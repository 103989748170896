import { createSlice } from '@reduxjs/toolkit';
import { User } from '@global/types/types';

export interface UserSliceState {
  user: null | User;
  loading: boolean;
  error: string | null;
  isLoggedIn: boolean;
}

const initialState: UserSliceState = {
  user: null,
  loading: false,
  isLoggedIn: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
      state.isLoggedIn = payload === null ? false : true;
    },
  },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
