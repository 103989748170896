import React, { FC } from 'react';

import { ReactComponent as CheckboxUnchecked } from '@assets/images/Checkbox_Unchecked.svg';
import { ReactComponent as TickCheckedBox } from '@assets/images/TickCheckedBox.svg';

interface CheckboxProps {
  toggle: () => void;
  isChecked: boolean;
  text: string;
  isHidden: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({
  toggle,
  isChecked,
  text,
  isHidden,
}) => {
  return (
    <div
      className={
        isHidden
          ? 'billing-info__payment-agreement hide-checkbox'
          : 'billing-info__payment-agreement'
      }
      onClick={toggle}
    >
      {!isChecked ? (
        <CheckboxUnchecked className="checkbox-unchecked" />
      ) : (
        <TickCheckedBox className="checkbox-checked" />
      )}
      <span className="regularText">{text}</span>
    </div>
  );
};
