import React, { FC } from 'react';
import './style.scss';

interface IProps {
  size?: string;
  withOverlay?: boolean;
}

export const Spinner: FC<IProps> = ({ size, withOverlay }) => {
  const spinner = (
    <div
      className="spinner"
      style={size ? { width: size, height: size } : {}}
    ></div>
  );

  return (
    <div className={withOverlay ? 'overlay center-box' : ''}>{spinner}</div>
  );
};
