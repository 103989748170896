import { useTranslation } from 'react-i18next';
import { regExpName } from '@global/components/ActiveForm/regexp';
import * as yup from 'yup';
import { configLanguage } from '@global/localization/i18n';
import { LOCALES_CONFIG } from '@global/App/constants/location/constants';

export const billingInformationSchema = () => {
  const { t, i18n } = useTranslation('', {
    keyPrefix: 'activeForm.validationErr',
  });

  const isStateRequired = LOCALES_CONFIG[i18n.language].stateDropdown;
  const phoneRegExp = configLanguage[i18n.language].phoneRegExp;
  const phoneFormat = configLanguage[i18n.language].phoneFormat;

  return yup.object({
    CCname: yup
      .string()
      .max(60, t('max60CCNameLength'))
      .matches(regExpName, t('nameFormat'))
      .trim(t('nameFormat'))
      .required(t('nameReq')),
    phone: yup
      .string()
      .max(15, t('max15PhoneLength'))
      .matches(phoneRegExp, t('phoneFormat', { phoneFormat }))
      .required(t('phoneReq')),
    address1: yup
      .string()
      .max(30, t('max30AddressLength'))
      .min(4, t('addressLength'))
      .trim(t('addressLength'))
      .required(t('addressReq')),
    address2: yup
      .string()
      .max(30, t('max30AddressLength'))
      .notRequired()
      .min(4, t('addressLength'))
      .nullable()
      .transform((value) => (!!value ? value : null)),
    state: isStateRequired
      ? yup.string().required(t('stateReq'))
      : yup.string(),
    city: yup
      .string()
      .max(24, t('max24CityLength'))
      .trim(t('cityReq'))
      .required(t('cityReq')),
    zip: yup
      .string()
      .max(9, t('max9ZipLength'))
      .trim(t('zipReq'))
      .required(t('zipReq')),
    country: yup.string().required(t('countryReq')),
  });
};

export interface IBillingInformationSchema {
  CCname: string;
  phone: string;
  address1: string;
  address2?: string;
  address3?: string;
  state?: string;
  city: string;
  zip: string;
  country?: string;
}
