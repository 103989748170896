import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';

import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_TYPE,
} from '@global/requests/subscriptionDetails';
import {
  queryBuilderFor_GET_SUBSCRIPTIONS,
  SubscriptionPageType,
} from '@global/requests/subscriptionDetails/GET_SUBSCRIPTIONS';
import { SUBSCRIPTION_STATUSES } from '@global/types/types';

export const getSubscriptions = createAsyncThunk<
  SubscriptionPageType[],
  string,
  { rejectValue: string }
>('subscription/getSubscriptions', async (userUid, { rejectWithValue }) => {
  try {
    const query = queryBuilderFor_GET_SUBSCRIPTIONS({
      userUid,
      statuses: [
        SUBSCRIPTION_STATUSES.Active,
        SUBSCRIPTION_STATUSES.Cancelling,
      ],
    });

    const result = await client.query<GET_SUBSCRIPTIONS_TYPE>({
      query: GET_SUBSCRIPTIONS,
      variables: {
        query,
      },
    });

    return result.data.fullTextSearchSubscriptionContracts.page;
  } catch (error) {
    return rejectWithValue('SERVER_ERROR');
  }
});
