//Yup rules examples https://www.npmjs.com/package/yup#api
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const newsSignUpValidationSchema = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm.validationErr',
  });
  return yup.object({
    email: yup.string().required(t('emailReq')).email(t('emailFormat')),
  });
};
