export const expirationDateFormat = (dateString) => {
  if (dateString && dateString !== 'undefined') {
    return dateString.split('-')[1] + ' / ' + dateString.split('-')[0];
  }
};

export const normalizePhoneNumber = (phone) =>
  phone.replace(/\D/g, '').slice(-12);

export const generateHash = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function interpolateString(
  template: string,
  data: Record<string, string>
) {
  const regex = /{(\w+)}/g;

  const result = template?.replace(regex, function (match, propertyName) {
    if (data.hasOwnProperty(propertyName)) {
      return data[propertyName];
    }
    return match;
  });

  return typeof result === 'string' ? result : '';
}
