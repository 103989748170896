// Modules
import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@global/App/style.scss';

// Utils
import { mainRoutes, protectedRoutes } from '@global/router';
import * as Constants from '@global/App/constants/constants';
import { prefixPath } from '@global/localization/services';
import { setupHtmlLang } from '@global/utils/lang';

import { useAppDispatch } from '@global/store/hooks';
import { useAuth } from '@global/hooks/useAuth';
import { setUser } from '@global/store/slices/user/userSlice';
import { setLanguage } from '@global/store/slices/config/configSlice';
import { LOCALE_CODES } from '@global/types/types';
import RouteWrapper from '@global/App/RouteWrapper';
import { getCountries } from '@global/store/slices/country/extraReducers/getCountries';

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();

  const language = i18n.language as LOCALE_CODES;
  const { user, isLoggedIn } = useAuth();

  const redirectPath = useMemo(
    () =>
      prefixPath(
        isLoggedIn
          ? Constants.Pages.SUBSCRIPTION_DETAILS
          : Constants.Pages.LOGIN,
        i18n.language
      ),
    [isLoggedIn]
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUser(user));
  }, [user]);

  useEffect(() => {
    dispatch(setLanguage(language));
    setupHtmlLang(language);
  }, [language]);

  useEffect(() => {
    dispatch(getCountries());
    setupHtmlLang(language);
  }, []);

  return (
    <div className="app">
      <div className="page-wrapper">
        <Switch>
          {/* Redirect to main PDP page when root is hit */}
          <Route exact path={prefixPath(Constants.Pages.ROOT, i18n.language)}>
            <Redirect
              to={{
                pathname: prefixPath(Constants.Pages.PDP1, i18n.language),
              }}
            />
          </Route>

          <Route exact path={Constants.Pages.ROOT}>
            <Redirect
              to={{
                pathname: prefixPath(Constants.Pages.PDP1, i18n.language),
              }}
            />
          </Route>

          {mainRoutes.map(({ path, component: Component }) => (
            <Route exact key={path} path={path}>
              <RouteWrapper path={path}>
                <Component />
              </RouteWrapper>
            </Route>
          ))}

          {isLoggedIn &&
            protectedRoutes.map(({ path, component: Component }) => (
              <Route exact key={path} path={path}>
                <RouteWrapper path={path}>
                  <Component />
                </RouteWrapper>
              </Route>
            ))}

          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: location },
            }}
          />
        </Switch>
      </div>
    </div>
  );
}

export default App;
