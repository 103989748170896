import {
  AddToCartLayerType,
  BtnLayerType,
  CancelSubscriptionLayerType,
  EcommercePropsType,
  FormLayerType,
  OrderConfirmationLayerType,
  OrderItemType,
  PageLayerType,
  UniversalPageLayerType,
} from './types';
import { countryCurrencySymbolByLocale } from '@global/utils/lang';
import { Pages } from '@global/App/constants/constants';
import { LOCALE_CODES } from '@global/types/types';

interface Login {
  isLoggedIn?: boolean;
}

const PAGES = {
  [Pages.CHECKOUT.slice(1)]: 'checkout',
};

class AdobeAnalytics {
  isWork: boolean;

  constructor() {
    this.isWork = true;
    window.adobeDataLayer = window.adobeDataLayer || [];

    this.dataLayerOrderConfirmation =
      this.dataLayerOrderConfirmation.bind(this);

    this.dataLayerForm = this.dataLayerForm.bind(this);

    this.dataLayerPageLoaded = this.dataLayerPageLoaded.bind(this);

    this.dataLayerBtnTracker = this.dataLayerBtnTracker.bind(this);

    this.dataLayerAddToCartTracker = this.dataLayerAddToCartTracker.bind(this);
    this.dataLayerCheckoutTracker = this.dataLayerCheckoutTracker.bind(this);
  }

  private buildProduct({
    maId,
    maName,
    category,
    type,
    totalPrice,
    price,
  }: OrderItemType) {
    const product = {
      maId,
      maName,
      category: category ?? maName,
      type: type ?? 'Subscription', // Subscription or a point product
    };
    if (price) {
      return {
        ...product,
        quantity: '1',
        price: `${price.value}`, // the individual price of one product of this SKU in the local currency
        totalRevenue: `${totalPrice ? totalPrice.value : ''}`, // the total revenue of the products of this SKU in the local currency
        source: 'WIL',
      };
    }
    return product;
  }

  private buildProducts(products: OrderItemType[]) {
    return products.map((orderItem) => this.buildProduct(orderItem));
  }

  private buildPageLayer(props: PageLayerType & Login) {
    const { page, event, error } = props;
    const { language } = this.parseUrlPath();
    const country = countryCurrencySymbolByLocale(language as LOCALE_CODES);
    const adobeDataObj = {
      event: event ?? 'page load',
      environment: process.env.REACT_APP_ANALYTICS_MODE,

      page: {
        pageName: `www:wil:${country}:${page.name}`,
        section: `${page.section}`,
        type: page.type ?? 'All Pages',
      },

      site: {
        language,
        country: `${country}`,
        platform: 'web',
      },

      visitor: {
        loginStatus: `${props.isLoggedIn ? 'logged in' : 'not logged in'}`,
      },
    };

    if (error) {
      return {
        ...adobeDataObj,
        error: {
          code: `${error.code}`,
          message: `${error.message}`,
        },
      };
    }
    return adobeDataObj;
  }

  private buildEcommerceLayer(props: EcommercePropsType) {
    const { orderItems, currency } = props;
    return {
      currency: currency ?? '', // Two values: GBP or EUR
      products: [...this.buildProducts(orderItems)],
    };
  }

  private buildFullEcommerceLayer(props: EcommercePropsType) {
    const { orderId, orderPrice, promotions } = props;
    return {
      orderId: orderId ?? undefined,
      paymentType: 'card',
      promoCode: promotions ? promotions[0].prmId : undefined,
      promoCodeDiscount:
        promotions && promotions[0].trpDuration ? '0' : undefined,
      totalRevenue: orderPrice?.total.value ?? undefined,
      subTotalRevenue: orderPrice?.price.value ?? undefined,
      vat: orderPrice?.tax.value ?? undefined,
      ...this.buildEcommerceLayer(props),
    };
  }

  private buildOrderConfirmation(props: OrderConfirmationLayerType & Login) {
    const { orderPrice, isLoggedIn } = props;

    const page = {
      name: 'order-confirmation',
      type: 'order confirmation',
      section: 'cart',
    };
    const event = 'order confirmation';

    const pageLayer = this.buildPageLayer({
      page,
      event,
      isLoggedIn,
    });
    const ecommerceLayer = this.buildFullEcommerceLayer({
      ...props,
      currency: orderPrice?.total.units,
    });
    return {
      ...pageLayer,
      ecommerce: ecommerceLayer,
    };
  }

  private parseUrlPath() {
    const url = location.pathname;

    const language = url.slice(1, 6);

    const pageSide = url.slice(7);
    const slashIndex = pageSide.indexOf('/');
    const page = slashIndex != -1 ? pageSide.slice(0) : pageSide;

    return {
      pageName: PAGES[page] || page,
      language,
    };
  }

  dataLayerOrderConfirmation(props: OrderConfirmationLayerType & Login) {
    if (!this.isWork) return;
    const adobeDataObj = this.buildOrderConfirmation(props);
    window.adobeDataLayer.push(adobeDataObj);
  }

  dataLayerForm(props: FormLayerType & Login) {
    if (!this.isWork) return;

    const { pageName, language } = this.parseUrlPath();
    const { formName, formEvent, errorMsg } = props;

    const fullLang = language.replace('-', '');

    const adobeDataObj = {
      event: `${formEvent}`,
      formName: `wil:form:${fullLang}:${formName}:${pageName}`,
      environment: process.env.REACT_APP_ANALYTICS_MODE,
      errorMsg: errorMsg ? `${errorMsg}` : null,
    };
    window.adobeDataLayer.push(adobeDataObj);
  }

  dataLayerPageLoaded(props: PageLayerType & Login) {
    if (!this.isWork) return;

    const adobeDataObj = this.buildPageLayer(props);
    window.adobeDataLayer.push(adobeDataObj);
  }

  dataLayerBtnTracker(props: BtnLayerType) {
    if (!this.isWork) return;

    const { btnType, btnName } = props;
    const adobeDataObj = {
      event: 'button click',
      click: {
        type: `${btnType}`,
        name: `${btnName}`,
      },
    };
    window.adobeDataLayer.push(adobeDataObj);
  }

  dataLayerAddToCartTracker(props: AddToCartLayerType) {
    if (!this.isWork) return;

    const { product, currency } = props;
    const adobeDataObj = {
      event: 'add to cart',
      ecommerce: {
        currency: `${currency}`, // Two values GBP or EUR
        products: this.buildProduct(product),
      },
    };
    window.adobeDataLayer.push(adobeDataObj);
  }

  dataLayerCancelSubscriptionTracker(props: CancelSubscriptionLayerType) {
    if (!this.isWork) return;

    const { product } = props;
    const adobeDataObj = {
      event: 'unsubscribe',
      ecommerce: {
        products: this.buildProduct(product),
      },
    };
    window.adobeDataLayer.push(adobeDataObj);
  }

  dataLayerCheckoutTracker(props: UniversalPageLayerType & Login) {
    if (!this.isWork) return;

    const { pageData, ecommerceData, isLoggedIn } = props;
    const pageLayer = this.buildPageLayer({
      ...pageData,
      isLoggedIn,
    });

    const ecommerceLayer = this.buildEcommerceLayer(ecommerceData);
    const adobeDataObj = {
      ...pageLayer,
      ecommerce: ecommerceLayer,
    };
    window.adobeDataLayer.push(adobeDataObj);
  }
}

export default new AdobeAnalytics();
