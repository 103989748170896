import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { COMFIRM_SETI_FOR_PAYMENT_CHANGE } from '@global/requests/subscriptionDetails';
import {
  setPaymentInfo,
  setError,
  setNotification,
  setIsButtonloading,
  setIsClicked,
} from '@global/store/slices/subscription/subscriptionSlice';

export const updatePaymentMethod = createAsyncThunk<
  any,
  {
    cb?: () => void;
    errorCb?: () => void;
    t: (arg: string) => string;
    uid: string;
    payment: any;
  },
  { rejectValue: string }
>(
  'subscription/updatePaymentMethod',
  async ({ t, cb, errorCb, ...variables }, { rejectWithValue, dispatch }) => {
    try {
      const { data, errors } = await client.mutate({
        mutation: COMFIRM_SETI_FOR_PAYMENT_CHANGE,
        variables,
      });

      if (errors) throw errors;

      if (data.wChangePMConfirm.result === 'SUCCESS') {
        const { ccpaCardNumber, ccpaExpirationDate } = data.wChangePMConfirm;
        dispatch(
          setPaymentInfo({
            paymentInfo: {
              cardNumber: ccpaCardNumber,
              expirationDate: ccpaExpirationDate,
              wPaymentType: variables.payment.paymentType,
            },
          })
        );
        cb?.();
        dispatch(setNotification(t('notificationTexts.info.paymentInfo')));
      }
      if (data?.wChangePMConfirm?.result === 'ERROR') {
        dispatch(setError(data?.wChangePMConfirm?.returnMessage));
      }
      dispatch(setIsClicked(false));
    } catch (err) {
      errorCb();
      dispatch(setError(err.message));
      dispatch(setIsButtonloading(false));
      dispatch(setIsClicked(false));
      return rejectWithValue(t('notificationTexts.err.errorOccurred'));
    }
  }
);
