import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GRAPHQL_URI } from '@global/App/constants/constants';
import {
  getFormattedLocale,
  getLocaleFromUrl,
  parseLocale,
} from '@global/utils/lang';
import { RootState, store } from '@global/store/store';
import { CountryService } from '@global/utils/CountryService';
import { getTokensFromCookies } from '@global/hooks/useAuth/utils';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      console.error(`GraphQL error ${message}`);
    });
  }
});

const makeLocaleHeaders = () => {
  const locale = getLocaleFromUrl();

  const { country } = store.getState() as RootState;
  const currentCountry = CountryService.getCountryByIsoCode(
    country.countries,
    parseLocale(locale)[1]
  );
  return {
    'X-Viax-User-Locale': getFormattedLocale() || '',
    'X-Viax-User-Currency': currentCountry?.currency || '',
  };
};

const headersLink = setContext((_, { headers }) => {
  const localeHeaders = makeLocaleHeaders();
  const token = getTokensFromCookies().viaxToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      ...localeHeaders,
    },
  };
});

const link = from([errorLink, new HttpLink({ uri: GRAPHQL_URI })]);

const typePolicies = {
  ViaxCustomerOrder: {
    keyFields: ['uid'],
  },
  CustomerOrder: {
    keyFields: ['uid'],
  },
  CustomerOrderItem: {
    keyFields: ['biiId'],
  },
  Product: {
    keyFields: ['sku'],
  },
  WileyBookAuthor: {
    keyFields: ['uid'],
  },
};

export const client = new ApolloClient({
  link: headersLink.concat(link),
  cache: new InMemoryCache({
    typePolicies,
  }),
});
