// Modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

// CSS and assets
import './style.scss';

// Components
import { useAuth } from '@global/hooks/useAuth';
import { useNotification } from '@global/hooks/useNotification';
import { NotificationTypes } from '@global/components/Notification';

import {
  BillingInfo,
  Header,
  NoActive,
  NotExist,
  PaymentInfoView,
  SubscriptionInfo,
  SubscriptionProduct,
} from '@pages/SubscriptionDetails/components';
import { PaymentInfoChangeForm } from './components/modals/PaymentInfoChangeForm';
import { ChangeBillingInfoForm } from './components/ChangeBillingInfoForm';

// Utils
import { LocalStorage } from '@global/utils/LocalStorage';

import analytic from '@global/utils/AdobeAnalytics';
import { PageLayerType } from '@global/utils/AdobeAnalytics/types';

import {
  clearSubscriptions,
  setOrderId,
  setIsButtonloading,
  setIsClicked,
} from '@global/store/slices/subscription/subscriptionSlice';
import { useAppDispatch, useAppSelector } from '@global/store/hooks';
import { getSubscriptions } from '@global/store/slices/subscription/extraReducers/getSubscriptions';
import { getCustomerOrder } from '@global/store/slices/subscription/extraReducers/getCustomerOrder';
import { getLatestSubscription } from '@global/store/slices/subscription/extraReducers/getLatestSubscription';
import { getParty } from '@global/store/slices/subscription/extraReducers/getParty';
import { updateBillingInformation } from '@global/store/slices/subscription/extraReducers/updateBillingInformation';

import { subscriptionSelector } from '@global/store/slices/subscription/selectors';

import { existSubscriptionStatus } from '@global/store/slices/subscription/helper';

import { cartSelector } from '@global/store/slices/cart/selectors';
import { countriesSelector } from '@global/store/slices/country/countrySelector';
import { useOldBillingInfoSelector } from '@global/store/slices/changeSubscriptionPayment/selector';

import {
  billingInformationSchema,
  IBillingInformationSchema,
} from '@pages/checkout/components/BillingInformation/rules';
import { useYupValidationResolver } from '@global/components/ActiveForm/utils';
import { makeBillingInfoForPaymentMethod } from '@global/utils/paymentMethodRules';

import { LOCALE_CODES } from '@global/types/types';

const pageLayerProps: PageLayerType = {
  page: {
    name: 'subscriptions-details',
    type: 'All Pages',
    section: 'Subscription Details',
  },
  event: 'page loaded',
};

export const Subscription: React.FC = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const { isLoggedIn, isLoading, user } = useAuth();
  const {
    isLoading: isLoadingSubscription,
    error,
    notification,
    uid,
    product,
    orderId,
    isCancelled,
    orderPrice,
    orderStatus,
    paymentInfo,
    periodInfo,
    billingInfo,
    subscriptions,
  } = useAppSelector(subscriptionSelector);
  const { isGetPartyLoading } = useAppSelector(cartSelector);

  const countries = useAppSelector(countriesSelector);
  const useOldBillingInfo = useAppSelector(useOldBillingInfoSelector);
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { setShowNotification } = useNotification();

  const [isShowBillingInfoForm, setIsShowBillingInfoForm] = useState(false);
  const [isShowPaymentForm, setIsShowPaymentForm] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');

  const toggleShowBillingInfoForm = () => {
    setIsShowBillingInfoForm((prev) => !prev);
    setIsShowPaymentForm(false);
  };

  const toggleShowPaymentForm = () => {
    setIsShowPaymentForm((prev) => !prev);
    setIsShowBillingInfoForm(false);
  };
  // Get id of latest subscription
  const latestUid = useMemo(() => subscriptions[0]?.uid, [subscriptions]);

  const resolverSchema = billingInformationSchema();
  const rulesResolver = useYupValidationResolver(resolverSchema);

  const billingInfoFormMethods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: rulesResolver,
  });

  const getSubscription = useCallback(
    () =>
      dispatch(
        getLatestSubscription({
          language,
          t,
        })
      ),
    [language]
  );

  const billingInfoFormSubmitHandler = useCallback(
    async (formData: IBillingInformationSchema) => {
      let data = formData;
      if (!data) {
        data = billingInfoFormMethods.getValues();
      }

      analytic.dataLayerForm({
        formName: 'billingInformationChangeForm',
        formEvent: 'form submit',
      });

      dispatch(
        updateBillingInformation({
          t,
          cb: () => {
            setIsShowPaymentForm(false);
            setIsShowBillingInfoForm(false);
            getSubscription();
          },
          ...data,
        })
      );
    },
    [dispatch]
  );

  const isPrevPaymentMethodPayPal = paymentInfo?.wPaymentType === 'PayPal';

  useEffect(() => {
    if (
      (isShowPaymentForm && isPrevPaymentMethodPayPal) ||
      (isShowPaymentForm && !useOldBillingInfo)
    ) {
      return;
    }

    if (billingInfo) {
      resolverSchema
        .validate({
          CCname: billingInfo.name,
          phone: billingInfo.tel,
          address1: billingInfo.addressOne,
          address2: billingInfo.addressTwo,
          state: billingInfo.state,
          city: billingInfo.city,
          zip: billingInfo.zip,
          country: billingInfo.country.iso2Code,
        })
        .then(() => {
          billingInfoFormMethods.trigger();
        })
        .catch(() => undefined);
    }
  }, [billingInfo, isShowBillingInfoForm, paymentMethod]);

  useEffect(() => {
    error && setShowNotification([NotificationTypes.error, error]);
    notification &&
      setShowNotification([NotificationTypes.success, notification]);
  }, [error, notification]);

  useEffect(() => {
    if (orderId) dispatch(getCustomerOrder());
  }, [orderId]);

  useEffect(() => {
    if (subscriptions.length > 0) {
      getSubscription();
    }
  }, [subscriptions, latestUid]);

  useEffect(() => {
    if (!isLoggedIn && !isLoading) {
      history.goBack();
    }

    if (isLoggedIn) {
      analytic.dataLayerPageLoaded({ ...pageLayerProps, isLoggedIn });
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setOrderId(LocalStorage.orderId));
    (async () => {
      if (!countries.length) return;
      const getPartyResult = await dispatch(getParty({ paId: user?.uid }));
      const getSubscriptionsResult = await dispatch(
        getSubscriptions(getPartyResult.payload)
      );
      const payloadOfSubscription = getSubscriptionsResult?.payload;
      if (
        Array.isArray(payloadOfSubscription) && // Checking that subscriptions in payloadOSubscription to be an array only not any string values like errors.
        payloadOfSubscription?.length > 0
      ) {
        getSubscription();
      } else {
        dispatch(clearSubscriptions());
      }
    })();
  }, [countries]);

  if (isLoading || isLoadingSubscription || isGetPartyLoading) {
    return <div className="page-spinner"></div>;
  }

  // If subscription purchased but not active yet
  if (
    existSubscriptionStatus.includes(
      latestUid ? subscriptions[0]?.bpStatus.code : orderStatus
    )
  ) {
    return <NoActive />;
  }

  // If subscription does not exist
  if (!subscriptions?.length) {
    return <NotExist />;
  }
  LocalStorage.orderId = undefined;

  const currentBilling = makeBillingInfoForPaymentMethod(
    billingInfo,
    paymentInfo?.wPaymentType
  );

  return (
    <>
      <Header
        setShowNotification={setShowNotification}
        subscriptionContractUid={uid}
      />
      <div className="subscription">
        <main className="subscription__container">
          <section className="subscription__data-active">
            <div className="subscription__item-wrapper">
              <Header
                setShowNotification={setShowNotification}
                isDesktop={true}
                subscriptionContractUid={uid}
              />

              {product.sku && (
                <SubscriptionProduct
                  sku={product.sku}
                  isActive={true}
                  isCancelled={isCancelled}
                  periodInfo={periodInfo}
                  totalPrice={`${
                    orderPrice.total.code
                  }${orderPrice.total.value.toFixed(2)}`}
                ></SubscriptionProduct>
              )}
            </div>

            <div className="subscription__content-wrapper">
              <div className="subscription__content">
                <SubscriptionInfo
                  disabled={isCancelled}
                  latestUid={latestUid}
                  setShowNotification={setShowNotification}
                />
                <div className="subscription__billing-wrapper">
                  <span className="subscription__title">
                    {t('subDetails.sub.billing.title')}
                  </span>
                  <div className="sub-information__column-container">
                    {!isPrevPaymentMethodPayPal ? (
                      <BillingInfo
                        disabled={isCancelled}
                        toggleOpen={toggleShowBillingInfoForm}
                        isShow={isShowBillingInfoForm}
                      />
                    ) : null}

                    <PaymentInfoView
                      paymentInfo={paymentInfo}
                      disabled={isCancelled}
                      toggleOpen={toggleShowPaymentForm}
                      isShow={isShowPaymentForm}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isShowPaymentForm ? (
              <div className="subscription__payment-form">
                <PaymentInfoChangeForm
                  setPaymentMethod={setPaymentMethod}
                  paymentMethod={paymentMethod}
                  billingInfoFormSubmitHandler={billingInfoFormSubmitHandler}
                  billingInfoFormMethods={billingInfoFormMethods}
                  hidden={!isShowPaymentForm}
                  latestUid={latestUid}
                  handleClose={() => setIsShowPaymentForm(false)}
                  prevPaymentMethod={paymentInfo?.wPaymentType}
                />
              </div>
            ) : null}

            {isShowBillingInfoForm ? (
              <div className="subscription__billing-form">
                <ChangeBillingInfoForm
                  methods={billingInfoFormMethods}
                  onSubmit={billingInfoFormMethods.handleSubmit(
                    billingInfoFormSubmitHandler
                  )}
                  billingInfo={currentBilling}
                  setIsShow={(value: boolean) =>
                    setIsShowBillingInfoForm(value)
                  }
                  isShow={isShowBillingInfoForm}
                  buttonId="btn-info-form-submit"
                  buttonText={t('subDetails.sub.billing.billingEditCta.cta')}
                />
              </div>
            ) : null}
          </section>
        </main>
      </div>
    </>
  );
};
