import { IBillingInfo } from '@global/types/types';
import { PaybBillingAddress } from '@pages/checkout/types';

interface billingInfoResult {
  paybBillingAddress: PaybBillingAddress & { typeName: string };
  wContactPhone: string;
  wTOSAccepted?: boolean;
  wMailingListSubscriptionAccepted?: boolean;
  paybPayment: {
    typeName: string;
    _wPSCustomerPeriodicPayment: {
      ccpaNameOnCard: string;
    };
  };
}

export function mapToServer(billingInfo: IBillingInfo): billingInfoResult {
  const result: billingInfoResult = {
    paybBillingAddress: {
      typeName: 'ViaxUrbanPropertyAddress',
      gadCountry: {
        name: billingInfo.country.name,
        numericCode: billingInfo.country.numericCode,
        iso2Code: billingInfo.country.iso2Code,
      },
      gadStateOrProvince: billingInfo.state,
      upaLine1: billingInfo.addressOne,
      upaLine2: billingInfo.addressTwo,
      upaPostcode: billingInfo.zip,
      upaCity: billingInfo.city,
    },
    paybPayment: {
      typeName: 'WPSCustomerPeriodicPayment',
      _wPSCustomerPeriodicPayment: {
        ccpaNameOnCard: billingInfo.name,
      },
    },
    wContactPhone: billingInfo.tel,
  };

  if (billingInfo?.uid) {
    result.paybBillingAddress.uid = billingInfo.uid;
  }

  return result;
}
