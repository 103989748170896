import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { SubscriptionsBookItem } from '@global/components/SubscriptionsBookItem';

// Types
import {
  hiConsistsOfCustomerOrderBiId,
  ICorPromoCodes,
  LOCALE_CODES,
} from '@global/types/types';

// Utils
import { CURRENCY_SYMBOLS } from '@global/App/constants/location/constants';
import defaultImage from '@assets/images/product-default-img.png';
import { dateFormatter } from '@global/utils/lang';

type IProps = {
  cartContents: hiConsistsOfCustomerOrderBiId[];
  promotions?: ICorPromoCodes[] | null;
  loading: boolean;
};

export const ItemsList: FC<IProps> = ({
  loading,
  cartContents = [],
  promotions,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const currentDate = useMemo(() => new Date(), []);

  const datePlusMonth = useMemo(() => {
    const resultData = new Date(cartContents[0]?.subsActivationDate);
    const currentMonth = resultData.getMonth();
    const currentDay = resultData.getDate();

    const nextMonth = currentMonth + 1;
    const nextBillDay =
      nextMonth === 2 && currentDay > 28 // if february set 28
        ? 28
        : currentDay === 31 // if long month set 30
        ? 30
        : currentDay;

    resultData.setDate(nextBillDay);
    resultData.setMonth(nextMonth);
    return resultData;
  }, [currentDate]);

  const promoDate = (index) => {
    const expDate = new Date();
    return dateFormatter(
      expDate.setDate(
        currentDate.getDate() + parseInt(promotions[index].trpDuration.amount)
      ),
      language
    );
  };

  const nextBilldate = useMemo(
    () => dateFormatter(datePlusMonth, language),
    []
  );

  const purchasedItemsData = useMemo(
    () =>
      cartContents.map((item) => {
        const node = item.pricPrice['edges'][0].node;
        return {
          imgUrl: item.biiSalable.imageUrl || defaultImage,
          navEntity: {
            to: 'subscriptions',
            isInAppNav: true,
            label: item.biiSalable.maName,
          },
          bottomContent: {
            top: {
              left: t('confirmation.subscription.nextBill'),
              right: t('confirmation.subscription.monthlyBill'),
            },
            bottom: {
              left: nextBilldate,
              right: `${
                CURRENCY_SYMBOLS[node.units.code]
              }${node?.amount.toFixed(2)}`,
            },
          },
        };
      }),
    [cartContents]
  );

  useEffect(() => {
    promotions &&
      purchasedItemsData.map((item, index) => {
        promotions[index] &&
          (item.bottomContent.bottom.left = promoDate(index));
      });
  }, [promotions]);

  return (
    <>
      <h3 className="order-confirmation__subtitle order-confirmation__subtitle_no-border">
        {t('confirmation.subscription.title')}
      </h3>
      <div className="order-confirmation__purchased-items">
        {!loading ? (
          purchasedItemsData?.map((item, i) => {
            return (
              <SubscriptionsBookItem
                key={item.navEntity.label + i}
                itemEntity={item}
                active={true}
              />
            );
          })
        ) : (
          <div className="spinner" id="spinner"></div>
        )}
      </div>
    </>
  );
};
