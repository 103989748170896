import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { configReducer } from '@global/store/slices/config/configSlice';

import { userReducer } from '@global/store/slices/user/userSlice';
import { cartReducer } from '@global/store/slices/cart/cartSlice';
import { subscriptionReducer } from '@global/store/slices/subscription/subscriptionSlice';
import { countryReducer } from '@global/store/slices/country/countrySlice';
import { changeSubscriptionPaymentReducer } from '@global/store/slices/changeSubscriptionPayment/changeSubscriptionPayment';

export const store = configureStore({
  reducer: {
    user: userReducer,
    cart: cartReducer,
    subscription: subscriptionReducer,
    config: configReducer,
    country: countryReducer,
    subscriptionPayment: changeSubscriptionPaymentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
