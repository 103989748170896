// Modules
import React, { useMemo } from 'react';

// Components
import { Header } from '@src/global/components/Header';
import { Footer } from '@src/global/components/Footer';
import { Notification } from '@global/components/Notification';
import { NewsSignUpPanel } from '@src/global/components/NewsSignUpPanel';
import { ErrorBoundary } from '@global/components/ErrorBoundary';

// Utils
import { Pages } from '@global/App/constants/constants';
import { useNotification } from '@global/hooks/useNotification';

interface IProps {
  path: string;
}

const newsHide = [undefined, Pages.CHECKOUT]; // undefined is 404 page

const PageLayout: React.FC<IProps> = ({ children, path }) => {
  const currentPage = useMemo(() => path.split('/:locale')[1], []);
  const { showNotification } = useNotification();

  return (
    <ErrorBoundary>
      {showNotification && (
        <Notification
          notification={{
            type: showNotification[0],
            effectClass: 'fading',
            message: showNotification[1],
          }}
        />
      )}
      <Header />

      {children}

      <div className="footer-wrapper">
        {!newsHide.includes(currentPage) ? (
          <NewsSignUpPanel currentPage={currentPage} />
        ) : (
          <></>
        )}
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default PageLayout;
