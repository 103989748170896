import { client } from '@global/Api';

import { REGISTRATION, LOGIN } from '@global/requests/auth';
import {
  LoginParams,
  RegistrationParams,
  AuthResponseData,
  AuthErrorResponse,
} from './types';

import { getFormattedLocale } from '@global/utils/lang';

export const login = async (payload: LoginParams) => {
  try {
    const response = await client.mutate<{
      wLogin: { result: AuthResponseData } | AuthErrorResponse;
    }>({
      mutation: LOGIN,
      variables: { ...payload },
    });

    return response.data.wLogin;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const registration = async (data: RegistrationParams) => {
  try {
    const payload = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      country: data.country.isoCodeTwo,
      currency: data.country.currency,
      language: getFormattedLocale(),
      optIn: data.offers,
    };

    const response = await client.mutate<{
      wRegistration: { result: AuthResponseData } | AuthErrorResponse;
    }>({
      mutation: REGISTRATION,
      variables: payload,
    });

    return response.data.wRegistration;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
