// Modules
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useSelector } from 'react-redux';
import { subscriptionSelector } from '@global/store/slices/subscription/selectors';
import { setUseOldBillingInfo } from '@global/store/slices/changeSubscriptionPayment/changeSubscriptionPayment';
import { updatePaymentMethod } from '@global/store/slices/subscription/extraReducers/updatePaymantMethod';
import { useAppDispatch } from '@global/store/hooks';

// CSS and assets
import './style.scss';

//Components
import { CREATE_SETI_FOR_PAYMENT_CHANGE } from '@global/requests/subscriptionDetails';
import { Spinner } from '@global/components/UI Elements/Spinner';

import analytic from '@global/utils/AdobeAnalytics';
import { PageLayerType } from '@global/utils/AdobeAnalytics/types';

import { useNotification } from '@global/hooks/useNotification';
import { NotificationTypes } from '@global/components/Notification';
import {
  setError,
  setIsClicked,
} from '@global/store/slices/subscription/subscriptionSlice';
import { paymentInfo } from '@src/pages/checkout/types';
import {
  WPS_JS_PROVIDER,
  WPS_PAYMENT_METHODS,
} from '@src/global/App/constants/constants';
import {
  getPaymentMethodsCount,
  paymentMethodSelection,
} from '@src/global/utils/paymentMethodRules';
import { ReloadInitResponseContext } from '@src/pages/checkout/components/SecurePayment';
import PaymentOptions from '@global/components/PaymentOptions';

import PayPal from '@src/global/components/WpsPayment/PayPal';
import { SubscriptionCard } from '@src/global/components/WpsPayment/subscriptions/SubscriptionCard';

import { MappedPaymentComponentsType, PaymentInfo } from '@global/types/types';
import { UseFormReturn } from 'react-hook-form';
import { IBillingInformationSchema } from '@pages/checkout/components/BillingInformation/rules';
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

interface IProps {
  billingInfoFormMethods: UseFormReturn<any, any>;
  handleClose: () => void;
  latestUid?: string;
  hidden: boolean;
  prevPaymentMethod: PaymentInfo['wPaymentType'];
  paymentMethod: string;
  setPaymentMethod: (value: string) => void;
  billingInfoFormSubmitHandler: (
    formData?: IBillingInformationSchema
  ) => Promise<void>;
}

export const PaymentInfoChangeForm: React.FC<IProps> = ({
  handleClose,
  latestUid,
  hidden,
  billingInfoFormMethods,
  billingInfoFormSubmitHandler,
  paymentMethod,
  setPaymentMethod,
  prevPaymentMethod,
}) => {
  const { t } = useTranslation();
  const { error } = useSelector(subscriptionSelector);
  const dispatch = useAppDispatch();

  const paymentComponentMap: MappedPaymentComponentsType = useMemo(
    () => ({
      Card: () => (
        <SubscriptionCard
          handleClose={handleClose}
          billingInfoFormMethods={billingInfoFormMethods}
          isShowCheckbox={prevPaymentMethod !== 'PayPal'}
        />
      ),
      PayPal: PayPal,
    }),
    []
  );

  const { setShowNotification } = useNotification();
  const [paymentErr, setPaymentErr] = useState('');
  const [startWpsJsInit, setStartWpsJsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initChangePMData, setInitChangePMData] = useState<paymentInfo>();
  const [createChangeSeti] = useMutation(CREATE_SETI_FOR_PAYMENT_CHANGE);
  const [loadingCreateChangeSeti, setLoadingCreateChangeSeti] = useState(false);
  const [paymentMethodsCount, setPaymentMethodsCount] = useState(0);
  const [payment, setPayment] = useState(null);
  const [usedPaymentMethod, setUsedPaymentMethod] = useState(null);

  const [PaymentMethodErrorMsg, setPaymentMethodErrorMsg] = useState<
    string | null
  >(null);
  const displayPaymentMethodComponent = [];
  for (const paymentMethodKey in WPS_PAYMENT_METHODS) {
    displayPaymentMethodComponent[paymentMethodKey] =
      paymentMethod === WPS_PAYMENT_METHODS[paymentMethodKey];
  }

  const formLayerProps = {
    formName: 'paymentInformationChangeForm',
  };

  const pageLayerProps: PageLayerType = {
    page: {
      name: 'subscriptions-details',
      type: 'All Pages',
      section: 'Subscription Details',
    },
    event: 'change payment method',
  };

  useEffect(() => {
    const loadWpsJsScript = () => {
      const script = document.createElement('script');
      script.src = WPS_JS_PROVIDER;
      script.async = true;
      document.body.appendChild(script);
    };
    // Note: loading WpsJs provider script
    loadWpsJsScript();

    const onMessage = (event) => {
      if (event.data.returnCode) {
        setInitChangePMData(event.data);
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    async function createSeti() {
      setLoadingCreateChangeSeti(true);
      !loadingCreateChangeSeti &&
        (await createChangeSeti({
          variables: {
            uid: latestUid,
            local: process.env.REACT_APP_WPS_LOCAL_ENV === 'true',
          },
          onCompleted: (data) => {
            if (data && data.wInitChangePM.paymentInitData) {
              setPaymentMethodsCount(
                getPaymentMethodsCount(data.wInitChangePM.paymentInitData)
              );
              setPaymentMethod(
                paymentMethodSelection(data.wInitChangePM.paymentInitData) ||
                  usedPaymentMethod
              );
              setInitChangePMData(data.wInitChangePM.paymentInitData);
              if (!startWpsJsInit) {
                setStartWpsJsInit(true);
              }
            } else {
              setShowNotification([
                NotificationTypes.error,
                'Something went wrong',
              ]);
            }
            setIsLoading(false);
          },
          onError: (error) => {
            setShowNotification([
              NotificationTypes.error,
              'Something went wrong',
            ]);
            setIsLoading(false);
          },
        }));

      setLoadingCreateChangeSeti(false);
    }

    createSeti();
    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form view',
    });
  }, [error, PaymentMethodErrorMsg]);

  useEffect(() => {
    async function handlePaymentInfoChange() {
      setPaymentErr('');
      dispatch(setError(null));
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form submit',
      });

      analytic.dataLayerPageLoaded(pageLayerProps);
      try {
        if (payment) {
          if (payment.returnMessage === 'ERROR') {
            setPaymentMethodErrorMsg(payment.merchantResponse);
            setShowNotification([
              NotificationTypes.error,
              payment.merchantResponse,
            ]);
          } else {
            dispatch(
              updatePaymentMethod({
                t,
                uid: latestUid,
                payment: {
                  paymentType: paymentMethod,
                  payment: payment,
                },
                cb: () => {
                  billingInfoFormSubmitHandler();
                  handleClose();
                },
                errorCb: () => {
                  dispatch(setIsClicked(false));
                  setStartWpsJsInit(false);
                },
              })
            );
          }
        }
      } catch (error) {
        setPaymentErr(error.message);
      }
    }

    handlePaymentInfoChange();
  }, [payment]);

  useEffect(() => {
    if (paymentErr || error) {
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form error',
        errorMsg: error || paymentErr,
      });
      pageLayerProps.error = {
        message: error || paymentErr,
      };
      analytic.dataLayerPageLoaded(pageLayerProps);
    }
  }, [paymentErr, error]);

  useEffect(() => {
    if (paymentMethod && paymentMethod !== usedPaymentMethod)
      setUsedPaymentMethod(paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    return () => {
      dispatch(setUseOldBillingInfo(false));
      setUsedPaymentMethod(null);
      setPaymentMethod('');
    };
  }, []);

  return (
    <>
      {!hidden && isLoading && <Spinner size={'64px'} />}
      {(!isLoading || PaymentMethodErrorMsg) && !hidden && (
        <div className="wrap-payment-form">
          {Boolean(error || paymentErr) && (
            <span className="payment-form__error-msg">
              {t('subDetails.sub.billing.paymentValidationErr.unableProcess')}
            </span>
          )}
          <ReloadInitResponseContext.Provider
            value={{
              setIsLoading,
              startWpsJsInit,
              setStartWpsJsInit,
              setPaymentMethodErrorMsg,
              initChangePMData,
            }}
          >
            <PaymentOptions
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              setPayment={setPayment}
              paymentMethodsCount={paymentMethodsCount}
              paymentInitData={initChangePMData}
              paymentComponentMap={paymentComponentMap}
            />
          </ReloadInitResponseContext.Provider>
        </div>
      )}
      {!isLoading && !initChangePMData && !hidden && (
        <span>Something went wrong...</span>
      )}
      {!isLoading && !hidden && paymentMethod !== 'Card' && (
        <div className="cancel-btn-wrapper">
          <ButtonCTA
            variant="secondary"
            className="small-btn"
            onClick={handleClose}
          >
            {t('subDetails.sub.billing.billingEditCta.cancel')}
          </ButtonCTA>
        </div>
      )}
    </>
  );
};
