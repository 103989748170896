import { gql } from '@apollo/client';

export interface ATTACH_CUSTOMER_TO_ORDER_RESULT {
  wAttachCustomerToOrder: {
    attached: 'SUCCESS' | 'ERROR';
  };
}

export const ATTACH_CUSTOMER_TO_ORDER = gql`
  mutation wAttachCustomerToOrder(
    $uid: UUID
    $currentPaIds: [String!]!
    $paId: String!
  ) {
    wAttachCustomerToOrder(uid: $uid, currentPaIds: $currentPaIds, paId: $paId)
  }
`;
