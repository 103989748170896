import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  useOldBillingInfo: false as boolean,
} as const;

export const changeSubscriptionPaymentSlice = createSlice({
  name: 'changeSubscriptionPayment',
  initialState: initialState,
  reducers: {
    toggleUseOldBillingInfo: (state) => {
      state.useOldBillingInfo = !state.useOldBillingInfo;
    },
    setUseOldBillingInfo: (state, { payload }) => {
      state.useOldBillingInfo = payload;
    },
  },
});

export const { toggleUseOldBillingInfo, setUseOldBillingInfo } =
  changeSubscriptionPaymentSlice.actions;

export const changeSubscriptionPaymentReducer =
  changeSubscriptionPaymentSlice.reducer;
