import { RootState } from '@global/store/store';

export const subscriptionsSelector = (state: RootState) =>
  state.subscription.subscriptions;

export const subscriptionSelector = (state: RootState) => state.subscription;
export const subscriptionBillingInfoSelector = (state: RootState) =>
  state.subscription.billingInfo;

export const productContentSelector = (state: RootState) =>
  state.subscription.productContent;
