import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderItemType } from '@global/utils/orderUtils';
import { IPaybBillingAddress, TypesName } from '@global/types/types';
import { promotionClasses } from '@global/utils/promotionClasses';
import { getIsDeLocale, getIsDeOrAu } from '@global/utils/lang';

interface IProps {
  orderItem: OrderItemType;
  promotionType?: TypesName;
  addressDetails?: IPaybBillingAddress;
}

const OrderSummaryItem: React.FC<IProps> = ({
  orderItem,
  promotionType,
  addressDetails,
}) => {
  const cls = promotionClasses[promotionType] || '';
  const { t, i18n } = useTranslation();
  const isDe = useMemo(() => getIsDeLocale(), []);
  const isDeOrAu = getIsDeOrAu(addressDetails?.gadCountry?.iso2Code);
  return (
    <div className="order-summary-item">
      <span className="order-summary-item__name">
        <p>({t('checkout.summary.itemPlaceholder')})</p>
      </span>
      <span className={`order-summary-item__price ${cls}`}>
        {orderItem.price.code}
        {isDe && isDeOrAu
          ? orderItem.totalPrice
            ? orderItem.totalPrice.value.toFixed(2)
            : orderItem.price.value.toFixed(2)
          : orderItem.price.value.toFixed(2)}
      </span>
    </div>
  );
};

export default OrderSummaryItem;
