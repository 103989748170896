'use strict';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from '@global/App/index';
import { Providers } from '@global/App/Providers';

import * as serviceWorker from './serviceWorker';
import { initKeycloak } from '@global/Api/keycloak/';
import '@global/localization/i18n';

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Providers>
          <App />
        </Providers>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
