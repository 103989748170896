import {
  EdgesType,
  hiConsistsOfCustomerOrderBiId,
  LOCALE_CODES,
} from '@global/types/types';
import { configLanguage } from '@global/localization/i18n';
import { CURRENCY_SYMBOLS } from '@global/App/constants/location/constants';

export type OrderPriceType = {
  total?: { value: number; code: string; units?: string };
  tax?: { value: number; code: string };
  price?: { value: number; code: string };
};

export type OrderItemType = {
  maName: string;
  maId?: string;
  imageUrl?: string;
  price: {
    value: number;
    code: string;
    units?: string;
  };
  totalPrice?: {
    value: number;
  };
};

const getPriceByCode = (arr: EdgesType[], code: string) => {
  return arr?.find((item) => item.mapsOn.code === code)?.node?.amount || 0;
};

const getSymbolByCode = (arr: EdgesType[], code: string) => {
  const SymbolCode = arr?.find((item) => item.mapsOn.code === code)?.node?.units
    ?.code;

  return SymbolCode ? CURRENCY_SYMBOLS[SymbolCode] : '';
};

const getUnitsByCode = (arr: EdgesType[], code: string) => {
  const SymbolCode = arr?.find((item) => item.mapsOn.code === code)?.node?.units
    ?.code;

  return SymbolCode ? SymbolCode : '';
};

export const getOrderPrice = (serverPrices: EdgesType[]): OrderPriceType => {
  return {
    tax: {
      value: getPriceByCode(serverPrices, 'TAX'),
      code: getSymbolByCode(serverPrices, 'TAX'),
    },
    total: {
      value: getPriceByCode(serverPrices, 'TOTAL'),
      code: getSymbolByCode(serverPrices, 'TOTAL'),
      units: getUnitsByCode(serverPrices, 'TOTAL'),
    },
    price: {
      value: getPriceByCode(serverPrices, 'SUBTOTAL'),
      code: getSymbolByCode(serverPrices, 'SUBTOTAL'),
    },
  };
};

export const getOrderItems = (
  arr: hiConsistsOfCustomerOrderBiId[],
  language?: LOCALE_CODES
): OrderItemType[] => {
  const vat = configLanguage[language].VAT;

  const getPriceValue = (amount) => {
    return amount + (amount / 100) * vat;
  };

  return arr?.map((item) => {
    const node = item?.pricPrice?.edges[0]?.node;
    const priceContents = item.pricPrice.edges;

    return {
      maName: item.biiSalable.maName,
      maId: item.biiSalable.maId,
      imageUrl: item.biiSalable.imageUrl,
      price: {
        value: getPriceByCode(priceContents, 'SUBTOTAL') || node?.amount || 0,
        code: CURRENCY_SYMBOLS[node?.units?.code] || '',
        units: node?.units?.code || '',
      },
      totalPrice: {
        value:
          getPriceByCode(priceContents, 'TOTAL') ||
          getPriceValue(node?.amount || 0),
      },
    };
  });
};
