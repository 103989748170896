import { gql } from '@apollo/client';
import { IOrder } from '@global/types/types';

export interface NEW_CART_TYPE {
  createViaxCustomerOrder: IOrder;
}

export const NEW_CART = gql`
  mutation createCart(
    $uId: UUID
    $corPromoCodes: [PromotionInput!]
    $paybBillingPlanUid: UUID
    $tmpPricingCountryCode: String
    $tmpPricingFallbackValue: String
  ) {
    createViaxCustomerOrder(
      input: {
        tmpPricingCountryCode: $tmpPricingCountryCode
        tmpPricingFallbackValue: $tmpPricingFallbackValue
        hiConsistsOf: [
          {
            typeName: "ViaxSubscriptionContractItem"
            biiSalable: { uid: $uId }
            paybBillingPlan: { uid: $paybBillingPlanUid }
          }
        ]
        corPromoCodes: $corPromoCodes
      }
    ) {
      corPromoCodes {
        ... on ViaxTrialPromotion {
          id
          uid
          prmId
          typeName
          prmName
          trpDuration {
            amount
            units
          }
        }
      }
      uid
      id
      biCreatedAt
      hiConsistsOf {
        biiId
        biiSequentialId
        biiSalable {
          ... on ViaxConfigurableSalableProduct {
            uid
            maId
            maName
            imageUrl
          }
          __typename
        }
        pricPrice(_mapsOn: { code: "PRICE" }) {
          edges {
            mapsOn {
              code
            }
            node {
              amount
              units {
                code
              }
            }
          }
        }
      }
      pricPrice {
        edges {
          mapsOn {
            code
          }
          node {
            amount
            units {
              code
            }
          }
        }
      }
    }
  }
`;
