import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeBillingAddressString } from '@global/utils/lang';
import { PaybBillingAddress } from '@pages/SubscriptionDetails/types';

import { subscriptionSelector } from '@global/store/slices/subscription/selectors';
import { LOCALE_CODES } from '@global/types/types';

interface IProps {
  disabled: boolean;
  toggleOpen: () => void;
  isShow: boolean;
}

export const BillingInfo: FC<IProps> = ({ disabled, toggleOpen, isShow }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const { billingInfo } = useSelector(subscriptionSelector);

  let paybBillingAddress: PaybBillingAddress | null = null;
  if (billingInfo) {
    paybBillingAddress = {
      line1: billingInfo?.addressOne,
      line2: billingInfo?.addressTwo,
      zip: billingInfo?.zip,
      city: billingInfo?.city,
      state: billingInfo?.state,
      country: {
        name: billingInfo?.country.name,
        numericCode: billingInfo?.country.numericCode,
        iso2Code: billingInfo?.country.iso2Code,
      },
    };
  }

  if (!paybBillingAddress) return null;
  const userInformationString = makeBillingAddressString(language, {
    zip: paybBillingAddress.zip,
    city: paybBillingAddress.city,
    state: paybBillingAddress.state,
  });

  return (
    <div className="sub-information__billing-container">
      <div className="sub-information__column">
        <span className="sub-information__text_bold">{billingInfo?.name}</span>
        <span className="sub-information__text_black">{billingInfo?.tel}</span>
        <span className="sub-information__text_black">
          {paybBillingAddress.line1}
        </span>
        <span className="sub-information__text_black">
          {paybBillingAddress.line2 !== 'null' && paybBillingAddress.line2}
        </span>
        <span className="sub-information__text_black">
          {userInformationString}
        </span>
      </div>

      <button
        className={`subscription__change-billing-btn btn-toggle ${
          isShow ? 'btn-toggle__active' : ''
        }`}
        disabled={disabled}
        onClick={toggleOpen}
      >
        {t('subDetails.sub.billing.billingEditCta.btnText')}
      </button>
    </div>
  );
};
