import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useNotification } from '@global/hooks/useNotification';
import { Spinner } from '@global/components/UI Elements/Spinner';
import PaymentOptions from '@src/global/components/PaymentOptions';

import analytic from '@global/utils/AdobeAnalytics';

import { CREATE_PAYMENT_INTENT } from '@global/requests/checkout/';
import { CART_ID_ATTRIBUTE } from '@global/App/constants/constants';
import { NotificationTypes } from '@global/components/Notification';
import { paymentInfo, STATUSES } from '../../types';
import { useSelector } from 'react-redux';
import { cartSelector } from '@src/global/store/slices/cart/selectors';
import {
  getPaymentMethodsCount,
  paymentMethodSelection,
} from '@src/global/utils/paymentMethodRules';
import { useAppDispatch } from '@src/global/store/hooks';
import { updateWpsPaymentInformation } from '@src/global/store/slices/cart/extraReducers/saveWpsPayment';
import { getTaxData } from '@src/global/store/slices/cart/extraReducers/getTaxData';
import i18n from '@src/global/localization/i18n';
import { LOCALE_CODES } from '@src/global/types/types';
import { useAuth } from '@src/global/hooks/useAuth';
import { setUpdateBillingInformation } from '@src/global/store/slices/cart/cartSlice';
import { PaymentForm } from './form';

export const ReloadInitResponseContext = createContext(null);

interface IProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenModal: () => void;
}

const formLayerProps = {
  formName: 'securePaymentForm',
  pageName: 'checkout',
};

export const SecurePayment: React.FC<IProps> = ({
  isLoading,
  setIsLoading,
  handleOpenModal,
}) => {
  const { t } = useTranslation();
  const language = i18n.language as LOCALE_CODES;
  const { setShowNotification } = useNotification();

  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAuth();
  const cartState = useSelector(cartSelector);

  const [reloadInitDataOnError, setReloadInitDataOnError] = useState(null);
  const [paymentErr, setPaymentErr] = useState(false);
  const [mutateCreatePayment] = useMutation(CREATE_PAYMENT_INTENT);
  const [paymentInitData, setPaymentInitData] = useState<paymentInfo>();
  const [payment, setPayment] = useState(null);
  const [message, setMessage] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState('');

  useEffect(() => {
    document.getElementById('securePayment').scrollIntoView();
    const localCartId = localStorage.getItem(CART_ID_ATTRIBUTE);

    if (
      localCartId &&
      (reloadInitDataOnError === null || reloadInitDataOnError)
    ) {
      setIsLoading(true);
      mutateCreatePayment({
        variables: {
          uid: localCartId,
          local: process.env.REACT_APP_WPS_LOCAL_ENV === 'true',
        },
        fetchPolicy: 'no-cache',
        onCompleted(data) {
          setIsLoading(false);
          if (data.wInitPayment?.paymentInitData) {
            setPaymentMethod(
              paymentMethodSelection(data.wInitPayment?.paymentInitData)
            );
            return setPaymentInitData(data.wInitPayment?.paymentInitData);
          }
          if (data.wInitPayment?.result === 'SUBSCRIPTION_EXISTS') {
            localStorage.removeItem(CART_ID_ATTRIBUTE);
            handleOpenModal();
          } else {
            setShowNotification([NotificationTypes.error, 'SERVER_ERROR']);
          }
        },
      });
    }
    return () => {
      setIsLoading(false);
      setPaymentInitData(null);
      setReloadInitDataOnError(false);
      setPaymentMethod('');
    };
  }, [reloadInitDataOnError]);

  const setPaymentAnalyticStep = useCallback(() => {
    analytic.dataLayerCheckoutTracker({
      pageData: {
        page: {
          name: 'cart',
          section: 'checkout',
          type: `checkout`,
        },
        event: `checkout payment`,
      },
      ecommerceData: {
        orderItems: cartState.orderItems,
        currency: cartState.orderItems?.[0]?.price.units,
      },
      language,
      isLoggedIn,
    });
  }, [cartState.orderItems, isLoggedIn, language]);

  useEffect(() => {
    async function handleWPSwSavePaymentFunc() {
      setIsLoading(true);
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form submit',
      });

      if (payment && cartState.updateBillingInformation === STATUSES.TRUE) {
        const wpsPaymentData = {
          paymentType: paymentMethod,
          payment: payment,
        };
        dispatch(updateWpsPaymentInformation({ wpsPaymentData })).then(
          (res) => {
            if (res.payload === 'OK') {
              setPaymentAnalyticStep();
              setMessage(null);
              setReloadInitDataOnError(false);
              setIsLoading(false);
              dispatch(getTaxData({ language }));
            }
            if (res.payload === 'ERROR') {
              setPayment(null);
              setPaymentInitData(null);
              setIsLoading(false);
              setPaymentErr(true);
              analytic.dataLayerForm({
                ...formLayerProps,
                formEvent: 'form error',
                errorMsg: t('checkout.step2.validationErr.errorOccurred'),
              });
              dispatch(setUpdateBillingInformation(STATUSES.FALSE));
              setReloadInitDataOnError(true);
            }
          }
        );
      } else {
        setIsLoading(false);
      }
      return () => {
        setPayment(null);
        setPaymentInitData(null);
        setPaymentMethod('');
      };
    }
    handleWPSwSavePaymentFunc();
  }, [payment, cartState.updateBillingInformation]);
  return (
    <>
      {paymentErr && (
        <span className="payment-form__error-msg">
          {t('checkout.step2.validationErr.unableProcess')}
        </span>
      )}
      {paymentInitData && !isLoading ? (
        <ReloadInitResponseContext.Provider
          value={{
            setIsLoading,
            paymentErr,
            paymentInitData,
            payment,
            setPayment,
            paymentMethod,
            setPaymentMethod,
          }}
        >
          <PaymentForm />
        </ReloadInitResponseContext.Provider>
      ) : (
        <div className="payment-form__spinner_box">
          <Spinner size={'64px'} />
        </div>
      )}
    </>
  );
};
