import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { NotificationTypes } from '@global/components/Notification';

const NotificationContext = createContext(0) || createContext(null);

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider: FC = ({ children }) => {
  const [showNotification, setShowNotification] = useState<
    null | [NotificationTypes, string]
  >(null);

  useEffect(() => {
    let timoutId;
    if (showNotification) {
      timoutId = setTimeout(() => {
        setShowNotification(null);
      }, 4000);
    }
    return () => {
      clearTimeout(timoutId);
    };
  }, [showNotification]);

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        setShowNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
