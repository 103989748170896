import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { makeBillingAddressString } from '@global/utils/lang';
import { IBillingInfo, LOCALE_CODES, PaymentInfo } from '@global/types/types';

interface IProps {
  billingInfo: IBillingInfo;
  paymentInfo: PaymentInfo;
}

export const Address: FC<IProps> = ({ billingInfo, paymentInfo }) => {
  const { i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const addressString = useMemo(() => {
    return makeBillingAddressString(language, {
      zip: billingInfo?.zip,
      state: billingInfo?.state,
      city: billingInfo?.city,
    });
  }, [billingInfo]);

  return (
    <div className="payment-details__column">
      <p className="payment-details__item payment-details__item_bold">
        {paymentInfo?.nameOnCard}
      </p>
      <span className="payment-details__item">{billingInfo?.addressOne}</span>
      <span className="payment-details__item">{billingInfo?.addressTwo}</span>
      <span className="payment-details__item">{billingInfo?.tel}</span>
      <span className="payment-details__item">{addressString}</span>
    </div>
  );
};
