import React from 'react';
import { WPS_PAYMENT_METHODS } from '@global/App/constants/constants';
import { useTranslation } from 'react-i18next';
import './style.scss';
// Utils
import paypalLogo from '@assets/images/paypal.png';
import masterCardLogo from '@assets/images/Mastercard_logo.png';
import visaLogo from '@assets/images/Visa_logo.png';
import americanExpressLogo from '@assets/images/American_Express_logo.png';
import discoverLogo from '@assets/images/Discover_logo.png';

interface IPaymentMethod {
  name: string;
  label?: string;
  logos: string[];
}

const paymentMethods: IPaymentMethod[] = [
  {
    name: 'PayPal',
    logos: [paypalLogo],
  },
  {
    name: 'Card',
    label: 'checkout.step2.CardLabel',
    logos: [masterCardLogo, visaLogo, americanExpressLogo, discoverLogo],
  },
];

interface IProps {
  paymentMethod: string;
  selectedPaymentMethod: string;
  onPaymentMethodChange: React.Dispatch<React.SetStateAction<any>>;
}

const RadioButtonForPaymentMethod: React.FC<IProps> = ({
  paymentMethod,
  selectedPaymentMethod,
  onPaymentMethodChange,
}) => {
  const { t } = useTranslation();

  const selectedMethod = paymentMethods.find(
    (method) => method.name === paymentMethod
  );

  return (
    <>
      <span className="order-summary__promotion__icon">
        <input
          className="radio-button"
          type="radio"
          name="paymentMethod"
          value={WPS_PAYMENT_METHODS[paymentMethod]}
          id={WPS_PAYMENT_METHODS[paymentMethod]}
          onChange={onPaymentMethodChange}
          checked={paymentMethod === selectedPaymentMethod}
        />
        <label
          className="radio-options__label-card"
          htmlFor={WPS_PAYMENT_METHODS[paymentMethod]}
        >
          {selectedMethod && (
            <>
              {selectedMethod.label ? (
                <span className="payment-method-text">
                  {t(selectedMethod.label)}
                </span>
              ) : (
                <span className="payment-method-text-first"></span>
              )}
              <span className="all-images">
                {selectedMethod.logos.map((logo, index) => (
                  <img key={index} src={logo} alt={selectedMethod.name} />
                ))}
              </span>
            </>
          )}
        </label>
      </span>
    </>
  );
};

export default RadioButtonForPaymentMethod;
