// Modules
import React from 'react';

// Components
import { Checkout } from '@pages/checkout';
import { OrderConfirmation } from '@pages/orderConfirmation';
import { Subscription } from '@pages/SubscriptionDetails';
import { LoginPage } from '@pages/login';
import { Page404 } from '@pages/404';

// Utils
import * as Constants from '@global/App/constants/constants';
import { localizeRoutes } from '@global/localization/services';

function Empty() {
  return <div style={{ height: window.innerHeight / 2 }}></div>;
}

export const mainRoutes: Array<{
  path: string;
  component?: React.FC;
  localize?: boolean;
}> = localizeRoutes([
  { path: Constants.Pages.PDP1, component: Empty },
  { path: Constants.Pages.CHECKOUT, component: Checkout },
  { path: Constants.Pages.LOGIN, component: LoginPage },
  { path: Constants.Pages.ERR404, component: Page404 },
]);

export const protectedRoutes: Array<{
  path: string;
  component?: React.FC;
  localize?: boolean;
}> = localizeRoutes([
  {
    path: Constants.Pages.ORDER_CONFIRMATION,
    component: OrderConfirmation,
  },
  { path: Constants.Pages.SUBSCRIPTION_DETAILS, component: Subscription },
]);
