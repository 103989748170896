import { createSlice } from '@reduxjs/toolkit';

export interface ConfigSliceState {
  language: string;
}

const initialState: ConfigSliceState = {
  language: 'en',
};

const configSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
  },
});

export const { setLanguage } = configSlice.actions;
export const configReducer = configSlice.reducer;
