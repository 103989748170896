import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';

export const Modal: FC<{
  onClose: () => void;
  blockScreen?: boolean;
  customClass?: string;
  isModalVisible: boolean;
}> = ({ children, isModalVisible, blockScreen, onClose, customClass = '' }) => {
  const [verticalOffset, setVerticalOffset] = useState(0);

  const refModal = useRef<HTMLDivElement | null>(null);
  const refFirstFocusable = useRef<HTMLElement | null>(null);
  const refLastFocusable = useRef<HTMLElement | null>(null);

  // When DOM is mounted and modal is open, initialize ref elements.
  useEffect(() => {
    if (isModalVisible) {
      const focusableElements = Array.from<HTMLElement>(
        refModal.current?.querySelectorAll('[tabindex]') ?? []
      );

      refFirstFocusable.current = focusableElements[0];
      refLastFocusable.current =
        focusableElements[focusableElements.length - 1];

      // Auto focus on first available element.
      focusableElements[0].focus();
    }
  }, [isModalVisible]);

  // When modal is open, prevent page scroll.
  useEffect(() => {
    if (isModalVisible) {
      setVerticalOffset(window.scrollY);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('noscroll');
    } else {
      document.body.style.removeProperty('top');
      document.body.classList.remove('noscroll');
      window.scrollBy(0, verticalOffset);
    }
  }, [isModalVisible]);

  // Accessible navigation & lock focus inside modal.
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (
        e.key === 'Tab' &&
        !e.shiftKey &&
        document.activeElement === refLastFocusable.current
      ) {
        e.preventDefault();
        refFirstFocusable.current?.focus();
      }

      if (
        e.key === 'Tab' &&
        e.shiftKey &&
        document.activeElement === refFirstFocusable.current
      ) {
        e.preventDefault();
        refLastFocusable.current?.focus();
      }

      if (e.key === 'Escape') {
        onClose();
      }
    },
    [refFirstFocusable]
  );

  return (
    <div
      className={'modal ' + customClass}
      ref={refModal}
      onKeyDown={handleKeyDown}
    >
      {!blockScreen && (
        <button
          className="modal__close-btn"
          onClick={onClose}
          tabIndex={0}
        ></button>
      )}
      {children}
    </div>
  );
};
