import { createSlice } from '@reduxjs/toolkit';

import {
  IBillingInfo,
  ICorPromoCodes,
  IOrder,
  IPaybBillingAddress,
} from '@global/types/types';
import { STATUSES } from '@pages/checkout/types';

import {
  initBillingAddress,
  initBillingInfo,
  initOrderPrice,
} from '@pages/checkout/consts';
import {
  getOrderItems,
  getOrderPrice,
  OrderItemType,
  OrderPriceType,
} from '@global/utils/orderUtils';
import { mapToView } from '@pages/checkout/helper';

import { updateCartId } from '@global/store/slices/cart/extraReducers/updateCartId';
import { getSubscriptions } from '@global/store/slices/cart/extraReducers/getSubscriptions';
import { getCartData } from '@global/store/slices/cart/extraReducers/getCartData';
import { linkCartWithUser } from '@global/store/slices/cart/extraReducers/linkCartWithUser';
import { updateBillingInformation } from '@global/store/slices/cart/extraReducers/updateBillingInformation';
import { clearBillingInformation } from '@global/store/slices/cart/extraReducers/clearBillingInformation';
import { getParty } from '../subscription/extraReducers/getParty';
import { updateWpsPaymentInformation } from '@src/global/store/slices/cart/extraReducers/saveWpsPayment';
import { getTaxData } from '@src/global/store/slices/cart/extraReducers/getTaxData';

export interface CartSliceState extends Omit<IOrder, 'biCreatedAt' | 'id'> {
  isLoading: boolean;
  isShowSpinner: boolean;

  statusUserLinked: STATUSES;
  isCheckSubscriptions: STATUSES;
  updatedWithNewCustomer: STATUSES;
  updateBillingInformation: STATUSES;
  updatePaymenInformation: STATUSES;
  istaxCalculated: STATUSES;

  sku: string | null;
  cartId: string | null;
  uid: string | null;
  error: string | null;
  isSubscriptionExist: boolean;
  isGetPartyLoading: boolean;

  orderPrice: OrderPriceType;
  billingInfo: IBillingInfo;
  addressDetails: IPaybBillingAddress;
  orderItems: OrderItemType[];
  promotions: ICorPromoCodes[] | null;

  agreementCheck: boolean;
  checkoutValid: boolean;
}

const initialState: CartSliceState = {
  isLoading: false,
  isGetPartyLoading: true,
  isShowSpinner: false,

  statusUserLinked: STATUSES.FALSE,
  isCheckSubscriptions: STATUSES.FALSE,
  updatedWithNewCustomer: STATUSES.FALSE,
  updateBillingInformation: STATUSES.FALSE,
  updatePaymenInformation: STATUSES.FALSE,
  istaxCalculated: STATUSES.FALSE,

  sku: null,
  error: null,
  cartId: null,
  uid: null,
  isSubscriptionExist: false,

  orderPrice: initOrderPrice,
  billingInfo: initBillingInfo,
  orderItems: [],
  addressDetails: initBillingAddress,
  promotions: null,

  agreementCheck: false,
  checkoutValid: false,
  pricPrice: { edges: [] },
  hiConsistsOf: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartId: (state, { payload }) => {
      state.cartId = payload;
    },
    toggleAgreementCheck: (state) => {
      state.agreementCheck = !state.agreementCheck;
    },
    setUpdateBillingInformation: (state, { payload }) => {
      state.updateBillingInformation = payload;
    },
    setUpdateWpsPaymentInformation: (state, { payload }) => {
      state.updatePaymenInformation = payload;
    },
    setSku: (state, { payload }) => {
      if (payload !== state.sku) {
        state.sku = payload;
      }
    },
    clearCartStates: (state) => {
      state.cartId = initialState.cartId;
      state.isLoading = false;
      state.isShowSpinner = false;
      state.istaxCalculated = STATUSES.FALSE;

      state.statusUserLinked = STATUSES.FALSE;
      state.isCheckSubscriptions = STATUSES.FALSE;
      state.updatedWithNewCustomer = STATUSES.FALSE;
      state.updateBillingInformation = STATUSES.FALSE;

      state.error = null;
      state.isSubscriptionExist = false;

      state.orderPrice = initOrderPrice;
      state.billingInfo = initBillingInfo;
      state.orderItems = [];
      state.promotions = null;

      state.agreementCheck = false;
      state.checkoutValid = false;
      state.pricPrice = { edges: [] };
      state.hiConsistsOf = [];
      state.uid = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCartId.pending, (state) => {
        state.updatedWithNewCustomer = STATUSES.PENDING;

        state.error = null;
        state.isLoading = true;
        state.isShowSpinner = true;
      })
      .addCase(updateCartId.fulfilled, (state, { payload }) => {
        state.updatedWithNewCustomer = STATUSES.TRUE;

        state.isLoading = false;
        state.isShowSpinner = false;
        state.cartId = payload.cartId;
        state.promotions = payload.corPromoCodes;
        state.orderItems = payload.orderItems;
        state.orderPrice = payload.orderPrice;
      })
      .addCase(updateCartId.rejected, (state, { payload }) => {
        state.error = payload;
        state.updatedWithNewCustomer = STATUSES.FALSE;

        state.isLoading = false;
        state.isShowSpinner = false;
      })

      .addCase(getSubscriptions.pending, (state) => {
        state.isCheckSubscriptions = STATUSES.PENDING;
        state.isSubscriptionExist = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.isCheckSubscriptions = STATUSES.TRUE;
        state.isSubscriptionExist = payload;
        state.isLoading = false;
      })
      .addCase(getSubscriptions.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })

      .addCase(getParty.pending, (state) => {
        state.isGetPartyLoading = true;
      })
      .addCase(getParty.fulfilled, (state, { payload }) => {
        state.isGetPartyLoading = false;
        state.uid = payload;
      })
      .addCase(getParty.rejected, (state, { payload }) => {
        state.error = payload;
        state.isGetPartyLoading = false;
      })

      .addCase(getCartData.pending, (state) => {
        state.isShowSpinner = true;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        getCartData.fulfilled,
        (state, { payload: { response, language } }) => {
          state.isShowSpinner = false;
          state.isLoading = false;

          const {
            pricPrice,
            paybPayment,
            wContactPhone,
            paybBillingAddress,
            wTOSAccepted,
            prRelatedParties,
            hiConsistsOf,
          } = response?.getViaxCustomerOrder;

          state.orderPrice = getOrderPrice(pricPrice.edges);
          state.orderItems = getOrderItems(hiConsistsOf, language);
          state.agreementCheck = wTOSAccepted;
          state.prRelatedParties = prRelatedParties;

          state.billingInfo = {
            name: paybPayment?.ccpaNameOnCard || '',
            tel: wContactPhone || '',
            ...mapToView(paybBillingAddress),
          };
        }
      )
      .addCase(getCartData.rejected, (state, { payload }) => {
        state.error = payload;
        state.isShowSpinner = false;
        state.isLoading = false;
      })

      .addCase(linkCartWithUser.pending, (state) => {
        state.statusUserLinked = STATUSES.PENDING;
        state.isLoading = true;
        state.isShowSpinner = true;
        state.error = null;
      })
      .addCase(linkCartWithUser.fulfilled, (state, { payload }) => {
        state.statusUserLinked = payload ? STATUSES.TRUE : STATUSES.FALSE;
        state.isLoading = false;
        state.isShowSpinner = false;
        if (typeof payload === 'string') state.error = payload;
      })
      .addCase(linkCartWithUser.rejected, (state, { payload }) => {
        state.statusUserLinked = STATUSES.FALSE;
        state.error = payload;
        state.isLoading = false;
        state.isShowSpinner = false;
      })

      .addCase(updateBillingInformation.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.isShowSpinner = true;
        state.updateBillingInformation = STATUSES.PENDING;
      })
      .addCase(updateBillingInformation.fulfilled, (state, { payload }) => {
        state.orderPrice = payload.orderPrice;
        state.billingInfo = payload.billingInfo;

        state.orderItems = getOrderItems(
          payload.hiConsistsOf,
          payload.language
        );

        state.isLoading = false;
        state.isShowSpinner = false;
        state.updateBillingInformation = STATUSES.TRUE;
      })
      .addCase(updateBillingInformation.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
        state.isShowSpinner = false;
        state.updateBillingInformation = STATUSES.FALSE;
      })

      .addCase(clearBillingInformation.rejected, (state, { payload }) => {
        state.isLoading = false;
        if (typeof payload === 'string') state.error = payload;
      })

      .addCase(updateWpsPaymentInformation.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.isShowSpinner = true;
        state.updatePaymenInformation = STATUSES.PENDING;
      })
      .addCase(updateWpsPaymentInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isShowSpinner = false;
        state.updatePaymenInformation =
          payload === 'OK' ? STATUSES.TRUE : STATUSES.FALSE;
      })
      .addCase(updateWpsPaymentInformation.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
        state.isShowSpinner = false;
      })

      .addCase(getTaxData.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.isShowSpinner = true;
        state.istaxCalculated = STATUSES.PENDING;
      })
      .addCase(
        getTaxData.fulfilled,
        (state, { payload: { response, language } }) => {
          state.isLoading = false;
          state.isShowSpinner = false;
          state.istaxCalculated = STATUSES.TRUE;
          state.addressDetails =
            response.getViaxCustomerOrder.paybBillingAddress;
          state.orderItems = getOrderItems(
            response?.getViaxCustomerOrder.hiConsistsOf,
            language
          );
          const { pricPrice } = response?.getViaxCustomerOrder;

          state.orderPrice = getOrderPrice(pricPrice.edges);
        }
      )
      .addCase(getTaxData.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
        state.isShowSpinner = false;
      });
  },
});

export const {
  setCartId,
  setSku,
  toggleAgreementCheck,
  clearCartStates,
  setUpdateBillingInformation,
} = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
