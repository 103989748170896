import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

import { ReactComponent as OpenNewWindowIcon } from '@assets/images/Open_New_Window_Icon.svg';
import { useLazyQuery } from '@apollo/client';
import {
  GET_VITAL_SOURCE,
  GET_VITAL_SOURCE_TYPE,
} from '@global/requests/subscriptionDetails/GET_VITAL_SOURCE';

import {
  vitalSourceHTMLError,
  vitalSourceHTMLRedirect,
} from '@pages/SubscriptionDetails/consts';

import { NotificationTypes } from '@global/components/Notification';
import { buildTitleWithName } from '@global/utils/lang';

import analytic from '@global/utils/AdobeAnalytics';
import { PageLayerType } from '@global/utils/AdobeAnalytics/types';
import { LOCALE_CODES } from '@global/types/types';

interface IProps {
  subscriptionContractUid?: string;
  isDesktop?: boolean;
  setShowNotification: (arg: [NotificationTypes, string]) => void;
}

export const Header: FC<IProps> = ({
  subscriptionContractUid,
  isDesktop = false,
  setShowNotification,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  // VitalSource query
  const [getVSAccessLink, { loading: loadingVS }] =
    useLazyQuery<GET_VITAL_SOURCE_TYPE>(GET_VITAL_SOURCE, {
      fetchPolicy: 'network-only',
      variables: {
        subscriptionContractUid,
      },
    });

  const pageLayerProps: PageLayerType = {
    page: {
      name: 'subscriptions-details',
      type: 'All Pages',
      section: 'Subscription Details',
    },
    event: 'access vitalsource bookshelf',
  };

  const handleVitalSource = useCallback(async () => {
    // New tab needs to exist as a bridge between React app and VitalSource
    // Tab needs to be opened immediately to avoid pop-up blockers
    analytic.dataLayerBtnTracker({
      btnType: 'cta',
      btnName: t('subDetails.sub.vitalSourceCta'),
    });
    analytic.dataLayerPageLoaded(pageLayerProps);
    const vitalSourceRedirect = window.open('', '_blank');
    vitalSourceRedirect.document.write(vitalSourceHTMLRedirect);

    const { data, error } = await getVSAccessLink();

    const accessLink = await data?.wGetVitalSourceAccessLink120sec?.result
      ?.redirectLink;

    if (accessLink) {
      vitalSourceRedirect.location.href = accessLink;
    } else {
      vitalSourceRedirect.document.write(vitalSourceHTMLError);
    }

    if (error) {
      console.error(error);
      setShowNotification([
        NotificationTypes.error,
        t('notificationTexts.err.vitalSource'),
      ]);
    }
  }, []);

  const Button = useCallback(
    () => (
      <ButtonCTA
        variant="primary"
        onClick={handleVitalSource}
        disabled={loadingVS}
      >
        {loadingVS ? (
          <div className="spinner"></div>
        ) : (
          <span>
            {t('subDetails.sub.vitalSourceCta')} <OpenNewWindowIcon />
          </span>
        )}
      </ButtonCTA>
    ),
    [loadingVS, handleVitalSource]
  );

  const MobileView = useCallback(
    () => (
      <div className="mobile-cta-container">
        <Button />
      </div>
    ),
    [Button]
  );

  const DesktopView = useCallback(
    () => (
      <div className="subscription__title-container">
        <span>
          {buildTitleWithName(language, t('subDetails.sub.details.title'))}
        </span>

        <Button />
      </div>
    ),
    [Button]
  );

  return isDesktop ? <DesktopView /> : <MobileView />;
};
