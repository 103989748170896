import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapToServer } from '@pages/checkout/components/BillingInformation/helper';
import {
  hiConsistsOfCustomerOrderBiId,
  IBillingInfo,
  LOCALE_CODES,
} from '@global/types/types';
import { RootState } from '@global/store/store';
import { client } from '@global/Api';
import { UPDATE_BILLING_INFORMATION } from '@pages/checkout/components/BillingInformation/queries';
import { getOrderPrice, OrderPriceType } from '@global/utils/orderUtils';

export const updateBillingInformation = createAsyncThunk<
  {
    orderPrice: OrderPriceType;
    billingInfo: IBillingInfo;
    language: LOCALE_CODES;
    hiConsistsOf: hiConsistsOfCustomerOrderBiId[];
  },
  {
    dataBillingInfo: IBillingInfo;
    language: LOCALE_CODES;
  },
  { rejectValue: string }
>(
  'cart/updateBillingInformation',
  async ({ dataBillingInfo, language }, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;

      if (!state.cart.agreementCheck) {
        throw new Error(`"agreement check" should be true`);
      }

      const result = await client.mutate({
        mutation: UPDATE_BILLING_INFORMATION,
        variables: {
          input: {
            uid: state.cart.cartId,
            wTOSAccepted: state.cart.agreementCheck,
            ...mapToServer(dataBillingInfo),
          },
        },
      });

      return {
        orderPrice: getOrderPrice(
          result.data?.updateViaxCustomerOrder?.pricPrice?.edges
        ),
        billingInfo: dataBillingInfo,
        language,
        hiConsistsOf: result.data?.updateViaxCustomerOrder?.hiConsistsOf,
      };
    } catch (error) {
      console.error(error);
      return rejectWithValue('SERVER ERROR');
    }
  }
);
