import Keycloak from 'keycloak-js';
import * as Constants from '@global/App/constants/constants';

const _kc = Keycloak({
  realm: Constants.Keycloak.REALM,
  clientId: Constants.Keycloak.CLIENT_ID,
  url: Constants.Keycloak.AUTH_URL,
});

export const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'check-sso',
    })
    .then(() => {
      onAuthenticatedCallback();
    });
};
