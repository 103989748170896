import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import NumberFormat from 'react-number-format';
// CSS and assets
import './style.scss';
import { ReactComponent as InfoIcon } from '@assets/images/Info_Icon.svg';
interface IProps {
  inputName: string;
  inputFormat: string;
  defaultValue?: string;
}

export const ActiveInputFormatted: React.FC<IProps> = ({
  inputName,
  inputFormat,
  defaultValue,
}) => {
  const methods = useFormContext();
  const { field } = useController({ name: inputName, defaultValue });
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm',
  });

  // I use it because we need to re-render this "input" when we set a value from the program side, but not from the client.
  useEffect(() => {
    setIsMounted(false);
  }, [defaultValue]);
  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);

  return (
    <div
      className={
        `${inputName}-field ` +
        'active-validation-field' +
        (methods.formState.errors[inputName] ? ' validation-error' : '') +
        (methods.watch(inputName) ? ' validation-success' : '')
      }
    >
      <label htmlFor="card_billingaddress_phone">
        <span>{t(`${inputName}Label`)}</span>
        <span
          className="infoWrapper"
          data-tip
          data-for="my-anchor-element"
          data-arrow-color="#fafafa"
        >
          <InfoIcon id="infoIcon" className="info-icon" />
        </span>
      </label>
      {isMounted ? (
        <>
          <ReactTooltip
            id="my-anchor-element"
            className="tooltip-inner"
            place="top"
            type="light"
            effect="solid"
            multiline={true}
          >
            <div className="billing-info__infoText">
              {t('phoneToolTipText')}
            </div>
          </ReactTooltip>

          <NumberFormat
            type="text"
            format={inputFormat}
            mask="_"
            id="card_billingaddress_phone"
            {...field}
            onValueChange={(c) => {
              field.onChange(c.value);
            }}
          />
        </>
      ) : null}

      {methods.formState.errors[inputName] && (
        <span className="error">
          {methods.formState.errors[inputName].message}
        </span>
      )}
    </div>
  );
};
