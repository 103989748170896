import { User } from '@src/global/types/types';
import { DoLogin, DoRegistration } from '@global/hooks/useAuth/types';

export const InitialAuthState = {
  user: null as User,
  isLoggedIn: false as boolean,
  isLoading: false as boolean,

  doLogout: () => undefined,
  doLogin: (async () => false) as DoLogin,
  doRegistration: (async () => false) as DoRegistration,
} as const;
