// Modules
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@global/store/hooks';
import { cartSelector } from '@global/store/slices/cart/selectors';

// Css and assets
import './style.scss';

// Components
import OrderSummaryItem from '@global/components/OrderSummary/item';
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';
import { PromotionBlock } from '@global/components/OrderSummary/PromotionBlock';

// Utils
import { OrderItemType, OrderPriceType } from '@global/utils/orderUtils';
import { promotionClasses } from '@global/utils/promotionClasses';
import { buildStringWithTitleAndVat, getIsDeLocale } from '@global/utils/lang';
import { matchCurrentLocation } from '@global/localization/services';

// Constants and Types
import * as Constants from '@global/App/constants/constants';
import {
  ICorPromoCodes,
  IPaybBillingAddress,
  LOCALE_CODES,
} from '@global/types/types';
import {
  CHECKOUT_ACCOUNT_STEP,
  CHECKOUT_PAYMENT_AND_BILLING_STEP,
  CHECKOUT_REVIEW_STEP,
} from '@pages/checkout/consts';

interface IProps {
  orderItems?: OrderItemType[];
  checkoutStep?: number;
  agreementCheck?: boolean;
  checkoutValid?: boolean;
  orderPrice?: OrderPriceType;
  isSummaryLoading?: boolean;
  supplier?: string;
  promotions?: ICorPromoCodes[] | null;
  addressDetails?: IPaybBillingAddress;
}

const defaultOrderPrice = {
  total: {
    code: '',
    value: 0,
  },
  tax: {
    code: '',
    value: 0,
  },
};

export const OrderSummary: React.FC<IProps> = ({
  promotions,
  orderItems,
  checkoutStep,
  agreementCheck,
  checkoutValid,
  orderPrice = defaultOrderPrice,
  isSummaryLoading,
  supplier,
  addressDetails,
}) => {
  const [viewSummary, setViewSummary] = useState(false);

  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;
  const isDe = useMemo(() => getIsDeLocale(language), []);
  const cartState = useAppSelector(cartSelector);

  const promotionCls = promotionClasses[promotions?.[0]?.typeName] || '';

  const isShowVatId = isDe;
  const isShowComponent = isDe
    ? checkoutStep === CHECKOUT_REVIEW_STEP ||
      matchCurrentLocation(Constants.Pages.ORDER_CONFIRMATION)
    : true;

  // Clicking this button triggers event on the original button in step 2 form
  const continueToPaymentHandler = () => {
    const paymentBtn = document.getElementById('btn-payment-element-submit');
    paymentBtn.click();
  };

  const isCartEmpty = orderItems && !orderItems.length;

  const vatTitle = buildStringWithTitleAndVat(
    language,
    t('checkout.summary.tax'),
    checkoutStep
  );

  return (
    <div
      hidden={!isShowComponent}
      className={
        !isCartEmpty ? 'order-summary' : 'order-summary order-summary-empty'
      }
    >
      <div className="order-summary__wrapper">
        <h2>{t('checkout.summary.title')}</h2>
        <div
          className={
            viewSummary
              ? 'order-summary__content'
              : 'order-summary__content--hidden'
          }
        >
          {isSummaryLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            <>
              {/* LIST OF ITEMS BEING PURCHASED */}
              {!isCartEmpty &&
                orderItems?.map((item, index) => {
                  return (
                    <div key={item.maName + index}>
                      <p>{item.maName}</p>
                      <OrderSummaryItem
                        promotionType={promotions?.[0]?.typeName} // TODO improve
                        key={item.maName + index}
                        orderItem={item}
                        addressDetails={addressDetails}
                      />
                    </div>
                  );
                })}

              {/* PROMOTION text */}
              {promotions &&
                promotions.map((promotion) => (
                  <div
                    key={promotion.prmName}
                    className="order-summary__item-tax"
                  >
                    <span className="order-summary__item-tax--left">
                      {t('checkout.summary.promo.title')}
                    </span>
                    <span className="order-summary__item-tax--right order-summary__item-promotion">
                      {promotion.prmName}
                    </span>
                  </div>
                ))}

              {/* TAXES */}
              <div className="order-summary__item-tax">
                <span className="order-summary__item-tax--left order-summary__item-tax--left__padding">
                  {vatTitle}
                </span>
                {(checkoutStep === CHECKOUT_ACCOUNT_STEP ||
                  checkoutStep === CHECKOUT_PAYMENT_AND_BILLING_STEP) && (
                  <span className="order-summary__item-tax--right">
                    {t('checkout.summary.priceToBeCalculated')}
                  </span>
                )}
                {checkoutStep === CHECKOUT_REVIEW_STEP && (
                  <span className="order-summary__item-tax--right-bold">
                    {orderPrice.tax.code}
                    {orderPrice.tax.value.toFixed(2)}
                  </span>
                )}
                {matchCurrentLocation(Constants.Pages.ORDER_CONFIRMATION) && (
                  <span className="order-summary__item-tax--right-bold">
                    {orderPrice.tax.code}
                    {orderPrice.tax.value.toFixed(2)}
                  </span>
                )}
              </div>

              {/* TOTAL */}
              <div className="order-summary__item-total">
                <span className="order-summary__item-total--left">
                  {checkoutStep === CHECKOUT_REVIEW_STEP ||
                  matchCurrentLocation(Constants.Pages.ORDER_CONFIRMATION)
                    ? t('checkout.summary.bill')
                    : t('checkout.summary.estimatedBill')}
                </span>
                <span className="order-summary__item-total--right-bold">
                  {orderPrice.total.code}
                  {checkoutStep === CHECKOUT_REVIEW_STEP ||
                  matchCurrentLocation(Constants.Pages.ORDER_CONFIRMATION)
                    ? orderPrice.total.value.toFixed(2)
                    : (orderPrice.total.value - orderPrice.tax.value).toFixed(
                        2
                      )}
                </span>
              </div>

              {/* CTA */}
              {/* {checkoutStep === CHECKOUT_REVIEW_STEP && (
                <ButtonCTA
                  variant="primary"
                  className="order-summary__submit-btn"
                  disabled={
                    !agreementCheck || cartState.isLoading || !checkoutValid
                  }
                  onClick={continueToPaymentHandler}
                >
                  {cartState.isLoading ? (
                    <div className="spinner"></div>
                  ) : (
                    t('checkout.summary.step2Cta')
                  )}
                </ButtonCTA>
              )} */}

              {/* {checkoutStep === CHECKOUT_REVIEW_STEP && (
                <div className="order-summary__agreement">
                  <span className="order-summary__agreement--text">
                    {t('checkout.summary.terms&conditions.instructions')}{' '}
                    <a
                      href={`https://www.wiley.com/${i18n.language}/terms-of-use`}
                      target="_blank"
                      className="payment-form__agreement--link"
                      rel="noreferrer"
                    >
                      {t('checkout.summary.terms&conditions.link')}
                    </a>{' '}
                    {t('checkout.summary.terms&conditions.instructions2')}
                  </span>
                </div>
              )} */}

              <ButtonCTA
                variant="primary"
                className="order-summary__view-summary-btn"
                onClick={() => {
                  setViewSummary((prev) => !prev);
                }}
              >
                {!viewSummary
                  ? t('checkout.summary.toggle.show')
                  : t('checkout.summary.toggle.hide')}
              </ButtonCTA>

              {supplier && (
                <div className="supplier-Info">
                  <h4>{t('checkout.summary.supplier.title')}</h4>
                  <p>{supplier}</p>
                  {isShowVatId && <p>{t('checkout.summary.supplier.vatId')}</p>}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {promotions &&
        promotions.map((promotion) => (
          <PromotionBlock
            key={promotion.id}
            typeName={promotion.typeName}
            trpDuration={promotion.trpDuration}
          />
        ))}
    </div>
  );
};
