import { PeriodInfo } from '@pages/SubscriptionDetails/types';

export const initPeriodInfo: PeriodInfo = {
  subscriptionBegan: '',
  nextBillDate: '',
  subscriptionEndDate: '',
};

export const vitalSourceHTMLRedirect = `<title>VitalSource Bookshelf</title>
      <style>
        #loading:after {
          content: '.';
          animation: dots 1s steps(5, end) infinite;}

        @keyframes dots {
          0%, 20% {
            color: rgba(0,0,0,0);
            text-shadow:
              .25em 0 0 rgba(0,0,0,0),
              .5em 0 0 rgba(0,0,0,0);}
          40% {
            color: black;
            text-shadow:
              .25em 0 0 rgba(0,0,0,0),
              .5em 0 0 rgba(0,0,0,0);}
          60% {
            text-shadow:
              .25em 0 0 black,
              .5em 0 0 rgba(0,0,0,0);}
          80%, 100% {
            text-shadow:
              .25em 0 0 black,
              .5em 0 0 black;}}
      </style>
      <h4 id="loading">Redirecting to VitalSource Bookshelf, this may take a few moments</h4>`;

export const vitalSourceHTMLError = `<title>VitalSource Bookshelf</title>
        <h4>Could not open VitalSource Bookshelf, please try again later.</h4>`;
