import React, { FC } from 'react';
import { expirationDateFormat } from '@global/utils/StringService';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from '@global/types/types';
interface IProps {
  paymentInfo: PaymentInfo | null;
}

export const CardPayment: FC<IProps> = ({ paymentInfo }) => {
  const { t } = useTranslation();
  return (
    <div className="payment-details__column">
      <p className="payment-details__item payment-details__item_secondary-color">
        {t('confirmation.details.payment')}
      </p>
      <p className="payment-details__item">{paymentInfo?.cardNumber}</p>
      <p className="payment-details__item">
        {t('confirmation.details.expiration')}{' '}
        {paymentInfo?.expirationDate &&
          expirationDateFormat(paymentInfo.expirationDate)}
      </p>
    </div>
  );
};
