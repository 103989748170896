import React from 'react';

export const CheckmarkIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74414 0C4.34414 0 0.744141 3.6 0.744141 8C0.744141 12.4 4.34414 16 8.74414 16C13.1441 16 16.7441 12.4 16.7441 8C16.7441 3.6 13.1441 0 8.74414 0ZM12.3441 6.6L8.34414 10.6C8.14414 10.8 7.94414 10.8667 7.74414 10.8667C7.54414 10.8667 7.27747 10.8 7.14414 10.6L5.14414 8.6C4.81081 8.26667 4.81081 7.73333 5.14414 7.33333C5.47747 7 6.01081 7 6.41081 7.33333L7.81081 8.73333L11.2108 5.33333C11.5441 5 12.0775 5 12.4775 5.33333C12.6775 5.73333 12.6775 6.26667 12.3441 6.6Z"
        fill="#094F34"
      />
    </svg>
  );
};
