import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';

import { UPDATE_BILLING_INFO_MUTATION } from '@pages/SubscriptionDetails/components/modals/BillingInfoChangeForm/queries';

import { RootState } from '@global/store/store';
import { IBillingInformationSchema } from '@pages/checkout/components/BillingInformation/rules';
import { IBillingInfo } from '@global/types/types';
import analytic from '@global/utils/AdobeAnalytics';

import {
  setBillingInfo,
  setNotification,
  setError,
  setIsButtonloading,
  setIsClicked,
} from '@global/store/slices/subscription/subscriptionSlice';
import { CountryService } from '@global/utils/CountryService';

export const updateBillingInformation = createAsyncThunk<
  string,
  {
    cb?: () => void;
    t: (arg: string) => string;
  } & IBillingInformationSchema,
  { rejectValue: string }
>(
  'subscription/updateBillingInformation',
  async ({ t, cb, ...formData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;

      const country = CountryService.getCountryByIsoCode(
        state.country.countries,
        formData.country
      );

      const dataBillingInfo: IBillingInfo = {
        ...state.subscription.billingInfo,
        name: `${formData.CCname.trim()}`,
        tel: `${formData.phone}`,
        addressOne: `${formData.address1.trim()}`,
        addressTwo: formData.address2?.trim(),
        city: `${formData.city.trim()}`,
        state: `${formData.state.trim()}`,
        zip: `${formData.zip.trim()}`,
        country: {
          name: country.name,
          numericCode: country.numCode.toString(),
          iso2Code: country.isoCodeTwo,
        },
      };

      const mutationVariables = {
        subscriptionUid: state.subscription.subscriptions[0].uid,
        paymentUid: state.subscription.paymentUid,
        nameCC: dataBillingInfo.name,
        state: dataBillingInfo.state,
        addr1: dataBillingInfo.addressOne,
        addr2: dataBillingInfo.addressTwo,
        zip: dataBillingInfo.zip,
        city: dataBillingInfo.city,
        phone: dataBillingInfo.tel,
        countryName: dataBillingInfo.country.name,
        countryCode: dataBillingInfo.country.numericCode,
        countryISO2: dataBillingInfo.country.iso2Code,
      };

      await client.mutate({
        mutation: UPDATE_BILLING_INFO_MUTATION,
        variables: mutationVariables,
      });

      dispatch(setBillingInfo(dataBillingInfo));
      cb?.();

      dispatch(setNotification(t('notificationTexts.info.billingInfo')));
      dispatch(setIsClicked(false));
    } catch (err) {
      analytic.dataLayerPageLoaded({
        page: {
          name: 'subscriptions-details',
          type: 'All Pages',
          section: 'Subscription Details',
        },
        event: 'change billing',
      });
      analytic.dataLayerForm({
        formName: 'billingInformationChangeForm',
        formEvent: 'form error',
        errorMsg: t('notificationTexts.err.billing'),
      });
      dispatch(setIsClicked(false));
      dispatch(setIsButtonloading(false));
      dispatch(setError(err.message));
      return rejectWithValue(t('notificationTexts.err.billing'));
    }
  }
);
