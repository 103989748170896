import { gql } from '@apollo/client';
import { SUBSCRIPTION_STATUSES } from '@global/types/types';

export interface SubscriptionPageType {
  uid: string;
  bpStatus: {
    code: SUBSCRIPTION_STATUSES;
  };
  hiConsistsOf: {
    biiSalable: {
      maId: string;
    };
  }[];
}

export interface GET_SUBSCRIPTIONS_TYPE {
  fullTextSearchSubscriptionContracts: {
    page: SubscriptionPageType[];
  };
}

type QueryBuilderFor_GET_SUBSCRIPTIONS = ({
  userUid: string,
  statuses: SUBSCRIPTION_STATUSES,
}) => string;

export const queryBuilderFor_GET_SUBSCRIPTIONS: QueryBuilderFor_GET_SUBSCRIPTIONS =
  ({ userUid, statuses = [] }) => {
    const should = statuses.reduce((acc, status) => {
      return [...acc, { term: { 'bpStatus.code': status } }];
    }, []);

    const query = {
      bool: {
        must: [
          {
            nested: {
              path: 'prRelatedParties.edges.node',
              query: { term: { 'prRelatedParties.edges.node.uid': userUid } },
            },
          },
          { bool: { should } },
        ],
      },
    };

    return JSON.stringify(query);
  };

export const GET_SUBSCRIPTIONS = gql`
  query getSubscriptionsForCustomer($query: String!) {
    fullTextSearchSubscriptionContracts(
      query: $query
      _page: { page: 1, limit: 10 }
    ) {
      page {
        uid
        bpStatus {
          code
        }
        hiConsistsOf {
          biiSalable {
            ... on ViaxConfigurableSalableProduct {
              maId
            }
          }
        }
      }
    }
  }
`;
