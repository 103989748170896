import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderNumber, Address, Payment, IssueDate } from '../';

import { IBillingInfo, PaymentInfo } from '@global/types/types';
import { showBillingAddressForPaymentMethods } from '@src/global/App/constants/location/constants';

interface IProps {
  email: string;
  orderId: string;
  issueDate: string;
  billingInfo: IBillingInfo;
  paymentInfo: PaymentInfo;
}

export const OrderInfo: FC<IProps> = ({
  email,
  orderId,
  paymentInfo,
  billingInfo,
  issueDate,
}) => {
  const { t } = useTranslation();
  const PaymentTypes = paymentInfo?.wPaymentType;
  return (
    <>
      <h3 className="order-confirmation__subtitle">
        {t('confirmation.details.title')}
      </h3>
      <p className="regularText">{t('confirmation.details.instructions')}</p>
      <p className="regularText">
        {t('confirmation.details.message')}
        <span className="order-confirmation__link">{email}</span>{' '}
        {t('confirmation.details.message2')}
      </p>
      <div className="order-confirmation__payment-details">
        <OrderNumber orderId={orderId} />
        <IssueDate date={issueDate} />
      </div>
      <div className="order-confirmation__payment-details">
        {showBillingAddressForPaymentMethods.includes(PaymentTypes) && (
          <Address paymentInfo={paymentInfo} billingInfo={billingInfo} />
        )}
        <Payment paymentInfo={paymentInfo} />
      </div>
    </>
  );
};
