import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { REQUEST_CART, REQUEST_CART_TYPE } from '@global/requests/checkout';
import { RootState } from '@global/store/store';
import { LOCALE_CODES } from '@global/types/types';

export const getCartData = createAsyncThunk<
  { response: REQUEST_CART_TYPE; language: LOCALE_CODES },
  { language: LOCALE_CODES },
  { rejectValue: string }
>('cart/getCartData', async ({ language }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;

    const result = await client.query<REQUEST_CART_TYPE>({
      query: REQUEST_CART,
      variables: {
        uid: state.cart.cartId,
      },
      fetchPolicy: 'network-only',
    });

    return { response: result?.data, language: language };
  } catch (error) {
    return rejectWithValue('SERVER ERROR');
  }
});
