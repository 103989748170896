import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_TYPE,
} from '@global/requests/subscriptionDetails';

import { getSku } from '@global/store/slices/cart/utils';
import { SUBSCRIPTION_STATUSES } from '@global/types/types';
import { queryBuilderFor_GET_SUBSCRIPTIONS } from '@global/requests/subscriptionDetails/GET_SUBSCRIPTIONS';

export const getSubscriptions = createAsyncThunk<
  boolean,
  { uId: string },
  { rejectValue: string }
>('cart/getSubscriptions', async ({ uId }, { rejectWithValue }) => {
  try {
    const query = queryBuilderFor_GET_SUBSCRIPTIONS({
      userUid: uId,
      statuses: [
        SUBSCRIPTION_STATUSES.Active,
        SUBSCRIPTION_STATUSES.Cancelling,
        SUBSCRIPTION_STATUSES.Created,
        SUBSCRIPTION_STATUSES.Fulfilled,
      ],
    });

    const result = await client.query<GET_SUBSCRIPTIONS_TYPE>({
      query: GET_SUBSCRIPTIONS,
      variables: { query },
    });

    const subAlreadyExists =
      result.data?.fullTextSearchSubscriptionContracts?.page[0]?.hiConsistsOf?.filter(
        (el) => el.biiSalable.maId === getSku()
      );
    return Boolean(subAlreadyExists?.length);
  } catch (e) {
    return rejectWithValue('SERVER ERROR');
  }
});
