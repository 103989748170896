import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import {
  PRODUCT_BY_MAID,
  PRODUCT_BY_MAID_TYPE,
} from '@global/requests/subscriptionDetails';
import { IProduct } from '@global/types/types';

export const getProductByMaId = createAsyncThunk<
  IProduct,
  string,
  { rejectValue: string }
>('subscription/getProductByMaId', async (maId, { rejectWithValue }) => {
  try {
    const result = await client.query<PRODUCT_BY_MAID_TYPE>({
      query: PRODUCT_BY_MAID,
      variables: { maId },
    });

    return result.data.getViaxConfigurableSalableProduct;
  } catch (error) {
    return rejectWithValue('SERVER_ERROR');
  }
});
