import { gql } from '@apollo/client';
import { IOrder } from '@global/types/types';

type IOrderAsSubscription = IOrder & {
  hiConsistsOf: {
    hiConsistsOf: {
      subsValidPeriod: {
        start: string;
        end: string;
      };
      paybNextBillDate: string;
    }[];
  }[];
};

// Requests
export interface REQUEST_CART_TYPE {
  getViaxCustomerOrder: IOrderAsSubscription;
}

export const REQUEST_CART = gql`
  query getCustomerOrderByUid($uid: UUID!) {
    getViaxCustomerOrder(uid: $uid) {
      biId
      id
      biCreatedAt
      corPromoCodes {
        ... on ViaxTrialPromotion {
          id
          uid
          prmId
          typeName
          prmName
          trpDuration {
            amount
            units
          }
        }
      }

      # order items
      paybPayment {
        ... on WPSCustomerPeriodicPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
          wPaymentType
        }
      }
      wContactPhone
      pricPrice(
        _mapsOn: [{ code: "TAX" }, { code: "TOTAL" }, { code: "SUBTOTAL" }]
      ) {
        edges {
          mapsOn {
            code
          }
          node {
            amount
            units {
              code
            }
          }
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
      hiConsistsOf {
        hiConsistsOf {
          ... on ViaxSubscriptionContract {
            subsValidPeriod {
              start
              end
            }
            paybNextBillDate
          }
        }
      }
      paybBillingAddress {
        uid
        id
        typeName
        gadCountry {
          name
          uid
          numericCode
          iso2Code
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
      hiConsistsOf {
        # primary key; order item number
        biiId
        # sorting key
        biiSequentialId
        # product total price
        pricPrice(_mapsOn: [{ code: "TOTAL" }]) {
          edges {
            mapsOn {
              code
            }
            node {
              amount
              units {
                code
              }
            }
          }
        }
        # product data
        biiSalable {
          ... on ViaxConfigurableSalableProduct {
            uid
            maId
            maName
            imageUrl
            pricPrice(_mapsOn: { code: "PRICE" }) {
              edges {
                mapsOn {
                  code
                }
                node {
                  amount
                }
              }
            }
          }
        }

        ... on SubscriptionContractItem {
          subsActivationDate
        }
      }
    }
  }
`;
