import { gql } from '@apollo/client';

export interface GET_ORDER_BY_ID_TYPE {
  getCustomerOrderByBiId: {
    biId: string;
    id: string;
    bpStatus: {
      code: string;
    };
  };
}

export const GET_ORDER_BY_ID = gql`
  query getCustomerOrderByBiId($biId: String!) {
    getCustomerOrderByBiId(biId: $biId) {
      biId
      id
      bpStatus {
        code
      }
    }
  }
`;
