import {
  COUNTRY_CONFIG,
  AustraliaConfig,
  GermanConfig,
  USConfig,
  UkConfig,
} from '@global/App/constants/location/configs';

export const CURRENCY_SYMBOLS = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  AUD: 'AUD $',
  CAD: 'CAD $',
};

export const showBillingAddressForPaymentMethods = ['Card'];

export const LOCALES_CONFIG = {
  'en-us': USConfig,
  'en-au': AustraliaConfig,
  'en-be': {} as COUNTRY_CONFIG,
  'en-br': {} as COUNTRY_CONFIG,
  'en-ca': {} as COUNTRY_CONFIG,
  'en-cn': {} as COUNTRY_CONFIG,
  'en-dk': {} as COUNTRY_CONFIG,
  'en-fr': {} as COUNTRY_CONFIG,
  'en-gb': UkConfig,
  'en-de': GermanConfig,
  'de-de': GermanConfig,
  'en-hk': {} as COUNTRY_CONFIG,
  'en-in': {} as COUNTRY_CONFIG,
  'en-ie': {} as COUNTRY_CONFIG,
  'en-it': {} as COUNTRY_CONFIG,
  'en-jp': {} as COUNTRY_CONFIG,
  'en-mx': {} as COUNTRY_CONFIG,
  'en-nl': {} as COUNTRY_CONFIG,
  'en-nz': {} as COUNTRY_CONFIG,
  'en-no': {} as COUNTRY_CONFIG,
  'en-sg': {} as COUNTRY_CONFIG,
  'en-kr': {} as COUNTRY_CONFIG,
  'en-es': {} as COUNTRY_CONFIG,
  'en-se': {} as COUNTRY_CONFIG,
  'en-ch': {} as COUNTRY_CONFIG,
  'de-ch': {} as COUNTRY_CONFIG,
  'en-ae': {} as COUNTRY_CONFIG,
} as const;

export type LOCALE_CODES = keyof typeof LOCALES_CONFIG;
