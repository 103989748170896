import React, { useMemo } from 'react';
import { OrderItemType } from '@global/utils/orderUtils';
import { IPaybBillingAddress, TypesName } from '@global/types/types';
import { promotionClasses } from '@global/utils/promotionClasses';
import { getIsDeLocale, getIsDeOrAu } from '@global/utils/lang';
import { useTranslation } from 'react-i18next';

interface IProps {
  orderItem: OrderItemType;
  promotionType?: TypesName;
  addressDetails?: IPaybBillingAddress;
}

const OrderReviewItem: React.FC<IProps> = ({
  orderItem,
  promotionType,
  addressDetails,
}) => {
  const { t } = useTranslation();
  const cls = promotionClasses[promotionType] || '';
  const isDe = useMemo(() => getIsDeLocale(), []);
  const isDeOrAu = getIsDeOrAu(addressDetails?.gadCountry?.iso2Code);
  return (
    <>
      <span className="order-review-item__name">{orderItem.maName}</span>
      <div className="order-review-item">
        <span className="order-review-item__text">
          <p>{t('checkout.review.subscription')}</p>
        </span>
        <span className="order-review-placeholdername">
          <p>{t('checkout.summary.itemPlaceholder')}</p>
        </span>
        <span className={`order-review-item__price ${cls}`}>
          {orderItem.price.code}
          {isDe && isDeOrAu
            ? orderItem.totalPrice
              ? orderItem.totalPrice.value.toFixed(2)
              : orderItem.price.value.toFixed(2)
            : orderItem.price.value.toFixed(2)}
        </span>
      </div>
    </>
  );
};

export default OrderReviewItem;
