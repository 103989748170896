import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@global/store/hooks';
import { countriesSelector } from '@global/store/slices/country/countrySelector';
import { CountryService } from '@global/utils/CountryService';
import { LOCALES_CONFIG } from '@global/App/constants/location/constants';
import { LOCALE_CODES } from '@global/types/types';
import { ActiveInputText } from '@global/components/ActiveForm/Elements/ActiveInputText';

interface IProps {
  selectedState: string;
  label?: string;
}

const INPUT_NAME = 'state';

export const StateSelectInput: React.FC<IProps> = ({
  selectedState,
  label,
}) => {
  const methods = useFormContext();
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const defaultState = selectedState;
  const selectedCountryValue = methods.getValues('country');
  const isShowStateSelect = LOCALES_CONFIG[language].stateDropdown;

  const countries = useAppSelector(countriesSelector);
  const selectedCountry = useMemo(
    () => CountryService.getCountryByIsoCode(countries, selectedCountryValue),
    [countries, selectedCountryValue]
  );

  return isShowStateSelect && selectedCountry?.regions?.length ? (
    <div
      className={
        'active-validation-field' +
        ' country-select-field' +
        (methods.formState.errors.state ? ' validation-error' : '') +
        (methods.watch(INPUT_NAME) && methods.watch(INPUT_NAME) !== -1
          ? ' validation-success'
          : '')
      }
    >
      <label htmlFor="card_billingaddress_state">
        {label ?? t('activeForm.stateLabel')}
      </label>
      <div className="select-wrapper">
        <fieldset>
          <select
            id="card_billingaddress_state"
            defaultValue={defaultState}
            {...methods.register(INPUT_NAME)}
          >
            <option value="" hidden>
              {t('activeForm.stateSelect')}
            </option>
            {selectedCountry?.regions?.map((region) => {
              return (
                <option key={region.id} value={region.isoCodeShort}>
                  {region.name}
                </option>
              );
            })}
          </select>
        </fieldset>

        {methods.formState.errors[INPUT_NAME] && (
          <span className="error">
            {methods.formState.errors[INPUT_NAME].message}
          </span>
        )}
      </div>
    </div>
  ) : (
    <ActiveInputText
      inputId="card_billingaddress_state"
      inputName={INPUT_NAME}
      defaultValue={defaultState}
    />
  );
};
