// Modules
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Css and assets
import './style.scss';
import { ReactComponent as UserIcon } from '@assets/images/User.svg';

// Components
import { UserDropdown } from './components/UserDropdown';

// Utils
import { Burger } from '../Burger';
import { useAuth } from '@global/hooks/useAuth';

export const MenuNavigation = () => {
  const { t } = useTranslation();
  const { isLoggedIn, user } = useAuth();

  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="nav-menu">
        <div className="link-icon account-icon">
          {isLoggedIn ? (
            <span className="user-icon">
              {user?.given_name.substring(0, 1)}
              {user?.family_name.substring(0, 1)}
            </span>
          ) : (
            <UserIcon
              className="icon-svg"
              role="img"
              title="Account"
              aria-label="Account"
            />
          )}
          <UserDropdown />
        </div>
      </div>

      <div className="nav-menu__mobile-menu" onClick={openMenu}>
        <span>
          {menuOpen
            ? t('header.mobileMenu.close')
            : t('header.mobileMenu.open')}
        </span>
        <Burger isOpen={menuOpen} />
      </div>

      <div className={menuOpen ? 'mobile-nav-menu open' : 'mobile-nav-menu'}>
        <div className="section-extra">
          <div className="section-user">
            <UserDropdown setMenuOpen={setMenuOpen} isMobile={true} />
          </div>
        </div>
      </div>
    </>
  );
};
