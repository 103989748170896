import React from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import './style.scss';

// Components
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

// Utils
import analytic from '@global/utils/AdobeAnalytics';
import { LOCALES_CONFIG } from '@global/App/constants/location/constants';
import { DEFAULT_LOCALE_CONFIG } from '@global/App/constants/location/configs';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  forceActive?: boolean;
  variant?: 'primary' | 'secondary';
}

export const ButtonCheckout: React.FC<IProps> = ({ text, variant }) => {
  const { i18n } = useTranslation();
  const { dataLayerBtnTracker } = analytic;

  const link =
    LOCALES_CONFIG[i18n.language]?.links?.subscribe ||
    DEFAULT_LOCALE_CONFIG.links.subscribe;

  return (
    <a
      className={`add-to-cart-btn-link`}
      href={link}
      onClick={() =>
        dataLayerBtnTracker({ btnType: 'cta', btnName: `${text}` })
      }
    >
      <ButtonCTA variant={!variant ? 'primary' : variant} id="add-to-cart-btn">
        {text}
      </ButtonCTA>
    </a>
  );
};
