import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { UPDATE_BILLING_INFORMATION } from '@pages/checkout/components/BillingInformation/queries';
import { RootState } from '@global/store/store';

export const clearBillingInformation = createAsyncThunk(
  'cart/clearBillingInformation',
  async (_, { getState }) => {
    const state = getState() as RootState;

    await client.mutate({
      mutation: UPDATE_BILLING_INFORMATION,
      variables: {
        input: {
          biId: state.cart.cartId,
          wTOSAccepted: false,
          paybBillingAddress: null,
          paybPayment: {
            typeName: 'WPSCustomerPeriodicPayment',
            _wPSCustomerPeriodicPayment: {
              ccpaNameOnCard: null,
            },
          },
          wContactPhone: null,
          corPromoCodes: [],
        },
      },
    });
  }
);
