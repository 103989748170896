// Modules
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@global/components/Header/style.scss';

// Components
import { ReactComponent as Logo } from '@assets/images/wiley_logo.svg';
import { MenuNavigation } from './components/MenuNavigation';

// Utils
import { Pages } from '@global/App/constants/constants';
import { matchCurrentLocation } from '@src/global/localization/services';
import { LOCALE_CODES } from '@global/types/types';

export const Header: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  let title = '';

  if (matchCurrentLocation(Pages.CHECKOUT)) {
    title = t('header.subtitle');
  }

  const isCheckoutPage = location.pathname === `/${language}${Pages.CHECKOUT}`;

  return (
    <header className="navigation-container">
      <div className={`header ${!title ? 'header_logged' : ''}`}>
        <div className="header__container">
          <a href={t('header.links.logo')} className="header__logo-wrapper">
            <Logo
              role="img"
              title="Wiley"
              aria-label="Wiley - Global Leader in Publishing, Education and Research"
              className="header__logo"
            />
          </a>

          {title && <span className="header__subheading">{title}</span>}

          {!isCheckoutPage && <MenuNavigation />}
        </div>
      </div>
    </header>
  );
};
