// Modules
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

// CSS and assets
import './style.scss';

// Utils
import { Pages } from '@global/App/constants/constants';
import { prefixPath } from '@global/localization/services';
import { useAuth } from '@global/hooks/useAuth';
import analytic from '@global/utils/AdobeAnalytics';

interface IProps {
  isMobile?: boolean;
  setMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserDropdown: React.FC<IProps> = ({ isMobile, setMenuOpen }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { doLogout, isLoggedIn } = useAuth();

  return (
    <div
      className={
        isLoggedIn
          ? 'user-dropdown user-dropdown__logged-in'
          : 'user-dropdown user-dropdown__logged-out'
      }
    >
      <div className="user-dropdown__selection-box">
        {!isLoggedIn ? (
          <button
            className="user-dropdown__login-btn"
            onClick={() => {
              analytic.dataLayerBtnTracker({
                btnType: 'link',
                btnName: t('header.dropdown.user.login'),
              });
              history.push(prefixPath(Pages.LOGIN, i18n.language));
              isMobile && setMenuOpen(false);
            }}
          >
            {t('header.dropdown.user.login')}
          </button>
        ) : (
          <>
            <Link to={prefixPath(Pages.SUBSCRIPTION_DETAILS, i18n.language)}>
              <button
                className="user-dropdown__my-account-btn"
                onClick={() => {
                  analytic.dataLayerBtnTracker({
                    btnType: 'link',
                    btnName: t('header.dropdown.user.sub'),
                  });
                  isMobile && setMenuOpen(false);
                }}
              >
                {t('header.dropdown.user.sub')}
              </button>
            </Link>
            <button
              className="user-dropdown__logout-btn"
              onClick={() => {
                analytic.dataLayerBtnTracker({
                  btnType: 'link',
                  btnName: t('header.dropdown.user.logout'),
                });
                analytic.dataLayerForm({
                  formName: 'header',
                  formEvent: 'LogoutSuccess',
                });
                doLogout();
                history.push(prefixPath(Pages.LOGIN, i18n.language));
              }}
            >
              {t('header.dropdown.user.logout')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};
