import React, { FC, memo, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import paypalLogo from '@assets/images/paypal.png';

// Utils
import { expirationDateFormat } from '@global/utils/StringService';

import { IPaybPayment, PaymentInfo } from '@global/types/types';

interface IProps {
  disabled: boolean;
  paymentInfo: PaymentInfo | null;
  isShow: boolean;
  toggleOpen: () => void;
}

export const PaymentInfoView: FC<IProps> = memo(
  ({ disabled, paymentInfo, isShow, toggleOpen }) => {
    const { t } = useTranslation();

    if (!paymentInfo) return null;

    const fragments: Record<IPaybPayment['wPaymentType'], ReactNode> = {
      PayPal: (
        <div className="sub-information__column">
          <span className="sub-information__text_gray sub-information__title">
            {t('subDetails.sub.billing.payment')}
          </span>
          <span className="all-images">
            <img src={paypalLogo} alt="PayPal" />
          </span>
        </div>
      ),
      Card: (
        <div className="sub-information__column">
          <span className="sub-information__text_gray">
            {t('subDetails.sub.billing.payment')}
          </span>
          <span className="sub-information__text_black">
            {paymentInfo.cardNumber}
          </span>
          <span className="sub-information__text_black">
            {t('subDetails.sub.billing.expiration')}{' '}
            {expirationDateFormat(paymentInfo?.expirationDate)}
          </span>
        </div>
      ),
    };

    const fragment = fragments[paymentInfo.wPaymentType];

    return (
      <div className="sub-information__payment-container">
        {fragment}

        <button
          className={`sub-information__change-payment-btn btn-toggle ${
            isShow ? 'btn-toggle__active' : ''
          }`}
          disabled={disabled}
          onClick={toggleOpen}
        >
          {t('subDetails.sub.billing.paymentEditCta.btnText')}
        </button>
      </div>
    );
  }
);
PaymentInfoView.displayName = 'PaymentInfoView';
