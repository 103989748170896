import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { WPS_SAVE_PAYMENT } from '@global/requests/checkout';
import { RootState } from '@global/store/store';
import { IPayment } from '@src/global/types/types';

export const updateWpsPaymentInformation = createAsyncThunk<
  string,
  { wpsPaymentData: IPayment },
  { rejectValue: string }
>('cart/wpsSave', async ({ wpsPaymentData }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;

    const result = await client.mutate({
      mutation: WPS_SAVE_PAYMENT,
      variables: {
        uid: state.cart.cartId,
        wpsPaymentData: wpsPaymentData,
      },
    });

    return result?.data?.wSavePmntContext.result;
  } catch (error) {
    return rejectWithValue('SERVER ERROR');
  }
});
