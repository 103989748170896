import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonShow } from '@global/components/ActiveForm/Elements/ButtonShow';

interface IProps {
  inputName: string;
  confirmationInputName?: string;
  inputType?: string;
  defaultValue?: string;
  placeholder?: string;
  inputId?: string;
}

export const ActiveInputText: React.FC<IProps> = ({
  inputName,
  confirmationInputName,
  inputType,
  defaultValue,
  placeholder,
  inputId,
}) => {
  const methods = useFormContext();
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm',
  });

  const options = useMemo(
    () => ({
      onChange: (e) => {
        if (!confirmationInputName) return;

        const confirmationInputValue =
          inputName !== 'password'
            ? methods.watch(confirmationInputName)?.trim()
            : methods.watch(confirmationInputName);

        if (methods.formState.dirtyFields[confirmationInputName]) {
          methods.setError(
            confirmationInputName,
            {
              type: 'match',
              message: t(`validationErr.${confirmationInputName}Match`),
            },
            { shouldFocus: false }
          );
        }

        if (confirmationInputValue === e.target.value) {
          methods.clearErrors(confirmationInputName);
        }
      },
    }),
    [confirmationInputName, methods]
  );

  const getDynamicLabel = (inputName, inputId) => {
    let label;
    if (inputName === 'address2') {
      label = (
        <label htmlFor={inputId} className="addressTwo">
          {t(`${inputName}Label`)}
          <span> {t('optionalText')}</span>
        </label>
      );
    } else {
      label = <label htmlFor={inputId}>{t(`${inputName}Label`)}</label>;
    }
    return label;
  };

  const wrapperCls = useMemo(
    () =>
      `${inputName}-field ` +
      'active-validation-field' +
      (methods.formState.errors[inputName] ? ' validation-error' : '') +
      (methods.watch(inputName) ? ' validation-success' : ''),
    [inputName, methods]
  );

  return (
    <div className={wrapperCls}>
      {getDynamicLabel(inputName, inputId)}
      {inputType === 'password' ? (
        <div className={'show-hide-wrapper'}>
          <input
            defaultValue={defaultValue}
            type={'password'}
            id={inputId}
            placeholder={placeholder}
            {...methods.register(inputName, options)}
          />
          <ButtonShow />
        </div>
      ) : (
        <input
          defaultValue={defaultValue}
          type={inputType ?? 'text'}
          placeholder={placeholder}
          id={inputId}
          {...methods.register(inputName, options)}
        />
      )}

      {methods.formState.errors[inputName] && (
        <span className="error">
          {methods.formState.errors[inputName].message}
        </span>
      )}
    </div>
  );
};
