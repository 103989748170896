import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { EdgesType } from '@global/types/types';
import { RootState } from '@global/store/store';

import {
  ATTACH_CUSTOMER_TO_ORDER,
  ATTACH_CUSTOMER_TO_ORDER_RESULT,
} from '@global/requests/checkout/ATTACH_CUSTOMER_TO_ORDER';

export const linkCartWithUser = createAsyncThunk<
  string | boolean,
  {
    paId: string;
    prRelatedParties?: {
      edges: EdgesType[];
    };
  },
  { rejectValue: string }
>(
  'cart/linkCartWithUser',
  async ({ prRelatedParties, paId }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const cartUid = state.cart.cartId;
    const currentPaIds = prRelatedParties?.edges?.map(
      (edge) => edge.node?.paId
    );

    if (!cartUid) return false;

    const result = await client.mutate<ATTACH_CUSTOMER_TO_ORDER_RESULT>({
      mutation: ATTACH_CUSTOMER_TO_ORDER,
      variables: {
        currentPaIds: currentPaIds,
        uid: cartUid,
        paId: paId,
      },
    });

    if (result.data.wAttachCustomerToOrder.attached === 'SUCCESS') return true;

    return rejectWithValue('SERVER ERROR');
  }
);
