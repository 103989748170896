// Modules
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@global/store/hooks';
import { cartSelector } from '@global/store/slices/cart/selectors';

// Css and assets
import './style.scss';

// Components
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';
import { useNotification } from '@global/hooks/useNotification';
import { NotificationTypes } from '@global/components/Notification';

// Utils
import { Pages } from '@global/App/constants/constants';
import { OrderItemType, OrderPriceType } from '@global/utils/orderUtils';
import { buildStringWithTitleAndVat, getIsDeLocale } from '@global/utils/lang';
import {
  matchCurrentLocation,
  prefixPath,
} from '@global/localization/services';

// Constants and Types
import * as Constants from '@global/App/constants/constants';
import {
  ICorPromoCodes,
  IPaybBillingAddress,
  LOCALE_CODES,
} from '@global/types/types';
import OrderReviewItem from './Item';
import { CONFIRM_PAYMENT } from '@src/global/requests/checkout';
import { useMutation } from '@apollo/client';
import { LocalStorage } from '@global/utils/LocalStorage';
import { useHistory } from 'react-router-dom';

interface IProps {
  orderItems?: OrderItemType[];
  checkoutStep?: number;
  checkoutValid?: boolean;
  orderPrice?: OrderPriceType;
  isLoading?: boolean;
  isSummaryLoading?: boolean;
  supplier?: string;
  promotions?: ICorPromoCodes[] | null;
  cartId: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
  addressDetails?: IPaybBillingAddress;
}

const defaultOrderPrice = {
  total: {
    code: '',
    value: 0,
  },
  tax: {
    code: '',
    value: 0,
  },
};

const OrderReview: React.FC<IProps> = ({
  promotions,
  orderItems,
  checkoutStep,
  isLoading,
  orderPrice = defaultOrderPrice,
  isSummaryLoading,
  supplier,
  cartId,
  setIsLoading,
  onSuccess,
  addressDetails,
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;
  const isDe = useMemo(() => getIsDeLocale(language), []);
  const cartState = useAppSelector(cartSelector);
  const { setShowNotification } = useNotification();
  const [error, setError] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const isShowVatId = isDe;

  useEffect(() => {
    error && setShowNotification([NotificationTypes.error, error]);
  }, [error]);

  const [confirmPayment] = useMutation(CONFIRM_PAYMENT);

  async function confirmPaymentFunc() {
    setIsClicked(true);
    setIsLoading(true);
    const confirmPaymentResult = await confirmPayment({
      variables: {
        uid: cartId,
      },
    });
    if (confirmPaymentResult.data?.wConfirmPayment?.status === 'OK') {
      setIsLoading(false);
      LocalStorage.cartId = undefined;
      LocalStorage.orderId = cartId;
      onSuccess();
      history.push(prefixPath(Pages.ORDER_CONFIRMATION, i18n.language));
    }
    if (confirmPaymentResult.data?.wConfirmPayment?.status === 'ERROR') {
      setIsLoading(false);
      setError(t('checkout.step3.validationErr.error'));
      setIsClicked(false);
    }
    setError('');
  }

  const continueToPaymentHandler = () => {
    confirmPaymentFunc();
  };

  const isCartEmpty = orderItems && !orderItems.length;

  const vatTitle = buildStringWithTitleAndVat(
    language,
    t('checkout.summary.tax'),
    checkoutStep
  );

  return (
    <div
      className={
        !isCartEmpty ? 'order-review' : 'order-review order-review-empty'
      }
    >
      <div className="order-review__wrapper">
        <div className="order-review__content">
          {isSummaryLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            <>
              {/* LIST OF ITEMS BEING PURCHASED */}
              {!isCartEmpty &&
                orderItems?.map((item, index) => {
                  return (
                    <div key={item.maId + index}>
                      <OrderReviewItem
                        promotionType={promotions?.[0]?.typeName} // TODO improve
                        key={item.maName + index}
                        orderItem={item}
                        addressDetails={addressDetails}
                      />
                    </div>
                  );
                })}

              {/* PROMOTION text */}
              {promotions &&
                promotions.map((promotion) => (
                  <div
                    key={promotion.prmName}
                    className="order-review__item-tax"
                  >
                    <span className="order-review__item-tax--left">
                      {t('checkout.summary.promo.title')}
                    </span>
                    <span className="order-review__item-tax--right order-review__item-promotion">
                      {promotion.prmName}
                    </span>
                  </div>
                ))}

              {/* TAXES */}
              <div className="order-review__item-tax">
                <span className="order-review__item-tax--left order-review__item-tax--left__padding">
                  {vatTitle}
                </span>
                <span className="order-review__item-tax--right-bold">
                  {orderPrice.tax.code}
                  {orderPrice.tax.value.toFixed(2)}
                </span>
              </div>

              {/* TOTAL */}
              <div className="order-review__item-total">
                <span className="order-review__item-total--left">
                  {t('checkout.review.total')}
                </span>
                <span className="order-review__item-total--right-bold">
                  {orderPrice.total.code}
                  {orderPrice.total.value.toFixed(2)}
                </span>
              </div>

              <div className="order-review__agreement">
                <span className="order-review__agreement--text">
                  {t('checkout.summary.terms&conditions.instructions')}{' '}
                  <a
                    href={`https://www.wiley.com/${i18n.language}/terms-of-use`}
                    target="_blank"
                    className="payment-form__agreement--link"
                    rel="noreferrer"
                  >
                    {t('checkout.summary.terms&conditions.link')}
                  </a>{' '}
                  {t('checkout.summary.terms&conditions.instructions2')}
                </span>
              </div>

              {/* CTA */}
              <ButtonCTA
                variant="primary"
                className="order-review__submit-btn"
                disabled={cartState.isLoading || isClicked}
                onClick={continueToPaymentHandler}
              >
                {isLoading ? (
                  <div className="spinner"></div>
                ) : (
                  t('checkout.review.placeorder')
                )}
              </ButtonCTA>

              {supplier && (
                <div className="supplier-Info">
                  <h4>{t('checkout.summary.supplier.title')}</h4>
                  <p>{supplier}</p>
                  {isShowVatId && <p>{t('checkout.summary.supplier.vatId')}</p>}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderReview;
