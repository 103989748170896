// Modules
import React, { useContext, useMemo, useState } from 'react';
import { MappedPaymentComponentsType } from '@global/types/types';

// CSS and assets
import '@pages/checkout/components/SecurePayment/style.scss';

// Utils

import PaymentOptions from '@src/global/components/PaymentOptions';
import { ReloadInitResponseContext } from './SecurePayment';

import PayPal from '@src/global/components/WpsPayment/PayPal';
import Card from '@src/global/components/WpsPayment/Card';
import { getPaymentMethodsCount } from '@src/global/utils/paymentMethodRules';

export const PaymentForm: React.FC = () => {
  const { paymentInitData, setPayment, paymentMethod, setPaymentMethod } =
    useContext(ReloadInitResponseContext);

  const [message, setMessage] = useState<string | null>(null);

  const [paymentMethodsCount, setPaymentMethodsCount] = useState(
    getPaymentMethodsCount(paymentInitData)
  );

  const paymentComponentMap: MappedPaymentComponentsType = useMemo(
    () => ({
      Card: Card,
      PayPal: PayPal,
    }),
    []
  );

  return (
    <>
      {/* Show any error or success messages */}
      {message && <span className="payment-form__error-msg">{message}</span>}

      {/* Show all payment methods with radio options */}
      {
        <PaymentOptions
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          setPayment={setPayment}
          paymentMethodsCount={paymentMethodsCount}
          paymentInitData={paymentInitData}
          paymentComponentMap={paymentComponentMap}
        />
      }
    </>
  );
};
