import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@pages/orderConfirmation/style.scss';

// Components
import { OrderSummary } from '@global/components/OrderSummary';
import { useAuth } from '@global/hooks/useAuth';

import {
  Header,
  OrderInfo,
  ItemsList,
} from '@pages/orderConfirmation/components';

// Utils
import { CURRENCY_SYMBOLS } from '@global/App/constants/location/constants';
import { getOrderPrice } from '@global/utils/orderUtils';
import { dateFormatter } from '@global/utils/lang';
import analytic from '@global/utils/AdobeAnalytics';
import { LocalStorage } from '@global/utils/LocalStorage';

// GraphQL
import {
  REQUEST_CART,
  REQUEST_CART_TYPE,
} from '@global/requests/orderConfirmation/';

// Types
import {
  IBillingInfo,
  IPaybBillingAddress,
  LOCALE_CODES,
  PaymentInfo,
} from '@global/types/types';
import { Spinner } from '@global/components/UI Elements/Spinner';

export const OrderConfirmation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const { user, isLoggedIn } = useAuth();

  const [orderId, setOrderId] = useState<string>(null);
  const [orderUid, setOrderUid] = useState<string>(null);

  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const [billingInfo, setBillingInfo] = useState<IBillingInfo | null>(null);
  const [userAddress, setUserAddress] = useState<IPaybBillingAddress | null>(
    null
  );

  const { data, loading } = useQuery<REQUEST_CART_TYPE>(REQUEST_CART, {
    variables: {
      uid: orderUid,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !orderUid,
    onCompleted: (data) => {
      if (data?.getViaxCustomerOrder?.paybBillingAddress) {
        const {
          paybBillingAddress: {
            upaLine1,
            upaLine2,
            upaPostcode,
            upaCity,
            gadStateOrProvince,
            gadCountry,
          },
          paybPayment,
          wContactPhone,
          biId,
        } = data.getViaxCustomerOrder;

        setUserAddress(data?.getViaxCustomerOrder?.paybBillingAddress);
        if (paybPayment) {
          const {
            ccpaNameOnCard,
            ccpaCardNumber,
            ccpaExpirationDate,
            wPaymentType,
          } = paybPayment;

          setPaymentInfo({
            nameOnCard: ccpaNameOnCard,
            cardNumber: ccpaCardNumber,
            expirationDate: ccpaExpirationDate,
            wPaymentType: wPaymentType,
          });

          setBillingInfo({
            addressOne: upaLine1,
            addressTwo: upaLine2,
            city: upaCity,
            name: paybPayment.ccpaNameOnCard,
            tel: wContactPhone,
            state: gadStateOrProvince,
            zip: upaPostcode,
            country: {
              name: gadCountry.name || '',
              numericCode: gadCountry.numericCode || '',
              iso2Code: gadCountry.iso2Code || '',
            },
          });

          setOrderId(biId);
        }
      }
    },
  });

  const cartContents = useMemo(
    () => data?.getViaxCustomerOrder?.hiConsistsOf,
    [data]
  );

  const promoCodes = useMemo(
    () =>
      data?.getViaxCustomerOrder?.corPromoCodes?.length > 0
        ? data.getViaxCustomerOrder?.corPromoCodes
        : null,
    [data]
  );

  const orderItems = useMemo(() => {
    const priceContents = data?.getViaxCustomerOrder?.pricPrice.edges;

    const priceNode = priceContents?.find(
      ({ mapsOn: { code } }) => code === 'SUBTOTAL'
    )?.node;

    const totalPriceNode = priceContents?.find(
      ({ mapsOn: { code } }) => code === 'TOTAL'
    )?.node;

    return cartContents?.map((item) => {
      return {
        maName: item.biiSalable.maName,
        maId: item.biiSalable.maId,
        price: {
          value: priceNode?.amount || 0,
          code: CURRENCY_SYMBOLS[priceNode?.units?.code],
        },
        totalPrice: {
          value: totalPriceNode?.amount || 0,
        },
      };
    });
  }, [data, cartContents]);

  const orderPrice = useMemo(() => {
    if (data?.getViaxCustomerOrder?.pricPrice) {
      return getOrderPrice(data.getViaxCustomerOrder.pricPrice?.edges);
    }
    return {
      total: { value: 0, code: '', units: '' },
      tax: { value: 0, code: '' },
    };
  }, [data]);

  const issueDate = useMemo(
    () =>
      cartContents?.[0]
        ? dateFormatter(cartContents[0].subsActivationDate, language)
        : '',
    [cartContents]
  );

  const getOrderId = useCallback(() => {
    const orderUid = LocalStorage.orderId;
    setOrderUid(orderUid);
  }, []);

  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderId();
  }, []);

  useEffect(() => {
    if (orderItems && orderId && orderPrice) {
      analytic.dataLayerOrderConfirmation({
        orderItems: orderItems,
        orderId: orderId,
        orderPrice: orderPrice,
        promotions: promoCodes,
        isLoggedIn: isLoggedIn,
      });
    }
  }, [orderId, orderPrice, orderItems, promoCodes]);

  return (
    <main className="order-confirmation">
      <Header email={user?.email} />

      {loading && <Spinner size={'64px'} withOverlay />}
      {!loading && (
        <div className="order-confirmation__container">
          <div className="order-confirmation__steps-container">
            <OrderInfo
              orderId={orderId}
              issueDate={issueDate}
              email={user?.email}
              paymentInfo={paymentInfo}
              billingInfo={billingInfo}
            />

            <ItemsList
              loading={loading}
              cartContents={cartContents}
              promotions={promoCodes}
            />
          </div>

          <div className="order-summary-confirmation">
            <OrderSummary
              orderItems={orderItems}
              orderPrice={orderPrice}
              isSummaryLoading={loading}
              supplier={t('checkout.summary.supplier.description')}
              promotions={promoCodes}
              addressDetails={userAddress}
            />
          </div>
        </div>
      )}
    </main>
  );
};
