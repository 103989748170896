import { IGadCountry } from '@global/types/types';

export interface PaybBillingAddress {
  upaLine1: string;
  upaLine2: string;
  upaCity: string;
  gadStateOrProvince: string;
  gadCountry: IGadCountry;
  upaPostcode: string;
  uid?: string;
}

export interface PaybBillingAddressView {
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  country: {
    name: string;
    numericCode: string;
    iso2Code?: string;
  };
  zip: string;
  uid?: string;
}

export enum STATUSES {
  FALSE = 'false',
  TRUE = 'true',
  PENDING = 'pending',
}

export interface paymentInfo {
  Card?: {
    appId: string;
    region: string;
    currency: string;
    amount: number;
    intentType: string;
    timestamp: number;
    email: string;
    paymentMethods: string[];
    paymentToken: string;
    paymentReference: string;
  };
  PayPal?: {
    appId: string;
    region: string;
    currency: string;
    amount: number;
    intentType: string;
    timestamp: number;
    email: string;
    paymentMethods: string[];
    paymentToken: string;
    paymentReference: string;
  };
}
