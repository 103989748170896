import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  regCapital,
  regLetter,
  regDigit,
  regSpecial,
  regPass,
} from '@global/components/ActiveForm/regexp';

// CSS and assets
import './style.scss';

export const PasswordRequirements: React.FC = () => {
  const methods = useFormContext();
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm.passwordReqs',
  });
  return (
    <div className="row-description">
      <p>{t('subtitle')}</p>
      <ul className="validation-requirements">
        <li
          className={
            methods.watch('password') && methods.watch('password').length > 9
              ? 'match'
              : ''
          }
        >
          {t('10char')}
        </li>
        <li
          className={
            RegExp(regPass).test(methods.watch('password')) ? 'match' : ''
          }
        >
          {t('3categories')}
        </li>
        <li className="sub-validation">
          <ul>
            <li
              className={
                RegExp(regCapital).test(methods.watch('password'))
                  ? 'match'
                  : ''
              }
            >
              {t('uppercase')}
            </li>
            <li
              className={
                methods.watch('password') &&
                RegExp(regLetter).test(methods.watch('password'))
                  ? 'match'
                  : ''
              }
            >
              {t('lowercase')}
            </li>
            <li
              className={
                RegExp(regDigit).test(methods.watch('password')) ? 'match' : ''
              }
            >
              {t('num')}
            </li>
            <li
              className={
                RegExp(regSpecial).test(methods.watch('password'))
                  ? 'match'
                  : ''
              }
            >
              {t('special')}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
