import { Country } from '@global/types/types';
import { AWS_COUNTRIES_URL } from '@global/App/constants/constants';

export class CountryService {
  private static async request<T>(url = ''): Promise<T> {
    try {
      const randomValue8Digits = Math.floor(Math.random() * 100_000_000);
      const currentTime = Date.now();

      const response = await fetch(`${AWS_COUNTRIES_URL}${url}`, {
        headers: { 'X-Request-ID': currentTime + '-' + randomValue8Digits },
      });
      const json: T = await response.json();

      return json;
    } catch (error) {
      console.error(error);
    }
  }

  static async getCountries() {
    const result = await this.request<{ countries: Country[] }>();
    return result.countries;
  }

  static getCountryByIsoCode(countries: Country[], isoCodeTwo: string) {
    return countries.find(
      (country) =>
        country.isoCodeTwo?.toUpperCase() === isoCodeTwo?.toUpperCase()
    );
  }
}
