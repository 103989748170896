// Modules
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@global/components/NewsSignUpPanel/style.scss';
import { newsSignUpValidationSchema } from '@global/components/NewsSignUpPanel/formValidationRules';
import { useYupValidationResolver } from '@global/components/ActiveForm/utils';
import { useForm, FormProvider } from 'react-hook-form';
import { ActiveInputText } from '@global/components/ActiveForm/Elements/ActiveInputText';
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';
import {
  makePayload,
  signUpSend,
} from '@global/components/NewsSignUpPanel/formHelper';
import analytic from '@global/utils/AdobeAnalytics';

type FormValues = {
  email: string;
};

interface IProps {
  currentPage: string;
}

export const NewsSignUpPanel: React.FC<IProps> = ({ currentPage }) => {
  const { t } = useTranslation();
  const resolverSchema = newsSignUpValidationSchema();
  const rulesResolver = useYupValidationResolver(resolverSchema);
  const [loading, setLoading] = useState(false);
  const [sendingSuccess, setSendingSuccess] = useState(false);

  const formLayerProps = {
    formName: 'subscriptionForm',
    pageName: `${currentPage.replace('/', '')}`,
  };

  const methods = useForm({
    mode: 'all',
    resolver: rulesResolver,
  });

  const newsSignUpHandler = async (formSubmitData: FormValues) => {
    setLoading(true);

    const formBodyPayload = makePayload(formSubmitData.email);

    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form submit',
    });

    setSendingSuccess(await signUpSend(formBodyPayload));

    setLoading(false);
  };

  useEffect(() => {
    methods.formState.isDirty &&
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form start',
      });
  }, [methods.formState.isDirty]);

  useEffect(() => {
    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form view',
    });
  }, []);

  return (
    <div className="news-panel">
      <div className="news-panel__container">
        <span className="news-panel__title">{t('footer.news.title')}</span>

        <div className="news-panel__content">
          {sendingSuccess ? (
            <p className="news-panel__success-msg">
              {t('footer.news.success_message')}
            </p>
          ) : (
            <>
              <FormProvider {...methods}>
                <form
                  id={'news-sign-up__form'}
                  className="news-sign-up__form"
                  onSubmit={methods.handleSubmit(newsSignUpHandler)}
                >
                  <div className="news-panel__wrapper">
                    <ActiveInputText
                      inputName={'email'}
                      placeholder={t('footer.news.placeholder')}
                    />
                    <ButtonCTA
                      variant="primary"
                      className="news-panel__submit-btn"
                      type="submit"
                      disabled={!methods.formState.isValid || loading}
                    >
                      {loading ? (
                        <div className="spinner"></div>
                      ) : (
                        t('footer.news.cta')
                      )}
                    </ButtonCTA>
                  </div>
                </form>
              </FormProvider>
              <span>{t('footer.news.description')}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
