import { useTranslation } from 'react-i18next';
import { regExpName, regPass } from '@global/components/ActiveForm/regexp';
import * as yup from 'yup';

export const accountCreationSchema = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm.validationErr',
  });
  return yup.object({
    firstName: yup
      .string()
      .trim()
      .max(30, t('max30FirstNameLength'))
      .required(t('nameReq'))
      .matches(regExpName, t('nameFormat')),
    lastName: yup
      .string()
      .trim()
      .max(30, t('max30LastNameLength'))
      .required(t('lastNameReq'))
      .matches(regExpName, t('lastNameFormat')),
    email: yup.string().trim().required(t('emailReq')).email(t('emailFormat')),
    confirmEmail: yup
      .string()
      .trim()
      .required(t('confirmEmailReq'))
      .email(t('confirmEmailFormat'))
      .oneOf([yup.ref('email')], t('confirmEmailMatch')),
    password: yup
      .string()
      .required(t('passwordReq'))
      .min(10, t('passwordLength'))
      .matches(regPass, t('passwordFormat')),
    confirmPassword: yup
      .string()
      .required(t('confirmPasswordReq'))
      .oneOf([yup.ref('password')], t('confirmPasswordMatch')),
    country: yup.string().required(t('countryReq')),
  });
};
