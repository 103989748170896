import { createAsyncThunk } from '@reduxjs/toolkit';
import { Country } from '@global/types/types';
import { RootState } from '@global/store/store';
import { CountryService } from '@global/utils/CountryService';

export const getCountries = createAsyncThunk<
  Country[],
  undefined,
  { rejectValue: string }
>('country/getCountries', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    if (state.country.countries.length > 0) {
      return state.country.countries;
    }
    return await CountryService.getCountries();
  } catch (error) {
    return rejectWithValue('SERVER_ERROR');
  }
});
