// Modules
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@global/store/hooks';
import { useSelector } from 'react-redux';
import { useAuth } from '@global/hooks/useAuth';

// CSS and assets
import '@pages/checkout/components/BillingInformation/style.scss';

// Components
import { BillingInfoForm } from '@global/components/BillingInfoForm/BillingInfoForm';
import { Spinner } from '@global/components/UI Elements/Spinner';

// Utils
import analytic from '@global/utils/AdobeAnalytics';

import { IBillingInfo, LOCALE_CODES } from '@global/types/types';
import { useYupValidationResolver } from '@global/components/ActiveForm/utils';
import {
  billingInformationSchema,
  IBillingInformationSchema,
} from '@pages/checkout/components/BillingInformation/rules';
import { updateBillingInformation } from '@global/store/slices/cart/extraReducers/updateBillingInformation';
import { countriesSelector } from '@global/store/slices/country/countrySelector';
import { CountryService } from '@global/utils/CountryService';
import { toggleAgreementCheck } from '@global/store/slices/cart/cartSlice';
import { cartSelector } from '@global/store/slices/cart/selectors';
import { buildStringWithTitleAndVat } from '@global/utils/lang';
import { subscriptionSelector } from '@src/global/store/slices/subscription/selectors';

interface IProps {
  checkoutValid: boolean;
  setCheckoutValid: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  checkoutStep?: number;
  buttonId: string;
  setUpdateAdressResponse?: React.Dispatch<React.SetStateAction<any>>;
  payment?: Record<string, unknown>;
}

export const BillingInformation: React.FC<IProps> = ({
  checkoutValid,
  setCheckoutValid,
  isLoading,
  checkoutStep,
  buttonId,
  payment,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;
  const { isLoggedIn } = useAuth();
  const dispatch = useAppDispatch();
  const cartState = useSelector(cartSelector);
  const countries = useSelector(countriesSelector);
  const [isCartButtonLoading, setIsCartButtonLoading] = useState(false);
  const [isCartClicked, setIsCartClicked] = useState(false);

  const setBillingAnalyticStep = useCallback(() => {
    analytic.dataLayerCheckoutTracker({
      pageData: {
        page: {
          name: 'cart',
          section: 'checkout',
          type: `checkout`,
        },
        event: `checkout billing`,
      },
      ecommerceData: {
        orderItems: cartState.orderItems,
        currency: cartState.orderItems?.[0]?.price.units,
      },
      language,
      isLoggedIn,
    });
  }, [language, isLoggedIn, cartState.orderItems]);

  const resolverSchema = billingInformationSchema();
  const rulesResolver = useYupValidationResolver(resolverSchema);

  //React Hook Form
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: rulesResolver,
  });

  const updatePaymentInfoFormSubmitHandler = async (
    formData?: IBillingInformationSchema
  ) => {
    let data = formData;

    if (!data) {
      data = methods.getValues();
    }

    try {
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form submit',
      });
      setBillingAnalyticStep();
      const country = CountryService.getCountryByIsoCode(
        countries,
        data.country
      );

      const dataBillingInfo: IBillingInfo = {
        ...cartState.billingInfo,
        name: `${data.CCname.trim()}`,
        tel: `${data.phone.trim()}`,
        addressOne: `${data.address1.trim()}`,
        addressTwo: data.address2?.trim(),
        city: `${data.city.trim()}`,
        state: `${data.state.trim()}`,
        zip: `${data.zip.trim()}`,
        country: {
          name: country.name,
          numericCode: country.numCode.toString(),
          iso2Code: country.isoCodeTwo,
        },
      };
      dispatch(
        updateBillingInformation({
          dataBillingInfo,
          language,
        })
      );
      setIsCartButtonLoading(false);
    } catch (error) {
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form error',
        errorMsg: error.message,
      });
      setIsCartClicked(false);
      setIsCartButtonLoading(false);
    }
  };

  const formLayerProps = {
    formName: 'billingInformationForm',
    pageName: 'checkout',
  };

  // Clear errors on component render
  useEffect(() => {
    setCheckoutValid(methods.formState.isValid);
  }, [methods.formState.isValid, cartState.billingInfo]);

  useEffect(() => {
    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form view',
    });
  }, []);

  useEffect(() => {
    methods.formState.isDirty &&
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form start',
      });
  }, [methods.formState.isDirty]);

  const vatTitle = buildStringWithTitleAndVat(
    language,
    t('checkout.summary.tax'),
    checkoutStep
  );
  const agreementText = t('checkout.step2.agreement', { vatTitle });

  useEffect(() => {
    if (payment) {
      const formData = methods.getValues();
      updatePaymentInfoFormSubmitHandler(formData);
      let cancel = false;
      // const validateObj: IBillingInformationSchema = {
      //   CCname: cartState.billingInfo.name,
      //   phone: cartState.billingInfo.tel,
      //   address1: cartState.billingInfo.addressOne,
      //   state: cartState.billingInfo.state,
      //   city: cartState.billingInfo.city,
      //   zip: cartState.billingInfo.zip,
      //   country: cartState.billingInfo.country.iso2Code,
      // };

      // resolverSchema.validate(validateObj).then(() => {
      //   if (cancel) return;
      //   updatePaymentInfoFormSubmitHandler(formData);
      // });

      return () => {
        cancel = true;
      };
    }
  }, [payment]);

  return isLoading ? (
    <div style={{ padding: '20px' }}>
      <Spinner size={'64px'} />
    </div>
  ) : (
    <div className="billing-info">
      <span className="billing-info__subtitle regularText">
        {t('checkout.step2.subTitle')}
      </span>
      <span className="billing-info__info regularText">
        {t('checkout.step2.instructions')}
      </span>
      <span className="billing-info__reqd regularText">
        {t('activeForm.reqLabel')}
      </span>
      <FormProvider {...methods}>
        <BillingInfoForm
          billingInfo={cartState.billingInfo}
          buttonId={buttonId}
          buttonText={t('checkout.step2.cta')}
          isLoading={cartState.isLoading}
          disabledSubmit={
            !cartState.agreementCheck ||
            cartState.isLoading ||
            !checkoutValid ||
            isCartClicked
          }
          toggleAgreementCheck={() => {
            dispatch(toggleAgreementCheck());
          }}
          setIsCartClicked={setIsCartClicked}
          isCartButtonLoading={isCartButtonLoading}
          setIsCartButtonLoading={setIsCartButtonLoading}
          agreementText={agreementText}
          agreementCheck={cartState.agreementCheck}
        />
      </FormProvider>
    </div>
  );
};
