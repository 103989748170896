import { gql } from '@apollo/client';

export const UPDATE_BILLING_INFO_MUTATION = gql`
  mutation updateSubscriptionContract(
    $paymentUid: UUID
    $subscriptionUid: UUID
    $nameCC: String!
    $state: String!
    $addr1: String!
    $addr2: String
    $zip: String!
    $city: String!
    $phone: String!
    $countryName: String!
    $countryCode: String!
    $countryISO2: String!
  ) {
    updateViaxSubscriptionContract(
      input: {
        uid: $subscriptionUid
        wContactPhone: $phone
        paybBillingAddress: {
          typeName: "ViaxUrbanPropertyAddress"
          gadCountry: {
            name: $countryName
            numericCode: $countryCode
            iso2Code: $countryISO2
          }
          gadStateOrProvince: $state
          upaLine1: $addr1
          upaLine2: $addr2
          upaPostcode: $zip
          upaCity: $city
        }
        paybPayment: {
          uid: $paymentUid
          typeName: "WPSCustomerPeriodicPayment"
          _wPSCustomerPeriodicPayment: { ccpaNameOnCard: $nameCC }
        }
      }
    ) {
      paybBillingAddress {
        gadCountry {
          name
          numericCode
          iso2Code
        }
      }
    }
  }
`;
