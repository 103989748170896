import queryString from 'query-string';

export const getSku = () => {
  const search = location.search;
  const query = queryString.parse(search);
  return query.sku;
};

export const getPromoCode = (): string | undefined => {
  const search = location.search;
  const query = queryString.parse(search);
  return query.prom;
};
