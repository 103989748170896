import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  COUNTRY_CONFIG,
  DEFAULT_LOCALE_CONFIG,
} from '@global/App/constants/location/configs';
import { LOCALES_CONFIG } from '@global/App/constants/location/constants';

import { LOCALE_CODES } from '@global/types/types';
import { getLanguageByLocale } from '@global/utils/lang';

import en from './en.json';
import de from './de.json';

const APPLICATION_LANGUAGES = { en, de };

type Config = {
  code: LOCALE_CODES;
  resource: unknown;
  htmlLanguageCode: string;
} & COUNTRY_CONFIG;

export const configLanguage = Object.keys(LOCALES_CONFIG).reduce(
  (acc, locale: LOCALE_CODES) => {
    const localeConf: COUNTRY_CONFIG = LOCALES_CONFIG[locale];
    const lang = getLanguageByLocale(locale) || DEFAULT_LOCALE_CONFIG.language;

    return {
      ...acc,
      [locale as LOCALE_CODES]: {
        code: locale,
        htmlLanguageCode: lang,
        resource: APPLICATION_LANGUAGES[lang],
        VAT: DEFAULT_LOCALE_CONFIG.VAT,
        phoneRegExp: DEFAULT_LOCALE_CONFIG.phoneRegExp,
        phoneFormat: DEFAULT_LOCALE_CONFIG.phoneFormat,
        language: DEFAULT_LOCALE_CONFIG.locale,
        ...localeConf,
      },
    };
  },
  {}
) as Record<LOCALE_CODES, Config & COUNTRY_CONFIG>;

export const configLanguageList = Object.values(configLanguage);

const optionsLang = {
  order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag'],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
};

export const langExistsInPathList = configLanguageList.map((item) => ({
  code: item.code as string,
  lang: getLanguageByLocale(item.code) as string,
}));

const resources = configLanguageList.reduce((previousValue, currentValue) => {
  return {
    ...previousValue,
    [currentValue.code]: { translations: currentValue.resource },
  };
}, {});
const supportedLngs = configLanguageList.map((lang) => lang.code);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: DEFAULT_LOCALE_CONFIG.locale,
    supportedLngs,
    detection: optionsLang,
    lowerCaseLng: true,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  } as InitOptions);

export default i18n;
