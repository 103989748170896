import { gql } from '@apollo/client';
import { IProduct } from '@global/types/types';

export interface PRODUCT_BY_MAID_TYPE {
  maId(maId: any);

  getViaxConfigurableSalableProduct: IProduct;
}

export const PRODUCT_BY_MAID = gql`
  query GetProductByMaId($maId: String!) {
    getViaxConfigurableSalableProduct(maId: $maId) {
      maName
      maId
      maDescription
      uid
      imageUrl

      saOwnershipModels {
        uid
        omId
        __typename
        somBillingPlans(bipIsTemplate: true) {
          uid
          bipBillAtRPIT {
            name
          }
          bipIsTemplate
        }
      }
    }
  }
`;
