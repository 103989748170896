// Modules
import React from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@global/components/Footer/style.scss';

// Components
import { RoutingElement, LINK_TYPES } from '../../RoutingElement';
import { CookieLink } from '@global/components/CookieLink';

export const GbLinks: React.FC = () => {
  const { t } = useTranslation();

  const accountLinks = [
    {
      name: t('footer.account.info'),
      link: '',
      type: LINK_TYPES.text,
    },

    {
      name: t('footer.account.contact'),
      link: 'mailto:chemond@wiley.com',
      type: LINK_TYPES.email,
      withoutBold: true,
    },
    {
      name: t('footer.account.cookie'),
      element: <CookieLink />,
      type: LINK_TYPES.html,
    },
  ];

  return (
    <>
      <div className="footer__main-links">
        {accountLinks.map((item) => {
          return <RoutingElement item={item} key={item.name} />;
        })}
      </div>
    </>
  );
};
