// Modules
import React from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@global/components/Footer/style.scss';

// Components
import { RoutingElement, LINK_TYPES } from './components/RoutingElement';

import { DeLinks, GbLinks } from './components/MainLinks';

import { getIsDeLocale } from '@global/utils/lang';
import { LOCALE_CODES } from '@global/types/types';

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const generalLinks = [
    {
      name: t('footer.general.a'),
      link: t('footer.links.site_map'),
      type: LINK_TYPES.ext,
    },
    {
      name: t('footer.general.b'),
      link: t('footer.links.permissions'),
      type: LINK_TYPES.ext,
    },
    {
      name: t('footer.general.c'),
      link: t('footer.links.privacy'),
      type: LINK_TYPES.ext,
    },
    {
      name: t('footer.general.d'),
      link: t('footer.links.term_of_us'),
      type: LINK_TYPES.ext,
    },
  ];

  const Links = getIsDeLocale(language) ? DeLinks : GbLinks;

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <Links />
        </div>

        <div className="footer__bottom">
          <div className="footer__general-links">
            {generalLinks.map((item) => {
              return <RoutingElement item={item} key={item.name} />;
            })}
          </div>

          <span className="footer__copyright">
            <a
              href="https://www.wiley.com/copyright"
              target="_blank"
              rel="noreferrer"
            >
              {t('footer.copyright.part1', { year: new Date().getFullYear() })}
            </a>
            <a href="https://www.wiley.com/" target="_blank" rel="noreferrer">
              {t('footer.copyright.part2')}
            </a>
            {t('footer.copyright.part3')}
          </span>
        </div>
      </div>
    </footer>
  );
};
