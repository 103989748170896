import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@global/store/hooks';
import { useSelector } from 'react-redux';

import { useModal } from '@global/hooks/useModal';

import { CancelModalBody } from '@pages/SubscriptionDetails/components/modals';
import { useMutation } from '@apollo/client';
import { CANCEL_SUBSCRIPTION_CONTRACT } from '@global/requests/subscriptionDetails';
import { NotificationTypes } from '@global/components/Notification';

import { subscriptionSelector } from '@global/store/slices/subscription/selectors';
import { cancelSubscriptionContract } from '@global/store/slices/subscription/extraReducers/cancelSubscriptionContract';
import { getIsDeLocale } from '@global/utils/lang';
import { LOCALE_CODES } from '@global/types/types';

interface IProps {
  disabled: boolean;
  latestUid?: string;
  setShowNotification: Dispatch<SetStateAction<[NotificationTypes, string]>>;
}

export const SubscriptionInfo: FC<IProps> = ({ disabled, latestUid }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { orderPrice, periodInfo, productContent } =
    useSelector(subscriptionSelector);

  const isDe = getIsDeLocale(i18n.language as LOCALE_CODES);
  const [wCancelSubscriptionContract] = useMutation(
    CANCEL_SUBSCRIPTION_CONTRACT
  );

  const handleCancel = useCallback(async () => {
    dispatch(cancelSubscriptionContract({ t }));
  }, [wCancelSubscriptionContract, latestUid]);

  const { handleOpen } = useModal({
    content: ({ handleClose }) => (
      <CancelModalBody
        latestUid={latestUid}
        handleCancel={handleCancel}
        handleClose={handleClose}
        nextBillDate={periodInfo?.nextBillDate}
        product={{
          price: orderPrice.price,
          totalPrice: orderPrice.total,
          maId: productContent.maId,
          maName: productContent.maName,
        }}
      />
    ),
  });

  return (
    <div className="subscription__info-wrapper">
      <span className="subscription__title">
        {t('subDetails.sub.info.title')}
      </span>
      <div className="sub-information">
        <div className="sub-information__column-container">
          <div className="sub-information__column">
            <span className="sub-information__text_gray">
              {t('subDetails.sub.info.subBegan')}
            </span>
            <span className="sub-information__text_black">
              {periodInfo?.subscriptionBegan}
            </span>
          </div>
          <div className="sub-information__column">
            <span className="sub-information__text_gray">
              {t('subDetails.sub.info.costBreakdown.subtitle')}
            </span>
            <span className="sub-information__text_black">
              <span className="sub-infromation__text_black sub-information__text_price">
                {isDe ? orderPrice.total.code : orderPrice.price.code}
                {isDe
                  ? orderPrice.total.value.toFixed(2)
                  : orderPrice.price.value.toFixed(2)}
              </span>{' '}
              {t('subDetails.sub.info.costBreakdown.fee')}
            </span>
            <span className="sub-information__text_black">
              <span className="sub-infromation__text_black sub-information__text_price">
                {orderPrice.tax.code}
                {orderPrice.tax.value.toFixed(2)}
              </span>{' '}
              {t('subDetails.sub.info.costBreakdown.tax')}
            </span>
          </div>
        </div>

        <button
          className="subscription__cancel-btn"
          disabled={disabled}
          onClick={handleOpen}
        >
          {t('subDetails.sub.info.cancelCta.btnText')}
        </button>
      </div>
    </div>
  );
};
