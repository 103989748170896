import { ALMPages } from '@global/App/constants/constants';

import { LOCALE_CODES } from '@global/types/types';

import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { CheckoutLocale } from '@stripe/stripe-js/types/stripe-js/checkout';
import { interpolateString } from '@global/utils/StringService';
import { LOCALES_CONFIG } from '@global/App/constants/location/constants';
import { DEFAULT_LOCALE_CONFIG } from '@global/App/constants/location/configs';
import { getUserFormToken } from '@global/hooks/useAuth/utils';

export const dateFormatter = (date, locale: LOCALE_CODES) => {
  return new Date(date).toLocaleString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export const dateFormatterShort = (date, locale: LOCALE_CODES) => {
  return new Date(date).toLocaleString(locale, {
    month: 'short',
    day: 'numeric',
  });
};

export const buildTitleWithName = (locale: LOCALE_CODES, title) => {
  const name = getUserFormToken()?.given_name;

  const template =
    LOCALES_CONFIG[locale]?.templates?.titleWithName ||
    DEFAULT_LOCALE_CONFIG.templates.titleWithName;

  return interpolateString(template, { name, title });
};

export const buildStringWithTitleAndVat = (
  locale: LOCALE_CODES,
  defaultTitle: string,
  step: number
) => {
  const vatKeyForTemplate = `vatStep${step}`;
  const currentConfig = LOCALES_CONFIG[locale];
  const vat = currentConfig?.VAT || DEFAULT_LOCALE_CONFIG.VAT;
  const template =
    currentConfig?.templates?.[vatKeyForTemplate] ||
    DEFAULT_LOCALE_CONFIG.templates[vatKeyForTemplate];

  const vatString = interpolateString(template, { vat: vat?.toString() });
  const vatTitle = LOCALES_CONFIG[locale].vatTitle || defaultTitle;
  return `${vatTitle} ${vatString}`;
};

export const makeBillingAddressString = (
  locale: LOCALE_CODES,
  data:
    | ({ zip: string; city: string; state?: string } & Record<string, string>)
    | null
): string | null => {
  if (!data) return null;
  const { zip, state, city } = data;

  const _state = state ? state + ' ' : '';

  const template =
    LOCALES_CONFIG[locale]?.templates?.billingAddress ||
    DEFAULT_LOCALE_CONFIG.templates.billingAddress;

  return interpolateString(template, { zip, city, state: _state });
};

export const buildAlmLink = (locale: LOCALE_CODES, url) => {
  return `${ALMPages.ALM_URL}${locale}${url}`;
};

export const setupHtmlLang = (locale: LOCALE_CODES) => {
  const attr = getLanguageByLocale(locale);
  document.documentElement.setAttribute('lang', attr);
};

export const countryCurrencySymbolByLocale = (locale: LOCALE_CODES) => {
  const defaultAttr = 'gb';
  return (parseLocale(locale)[1] || defaultAttr).toLowerCase() as
    | StripeElementLocale
    | CheckoutLocale;
};

export function getLocaleFromUrl() {
  return window.location.pathname.slice(1, 6) as LOCALE_CODES; // get locale from url
}

export function getLanguageByLocale(locale: LOCALE_CODES) {
  return parseLocale(locale)[0];
}

export function parseLocale(locale: LOCALE_CODES) {
  return locale.split('-');
}

export function getFormattedLocale() {
  const locale = getLocaleFromUrl();
  const [lang, country] = parseLocale(locale);
  return `${lang}-${country?.toUpperCase()}`;
}

export function getLocaleDataFromUrl() {
  const locale = getLocaleFromUrl();
  const [lang, iso] = parseLocale(locale);
  return {
    lang,
    iso,
  };
}
// TODO figure out how to move it to the locale configuration
export function getIsDeLocale(locale?: LOCALE_CODES): boolean {
  let _locale = locale;

  if (!_locale) {
    _locale = getLocaleFromUrl() as LOCALE_CODES;
  }
  const deLocales: LOCALE_CODES[] = ['de-de', 'en-de', 'en-au'];
  return deLocales.includes(_locale);
}

export function getIsDeOrAu(code: string): boolean {
  const countryCode = ['DE', 'AU'];
  return countryCode.includes(code);
}
