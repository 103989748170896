import React, { FC, useEffect } from 'react';

import { ChangeBillingInfoForm } from '@pages/SubscriptionDetails/components/ChangeBillingInfoForm';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@global/components/ActiveForm/Elements/Checkbox';
import { UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useOldBillingInfoSelector } from '@global/store/slices/changeSubscriptionPayment/selector';
import { toggleUseOldBillingInfo } from '@global/store/slices/changeSubscriptionPayment/changeSubscriptionPayment';
import { useAppDispatch, useAppSelector } from '@global/store/hooks';
import { subscriptionBillingInfoSelector } from '@global/store/slices/subscription/selectors';

interface SubscriptionCardProps {
  handleClose: (value: boolean) => void;
  billingInfoFormMethods: UseFormReturn<any, any>;
  isShowCheckbox: boolean;
}

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  handleClose,
  billingInfoFormMethods,
  isShowCheckbox,
}) => {
  const { t } = useTranslation();
  const billingInfo = useAppSelector(subscriptionBillingInfoSelector);

  const dispatch = useAppDispatch();
  const useOldBillingInfo = useSelector(useOldBillingInfoSelector);

  useEffect(() => {
    if (useOldBillingInfo && billingInfo) {
      billingInfoFormMethods.setValue('country', billingInfo.country.iso2Code);
      billingInfoFormMethods.setValue('CCname', billingInfo.name);

      billingInfoFormMethods.setValue('address1', billingInfo.addressOne);
      billingInfoFormMethods.setValue('address2', billingInfo.addressTwo);

      billingInfoFormMethods.setValue('city', billingInfo.city);
      billingInfoFormMethods.setValue('zip', billingInfo.zip);
      billingInfoFormMethods.setValue('state', billingInfo.state);

      billingInfoFormMethods.setValue('phone', billingInfo.tel);
      billingInfoFormMethods.trigger();
    }
    if (!useOldBillingInfo) {
      billingInfoFormMethods.reset();
    }
  }, [useOldBillingInfo]);

  return (
    <>
      <div className="CardWrapper">
        <div id="payment-element"></div>
        <div id="captchaSection"></div>
      </div>
      <div>
        <div className="billing-info">
          <span className="billing-info__subtitle regularText">
            {t('checkout.step2.subTitle')}
          </span>
          {isShowCheckbox ? (
            <div className="subscription_details_checkbox">
              <Checkbox
                isHidden={false}
                isChecked={useOldBillingInfo}
                toggle={() => dispatch(toggleUseOldBillingInfo())}
                text={t('subDetails.sub.billing.billingEditCta.checkbox')}
              />
            </div>
          ) : null}
          <ChangeBillingInfoForm
            methods={billingInfoFormMethods}
            buttonId={'btn-payment-element-submit'}
            buttonText={t('subDetails.sub.billing.paymentEditCta.cta')}
            setIsShow={handleClose}
            isShow={true}
            billingInfo={useOldBillingInfo ? billingInfo : null}
          />
        </div>
      </div>
    </>
  );
};
