import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';

export enum LINK_TYPES {
  int = 'int',
  ext = 'ext',
  text = 'text',
  email = 'email',
  html = 'html',
}

interface IProps {
  item: {
    name: string;
    link?: string;
    element?: ReactElement;
    type: LINK_TYPES;
    withoutBold?: boolean;
  };
}

export const RoutingElement: React.FC<IProps> = ({
  item: { name, link, type, withoutBold, element },
}) => {
  const boldStyle = withoutBold ? { fontWeight: 400 } : {};

  const linkTypes = useMemo(
    () => ({
      [LINK_TYPES.text]: (
        <span style={boldStyle} className={'span'}>
          {name}
        </span>
      ),
      [LINK_TYPES.int]: (
        <Link style={boldStyle} to={link || ''}>
          {name}
        </Link>
      ),
      [LINK_TYPES.email]: (
        <a
          href={link}
          style={boldStyle}
          type="email"
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      ),
      [LINK_TYPES.ext]: (
        <a href={link} style={boldStyle} target="_blank" rel="noreferrer">
          {name}
        </a>
      ),
      [LINK_TYPES.html]: element,
    }),
    [name]
  );

  return linkTypes[type];
};
