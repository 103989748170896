import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import i18n, { t } from 'i18next';

import { AuthContext } from './context';
import { InitialAuthState } from '@global/hooks/useAuth/const';

import { useNotification } from '@global/hooks/useNotification';

// Services
import { login, registration } from '@global/hooks/useAuth/requests';
import {
  clearTokensFromCookies,
  getUserFormToken,
  setTokensToCookie,
} from './utils';
import { countryCurrencySymbolByLocale } from '@global/utils/lang';
import { prefixPath } from '@global/localization/services';

// Interfaces and constants
import * as Constants from '@global/App/constants/constants';
import { LOCALE_CODES, User } from '@global/types/types';
import {
  AuthResponseData,
  LoginParams,
  RegistrationParams,
} from '@global/hooks/useAuth/types';

type fromLocationState = {
  from?: {
    pathname: string;
  };
};

function useProvideAuth(): typeof InitialAuthState {
  const history = useHistory();
  const location = useLocation();
  const { setShowNotification } = useNotification();

  const [user, setUser] = useState<User>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const redirectAfterLogin = useCallback(() => {
    const allowURLs = [Constants.Pages.CART];
    const allowRoutes = allowURLs.map((url) => prefixPath(url, i18n.language));

    if (allowRoutes.includes(location.pathname)) return;

    const originState: fromLocationState = location?.state;
    const redirectTo =
      originState?.from?.pathname ||
      prefixPath(Constants.Pages.SUBSCRIPTION_DETAILS, i18n.language);

    history.push(redirectTo);
  }, [location]);

  const doLogout = useCallback(() => {
    clearTokensFromCookies();
    setUser(null);
  }, []);

  const updateAuthState = useCallback(async (params?: AuthResponseData) => {
    setIsLoading(true);
    if (params) {
      const { viaxToken, almToken } = params;
      setTokensToCookie(almToken, viaxToken);
    }

    const user = getUserFormToken(params?.viaxToken);
    if (user) {
      setUser(user);
    }

    setIsLoading(false);
    return Boolean(user);
  }, []);

  const doLogin = useCallback(
    async (formData: LoginParams, locale: LOCALE_CODES, useRedirect = true) => {
      setIsLoading(true);

      const loginData = await login({
        ...formData,
        country: countryCurrencySymbolByLocale(locale),
      });

      if (loginData.result === 'ERROR') {
        setIsLoading(false);
        setShowNotification(['error', t('notificationTexts.err.login')]);
        return false;
      }

      await updateAuthState(loginData.result);

      if (useRedirect) redirectAfterLogin();

      setIsLoading(false);
      return true;
    },
    [redirectAfterLogin, updateAuthState]
  );

  const doRegistration = useCallback(
    async (
      formData: RegistrationParams,
      locale: LOCALE_CODES,
      useRedirect = true
    ) => {
      setIsLoading(true);

      const registrationData = await registration(formData);

      if (registrationData.result === 'ERROR') {
        setIsLoading(false);
        setShowNotification([
          'error',
          `There was a problem with registration. ERROR: ${registrationData?.error}`,
        ]);
        return false;
      }

      await updateAuthState(registrationData.result);
      if (useRedirect) redirectAfterLogin();

      setIsLoading(false);
      return true;
    },
    [redirectAfterLogin, updateAuthState]
  );

  useEffect(() => {
    async function init() {
      const isLoggedIn = await updateAuthState();
      if (isLoggedIn) redirectAfterLogin();
    }

    init();
  }, []);

  return {
    user,
    isLoggedIn: Boolean(user),
    isLoading,
    doLogin,
    doRegistration,
    doLogout,
  };
}

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  const value = useProvideAuth();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
