import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WPS_PAYMENT_METHODS,
  PAYPAL_TEST_MODE_OPTION,
} from '@global/App/constants/constants';
import RadioButtonForPaymentMethod from '../PaymentMethodAggregator/radioButtonForPaymentMethod';
import PaymentMethod from '../PaymentMethods/PaymentMethod';
import { paymentInfo } from '@pages/checkout/types';
import { MappedPaymentComponentsType } from '@global/types/types';
import { Checkbox } from '../ActiveForm/Elements/Checkbox';

interface IProps {
  paymentMethod: string;
  setPaymentMethod: React.Dispatch<React.SetStateAction<string>>;
  setPayment: React.Dispatch<React.SetStateAction<any>>;
  paymentMethodsCount: number;
  paymentInitData: paymentInfo;
  paymentComponentMap: MappedPaymentComponentsType;
}

const PaymentOptions: React.FC<IProps> = ({
  paymentMethod,
  setPaymentMethod,
  setPayment,
  paymentMethodsCount,
  paymentInitData,
  paymentComponentMap,
}) => {
  const { t } = useTranslation();
  const onPaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const [isPaypalTestMode, setIsPaypalTestMode] = useState(false);
  const togglePaypalTestMode = () => {
    setIsPaypalTestMode((prev) => !prev);
  };

  return (
    <>
      <div className="payment-methods-desktop">
        <div className="order-summary__promotion select-wrapper">
          {paymentMethodsCount > 1 && (
            <h4 className="order-summary__promotion__title payment-heading">
              {t('checkout.step2.paymentMethod')}
            </h4>
          )}
          {PAYPAL_TEST_MODE_OPTION && (
            <Checkbox
              isHidden={true}
              isChecked={isPaypalTestMode}
              text={`paypal test mode`}
              toggle={togglePaypalTestMode}
            />
          )}
          {Object.entries(WPS_PAYMENT_METHODS).map(([key]) => (
            <React.Fragment key={`${key}`}>
              {paymentMethodsCount > 1 && (
                <RadioButtonForPaymentMethod
                  selectedPaymentMethod={paymentMethod}
                  paymentMethod={key}
                  onPaymentMethodChange={onPaymentMethodChange}
                />
              )}
              <PaymentMethod
                paymentMethod={key}
                setPayment={setPayment}
                selectedPaymentMethod={paymentMethod}
                paymentInitData={paymentInitData}
                paymentComponentMap={paymentComponentMap}
                isPaypalTestMode={isPaypalTestMode}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default PaymentOptions;
