import React from 'react';
import { Pages } from '@global/App/constants/constants';

export class ErrorBoundary extends React.Component<
  unknown,
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log({ error, from: 'getDerivedStateFromError' });
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      location.replace(location.pathname.slice(0, 6) + Pages.ERR404);
      // You can render any custom fallback UI
    }

    return this.props.children;
  }
}
