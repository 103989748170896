export const regExpMail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regExpName = /^[a-zA-Z-äöüÄÖÜß0-9 ']+$/;

export const regLetter = '(?=(.*[a-z]))';
export const regCapital = '(?=(.*[A-Z]))';
export const regSpecial = '(?=(.*[!@#$%^&]))';
export const regDigit = '(?=(.*[0-9]))';
export const regPass = new RegExp(
  `${regLetter}${regSpecial}${regDigit}|${regLetter}${regCapital}${regDigit}|${regLetter}${regCapital}${regSpecial}|${regCapital}${regSpecial}${regDigit}`
);
