import { FC, HTMLAttributeAnchorTarget } from 'react';
import { LOCALES_CONFIG } from '@global/App/constants/location/constants';

declare global {
  interface Window {
    digitalData: any;
    adobeDataLayer: any;
    Osano: any;
  }
}

export enum SUBSCRIPTION_STATUSES {
  None = 'None',
  Created = 'Created',
  Placed = 'Placed',
  Paid = 'Paid',
  Fulfilled = 'Fulfilled',
  Active = 'Active',
  Cancelling = 'Cancelling',
}

export type EdgesType<CODE = string> = {
  mapsOn: {
    code: CODE;
  };
  node: {
    amount?: number;
    paId?: string;
    units?: {
      code: string;
    };
  };
};

export type NavEntityType = {
  to: string;
  isInAppNav?: boolean;
  label: string;
  target?: HTMLAttributeAnchorTarget;
};

// interfaces
export interface IBillingInfo {
  name: string;
  tel: string;
  addressOne: string;
  addressTwo: string;
  addressThree?: string;
  city: string;
  state: string;
  zip: string;
  country: {
    name: string;
    numericCode: string;
    iso2Code?: string;
  };
  uid?: string;
}

export type PaymentMethodType = 'Card' | 'PayPal';

export type PaymentInfo = {
  nameOnCard: string;
  cardNumber: string | number;
  expirationDate: string | number;
  wPaymentType?: PaymentMethodType;
};

export interface IProduct {
  description?: string;
  imageUrl: string;
  maName: string;
  pricPrice: { edges: EdgesType[] };
  maId: string;
  uid: string;
  wBookTotalPages?: number;
  wIsbn?: string;

  saOwnershipModels: SaOwnershipModel[];
}

interface SaOwnershipModel {
  uid: string;
  omId: string;
  __typename: string;

  somBillingPlans?: SomBillingPlan[];
}

interface SomBillingPlan {
  uid: string;
  __typename: string;
  bipIsTemplate: boolean;
  bipBillAtRPIT: {
    name: string;
    __typename: string;
  };
}

export interface hiConsistsOfCustomerOrderBiId {
  pricPrice: { edges: EdgesType[] };
  biiSalable: IProduct;
  biSequentialId: string;
  subsActivationDate?: string;
  __typename: string;
}

export interface IPaybPayment {
  ccpaCardNumber: null | number | string;
  ccpaExpirationDate: null | number | string;
  ccpaNameOnCard: null | string;
  wPaymentType: PaymentMethodType;
}

export interface IGadCountry {
  name?: string;
  uid?: string;
  numericCode?: string;
  iso2Code?: string;
}

export enum TypesName {
  ViaxTrialPromotion = 'ViaxTrialPromotion',
}

export interface ICorPromoCodes {
  id?: string;
  uid?: string;
  prmName?: string;
  typeName?: TypesName;
  prmId?: string;
  trpDuration?: ITRPDuration;
}

export interface ITRPDuration {
  amount?: string;
  units?: string;
}

export interface IPaybBillingAddress {
  id: string;
  uid: string;
  typeName: string;
  gadCountry: IGadCountry;
  gadStateOrProvince: string;
  upaLine1: string;
  upaLine2: string;
  upaPostcode: string;
  upaCity: string;
}

export interface IOrder {
  biCreatedAt: string;
  uid: string;
  biId?: string;
  hiConsistsOf: hiConsistsOfCustomerOrderBiId[];
  id: string;
  corPromoCodes?: ICorPromoCodes[];
  paybBillingAddress?: null | IPaybBillingAddress;
  paybPayment?: IPaybPayment;
  pricPrice: {
    edges: EdgesType[];
  };

  prRelatedParties?: {
    edges: EdgesType[];
  };
  wContactPhone?: null | string;
  wMailingListSubscriptionAccepted?: null | boolean;
  wTOSAccepted?: null | boolean;
}

export interface ITax {
  pricPrice: {
    edges: EdgesType[];
  };
  paybBillingAddress?: null | IPaybBillingAddress;
  hiConsistsOf: hiConsistsOfCustomerOrderBiId[];
}

export type LOCALE_CODES = keyof typeof LOCALES_CONFIG;

export interface Region {
  id: number;
  name: string;
  locale: string;
  status: string;
  isoCodeLong?: string;
  isoCodeShort?: string;
}

export interface Country {
  id: number;
  name: string;
  locale: string;
  region: string;
  currency: string;
  fallbackCountry: string;
  isoCodeTwo: string;
  isoCodeThree: string;
  numCode: number;
  status: string;

  regions: Region[] | null;
}

export interface User {
  acr: string;
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  jti: string;
  name: string;
  preferred_username: string;
  realm: string;
  realm_access: {
    roles: string[];
  };
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
  uid: string;
}

export interface IPayment {
  paymentType: string;
  payment: {
    token: string;
    transactionId: string;
    returnCode: string;
    returnMessage: string;
    timestamp: string;
    currency: string;
  };
}

export type MappedPaymentComponentsType = Record<PaymentMethodType, FC>;
