import { gql } from '@apollo/client';

export interface GET_PARTY_TYPE {
  getParty: {
    uid: string;
  };
}

export const GET_PARTY = gql`
  query getParty($paId: String!) {
    getParty(paId: $paId) {
      paId
      uid
    }
  }
`;
