import { WPS_PAYMENT_METHODS } from '@global/App/constants/constants';
import { IBillingInfo, PaymentMethodType } from '@global/types/types';

const getPaymentMethods = (paymentInitData) => {
  return Object.keys(paymentInitData).filter((key) =>
    Object.hasOwn(WPS_PAYMENT_METHODS, key)
  );
};

export const paymentMethodSelection = (paymentInitData) => {
  const paymentMethodKeys = getPaymentMethods(paymentInitData);
  return paymentMethodKeys.length > 1 ? '' : paymentMethodKeys[0];
};

export const getPaymentMethodsCount = (paymentInitData) => {
  return getPaymentMethods(paymentInitData).length;
};

export const makeBillingInfoForPaymentMethod = (
  billingInfo: IBillingInfo,
  paymentMethod: PaymentMethodType
) => {
  const mappedBillingInfoByPaymentMethods: Record<
    PaymentMethodType,
    IBillingInfo
  > = {
    Card: billingInfo,
    PayPal: null,
  };

  return mappedBillingInfoByPaymentMethods[paymentMethod];
};
