import React from 'react';

export const ErrorIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={'notification__icon'}
  >
    <path
      d="M33 17C33 13.8355 32.0616 10.7421 30.3035 8.11089C28.5454 5.4797 26.0466 3.42894 23.1229 2.21793C20.1993 1.00693 16.9823 0.690079 13.8786 1.30744C10.7749 1.92481 7.92394 3.44866 5.6863 5.6863C3.44866 7.92394 1.92481 10.7749 1.30744 13.8786C0.690079 16.9823 1.00693 20.1993 2.21793 23.1229C3.42894 26.0466 5.4797 28.5454 8.11089 30.3035C10.7421 32.0616 13.8355 33 17 33C21.2435 33 25.3131 31.3143 28.3137 28.3137C31.3143 25.3131 33 21.2435 33 17Z"
      stroke="#D85139"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16.522 9.50295L17 19.6679L17.478 9.50295C17.4808 9.43796 17.4704 9.37308 17.4474 9.31225C17.4243 9.25142 17.3891 9.19591 17.344 9.14913C17.2988 9.10234 17.2445 9.06525 17.1845 9.04012C17.1245 9.01499 17.06 9.00234 16.995 9.00295V9.00295C16.9307 9.00355 16.8673 9.01711 16.8084 9.04279C16.7495 9.06848 16.6964 9.10577 16.6522 9.15244C16.6081 9.19911 16.5738 9.2542 16.5514 9.31443C16.529 9.37465 16.519 9.43877 16.522 9.50295V9.50295Z"
      stroke="#D85139"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 26.3262C16.6703 26.3262 16.348 26.2284 16.0739 26.0453C15.7997 25.8621 15.5861 25.6017 15.4599 25.2971C15.3337 24.9925 15.3007 24.6573 15.365 24.334C15.4294 24.0106 15.5881 23.7136 15.8213 23.4805C16.0544 23.2473 16.3514 23.0885 16.6748 23.0242C16.9982 22.9599 17.3333 22.9929 17.6379 23.1191C17.9425 23.2453 18.2029 23.4589 18.3861 23.7331C18.5692 24.0072 18.667 24.3295 18.667 24.6592C18.667 25.1013 18.4914 25.5253 18.1788 25.8379C17.8661 26.1506 17.4421 26.3262 17 26.3262Z"
      fill="#D85139"
    />
  </svg>
);
