import { User } from '@global/types/types';

const ALM_KEY = 'ALM-token';
const VIAX_KEY = 'Viax-token';

export const clearTokensFromCookies = () => {
  document.cookie = `${ALM_KEY}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
  document.cookie = `${VIAX_KEY}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

export const getTokensFromCookies = () => ({
  almToken: getCookieValue(ALM_KEY),
  viaxToken: getCookieValue(VIAX_KEY),
});

export const getUserFormToken = (token?: string) =>
  customParseJwt<User>(token || getTokensFromCookies()?.viaxToken);

export const setTokensToCookie = (almToken?: string, viaxToken?: string) => {
  try {
    if (almToken) {
      const decodedAlmToken = customParseJwt(almToken);
      document.cookie = `${ALM_KEY}=${almToken}; expires=${getExpireDate(
        decodedAlmToken.exp
      )}; path=/`;
    }
    if (viaxToken) {
      const decodedViaxToken = customParseJwt<User>(viaxToken);
      document.cookie = `${VIAX_KEY}=${viaxToken}; expires=${getExpireDate(
        decodedViaxToken.exp
      )}; path=/`;
    }
  } catch (e) {
    console.log(e);
  }
};

function getCookieValue(name: string) {
  return (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  );
}

function getExpireDate(exp: number) {
  return new Date(exp * 1000).toUTCString();
}

function customParseJwt<T = { exp: number }>(token: string): undefined | T {
  if (token && token !== 'undefined') {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } else return undefined;
}
