import React from 'react';
import '@global/components/Notification/style.scss';
import { SuccessIcon, ErrorIcon } from './Icons/';

export enum NotificationTypes {
  success = 'success',
  error = 'error',
  info = 'info',
}

interface IProps {
  notification: {
    type: NotificationTypes;
    message: string;
    effectClass?: string;
  };
}

export const Notification: React.FC<IProps> = ({
  notification: { type, effectClass, message },
}) => {
  let icon = null;

  if (type === NotificationTypes.success) icon = <SuccessIcon />;
  if (type === NotificationTypes.error) icon = <ErrorIcon />;

  return (
    <div
      id={`activeNotification`}
      className={`notification ${type} ${effectClass}`}
    >
      <div className={`notification__body`}>
        {icon}
        {message}
      </div>
    </div>
  );
};

export default Notification;
