import React from 'react';

export const SuccessIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={'notification__icon'}
  >
    <path
      d="M33 17C33 13.8355 32.0616 10.7421 30.3035 8.11089C28.5454 5.4797 26.0466 3.42894 23.1229 2.21793C20.1993 1.00693 16.9823 0.690079 13.8786 1.30744C10.7749 1.92481 7.92394 3.44866 5.6863 5.6863C3.44866 7.92394 1.92481 10.7749 1.30744 13.8786C0.690079 16.9823 1.00693 20.1993 2.21793 23.1229C3.42894 26.0466 5.4797 28.5454 8.11089 30.3035C10.7421 32.0616 13.8355 33 17 33C21.2435 33 25.3131 31.3143 28.3137 28.3137C31.3143 25.3131 33 21.2435 33 17Z"
      stroke="#0ABE75"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M22.75 12.208L14.7 21.791L11.25 17.958"
      stroke="#0ABE75"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
