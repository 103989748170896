import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from '@global/types/types';
import paypalLogo from '@assets/images/paypal.png';
interface IProps {
  paymentInfo: PaymentInfo | null;
}

export const PayPalPayment: FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <div className="payment-details__item">
      <p className="payment-details__item payment-details__item_secondary-color">
        {t('confirmation.details.payment')}
      </p>
      <img
        className="all-images-paypal-payment"
        src={paypalLogo}
        alt={t('confirmation.details.paymentType')}
      />
    </div>
  );
};
