import React, { FC, useMemo } from 'react';
import { CheckmarkIcon } from '@global/components/UI Elements/Icons/CheckMark';
import { ITRPDuration, LOCALE_CODES, TypesName } from '@global/types/types';
import { useTranslation } from 'react-i18next';
import { dateFormatterShort } from '@global/utils/lang';

interface IProps {
  typeName: TypesName;
  trpDuration: ITRPDuration;
}

const infos: Record<TypesName, { title: string; items: string[] }> = {
  [TypesName.ViaxTrialPromotion]: {
    title: 'checkout.summary.promo.ViaxTrialPromotion.title',
    items: [
      'checkout.summary.promo.ViaxTrialPromotion.item1',
      'checkout.summary.promo.ViaxTrialPromotion.item2_1',
      'checkout.summary.promo.ViaxTrialPromotion.item2_2',
    ],
  },
};

export const PromotionBlock: FC<IProps> = ({ typeName, trpDuration }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const info = useMemo(() => infos[typeName], []);
  const duration = useMemo(() => {
    const today = new Date();
    const expDate = new Date();
    return dateFormatterShort(
      expDate.setDate(today.getDate() + parseInt(trpDuration?.amount)),
      language
    );
  }, [language]);

  return (
    <div className="order-summary__promotion">
      <h4 className="order-summary__promotion__title">{t(info.title)}</h4>
      <div className="order-summary__promotion__text-block">
        <span className="order-summary__promotion__icon">
          <CheckmarkIcon />
        </span>
        <p className="order-summary__promotion__text">{t(info.items[0])}</p>
      </div>
      <div className="order-summary__promotion__text-block">
        <span className="order-summary__promotion__icon">
          <CheckmarkIcon />
        </span>
        <p className="order-summary__promotion__text">
          {t(info.items[1])}&nbsp;
          {duration}&nbsp;
          {t(info.items[2])}
        </p>
      </div>
    </div>
  );
};
