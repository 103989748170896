import React, { FC, useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { configLanguage } from '@global/localization/i18n';
import { useAppDispatch } from '@global/store/hooks';

// Inputs
import { CountrySelectInput } from '@global/components/ActiveForm/Elements/SelectCountry';
import { ActiveInputText } from '@global/components/ActiveForm/Elements/ActiveInputText';
import { StateSelectInput } from '@global/components/ActiveForm/Elements/SelectState';
import { ActiveInputFormatted } from '@global/components/ActiveForm/Elements/ActiveInputFormatted';
import { Checkbox } from '@global/components/ActiveForm/Elements/Checkbox';
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

// Icons
import { ReactComponent as PlusIcon } from '@assets/images/plus_icon.svg';

import { IBillingInfo, LOCALE_CODES } from '@global/types/types';
import observeButtonMutation from '@global/utils/mutationObserver';
import { PAYMENT_ELEMENT_ID } from '@global/App/constants/constants';

import {
  setIsButtonloading,
  setIsClicked,
} from '@global/store/slices/subscription/subscriptionSlice';

interface BillingInfoFormProps {
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  billingInfo: IBillingInfo;

  disabledSubmit: boolean;
  isLoading: boolean;
  buttonId: string;
  buttonText: string;
  isCartButtonLoading?: boolean;
  isButtonLoading?: boolean;
  buttonCancelText?: string;
  buttonCancelAction?: () => void;
  setIsCartClicked?: React.Dispatch<React.SetStateAction<any>>;
  setIsCartButtonLoading?: React.Dispatch<React.SetStateAction<any>>;
  toggleAgreementCheck?: () => void;
  agreementText?: string;
  agreementCheck?: boolean;
}

export const BillingInfoForm: FC<BillingInfoFormProps> = ({
  onSubmit = () => undefined,

  toggleAgreementCheck,
  agreementText,
  agreementCheck,
  isCartButtonLoading,
  billingInfo,
  disabledSubmit,
  isLoading,
  buttonId,
  buttonText,
  isButtonLoading,
  buttonCancelText,
  buttonCancelAction,
  setIsCartClicked,
  setIsCartButtonLoading,
}) => {
  const dispatch = useAppDispatch();
  const [addressTwoVisibility, setAddressTwoVisibility] =
    useState<boolean>(false);
  const [wpsButtonDisableState, setWpsButtonDisableState] = useState(false);
  const [mounted, setMounted] = useState(false);

  const { t, i18n } = useTranslation();
  const locale = i18n.language as LOCALE_CODES;
  const { name, addressOne, addressTwo, zip, city, state, tel, country } =
    billingInfo || {};
  const submitWPSForm = useCallback(() => {
    setIsCartClicked && setIsCartClicked(true);
    setIsCartButtonLoading && setIsCartButtonLoading(true);
    dispatch(setIsClicked(true));
    dispatch(setIsButtonloading(true));
    document.getElementById(buttonId).click();
  }, [buttonId]);
  useEffect(() => {
    const buttonToMonitor = document.getElementById(buttonId);

    if (buttonToMonitor) {
      // Create a MutationObserver instance
      const observer = new MutationObserver(
        observeButtonMutation(
          buttonId,
          setWpsButtonDisableState,
          PAYMENT_ELEMENT_ID
        )
      );

      // Start observing the button for changes
      observer.observe(buttonToMonitor, { attributes: true });
    }
  }, [buttonId]);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
      dispatch(setIsButtonloading(false));
    };
  }, []);

  useEffect(() => {
    if (mounted) setMounted(false);
  }, [billingInfo]);

  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [mounted]);

  return (
    <form onSubmit={onSubmit}>
      {mounted && (
        <div className="billing-info__form">
          {/* COUNTRY */}
          <CountrySelectInput
            selectedCountry={{
              isoCodeTwo: country?.iso2Code,
            }}
            disabled={true}
            label={t('activeForm.billingCountryLabel')}
          />
          {/* NAME */}
          <div className="billing-info__input-field-wrapper">
            <ActiveInputText inputName={'CCname'} defaultValue={name} />
          </div>

          {/* ADDRESS */}
          <div className="billing-info__address-field-wrapper">
            <ActiveInputText
              inputName={'address1'}
              inputId="card_billingaddress_addressLine1"
              defaultValue={addressOne}
            />
            {addressTwoVisibility && (
              <ActiveInputText
                inputName={'address2'}
                inputId="card_billingaddress_addressLine2"
                defaultValue={addressTwo}
              />
            )}
          </div>

          {!addressTwoVisibility && (
            <button
              className="add-address-btn"
              type="button"
              onClick={() => setAddressTwoVisibility((prev) => !prev)}
            >
              <PlusIcon className="plus-icon" />
              {t('activeForm.address2Btn')}
            </button>
          )}

          {/* ZIP CODE */}
          <ActiveInputText
            inputName={'zip'}
            inputId="card_billingaddress_postCode"
            defaultValue={zip}
          />
          {/* CITY */}
          <div className="billing-info__input-field-wrapper">
            <ActiveInputText
              inputName={'city'}
              inputId="card_billingaddress_city"
              defaultValue={city}
            />
          </div>

          {/* STATE */}
          <StateSelectInput selectedState={state} />

          {/* PHONE NUMBER */}
          <div className="billing-info__input-field-wrapper">
            <ActiveInputFormatted
              inputName={'phone'}
              inputFormat={configLanguage[locale].phoneFormat}
              defaultValue={tel}
            />
          </div>

          {toggleAgreementCheck && agreementText && (
            <Checkbox
              isHidden={false}
              isChecked={agreementCheck}
              text={agreementText}
              toggle={toggleAgreementCheck}
            />
          )}
        </div>
      )}
      <div className="billing-info__buttons-wrapper">
        <ButtonCTA
          variant="primary"
          className="billing-info__submit-btn"
          type="button"
          onClick={submitWPSForm}
          disabled={[wpsButtonDisableState, disabledSubmit].some(
            (disableCheck) => disableCheck
          )}
        >
          {isLoading || isCartButtonLoading || isButtonLoading ? (
            <div className="spinner"></div>
          ) : (
            buttonText
          )}
        </ButtonCTA>
        {buttonCancelText && buttonCancelAction && (
          <ButtonCTA
            variant="secondary"
            className="small-btn"
            onClick={buttonCancelAction}
          >
            {buttonCancelText}
          </ButtonCTA>
        )}
        <ButtonCTA
          variant="primary"
          className="billing-info__submit-btn"
          type="submit"
          id={buttonId}
        >
          {isLoading || isCartButtonLoading || isButtonLoading ? (
            <div className="spinner"></div>
          ) : (
            buttonText
          )}
        </ButtonCTA>
      </div>
    </form>
  );
};
