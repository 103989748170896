import React, { FC } from 'react';
import { MappedPaymentComponentsType, PaymentInfo } from '@global/types/types';

import { CardPayment } from './CardPayment';
import { PayPalPayment } from './PayPalPayment';
interface IProps {
  paymentInfo: PaymentInfo | null;
}

const paymentComponentMap: MappedPaymentComponentsType = {
  Card: CardPayment,
  PayPal: PayPalPayment,
};

export const Payment: FC<IProps> = ({ paymentInfo }) => {
  const paymentType = paymentInfo?.wPaymentType || '';
  const PaymentComponent = paymentComponentMap[paymentType];
  return (
    <>{PaymentComponent && <PaymentComponent paymentInfo={paymentInfo} />}</>
  );
};
