import React, { FC, useState } from 'react';

// Components
import { BillingInformation } from '@pages/checkout/components';
import { useAppSelector } from '@src/global/store/hooks';
import { cartSelector } from '@global/store/slices/cart/selectors';
import { STATUSES } from '@src/pages/checkout/types';

import { UseFormReturn } from 'react-hook-form';
import { PAYMENT_ELEMENT_ID } from '@global/App/constants/constants';

interface CardProps {
  paymentInfoFormMethods?: UseFormReturn<any, any>;
  payment?: Record<string, unknown>;
  isPaymentMethodSelected?: boolean;
}

const Card: React.FC<CardProps> = ({
  paymentInfoFormMethods,
  payment,
  isPaymentMethodSelected,
}) => {
  const cartState = useAppSelector(cartSelector);

  useState<boolean>(false);
  const [checkoutValid, setCheckoutValid] = useState<boolean>(false);
  const [checkoutStep, setCheckoutStep] = useState<number>(1);

  return (
    <>
      <div className="CardWrapper">
        <div className="card-inner-content">
          <div id={PAYMENT_ELEMENT_ID}></div>
          <div id="captchaSection"></div>
        </div>
      </div>
      {isPaymentMethodSelected &&
      cartState.updateBillingInformation === STATUSES.FALSE ? (
        <div>
          <BillingInformation
            isLoading={cartState.isLoading}
            checkoutValid={checkoutValid}
            setCheckoutValid={setCheckoutValid}
            checkoutStep={checkoutStep}
            buttonId="btn-payment-element-submit"
            payment={payment}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Card;
