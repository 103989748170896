// Modules
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// CSS and assets
import './style.scss';
import './components/OrderReview/style.scss';
import { ReactComponent as LockIcon } from '@assets/images/lock_keyhole.svg';
import defaultImage from '@assets/images/product-default-img.png';

// Hooks
import { useAppDispatch, useAppSelector } from '@global/store/hooks';
import { useAuth } from '@global/hooks/useAuth';
import { useModal } from '@global/hooks/useModal';
import { useNotification } from '@global/hooks/useNotification';

// Components
import {
  AccountLogin,
  AccountCreate,
  SecurePayment,
  SubscriptionNotificationBody,
} from '@pages/checkout/components';
import { OrderSummary } from '@global/components/OrderSummary';
import { Spinner } from '@global/components/UI Elements/Spinner';

// Actions
import {
  setCartId,
  setSku,
  clearCartStates as _clearCartStates,
  setUpdateBillingInformation,
} from '@global/store/slices/cart/cartSlice';

import { cartSelector } from '@global/store/slices/cart/selectors';
import { clearBillingInformation } from '@global/store/slices/cart/extraReducers/clearBillingInformation';

// step 1
import { updateCartId } from '@global/store/slices/cart/extraReducers/updateCartId';
// step 2
import { getCartData } from '@global/store/slices/cart/extraReducers/getCartData';
import { linkCartWithUser } from '@global/store/slices/cart/extraReducers/linkCartWithUser';
// -----------------------------

// Utils
import { LocalStorage } from '@global/utils/LocalStorage';
import analytic from '@global/utils/AdobeAnalytics';
import { getSku } from '@global/store/slices/cart/utils';
import { prefixPath } from '@global/localization/services';

// Types
import { STATUSES } from '@pages/checkout/types';
import { AddToCartLayerType } from '@global/utils/AdobeAnalytics/types';

// Constants
import { NotificationTypes } from '@global/components/Notification';
import { LOCALE_CODES } from '@global/types/types';
import * as Constants from '@global/App/constants/constants';
import { getParty } from '@src/global/store/slices/subscription/extraReducers/getParty';
import { getSubscriptions } from '@src/global/store/slices/cart/extraReducers/getSubscriptions';
import {
  CHECKOUT_ACCOUNT_STEP,
  CHECKOUT_PAYMENT_AND_BILLING_STEP,
  CHECKOUT_REVIEW_STEP,
} from '@pages/checkout/consts';
import { countriesSelector } from '@global/store/slices/country/countrySelector';
import OrderReview from './components/OrderReview';

export const Checkout = () => {
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const language = i18n.language as LOCALE_CODES;

  const dispatch = useAppDispatch();

  const cartState = useAppSelector(cartSelector);
  const countries = useAppSelector(countriesSelector);

  const { user, isLoggedIn, doLogout } = useAuth();
  const { setShowNotification } = useNotification();

  const { handleOpen: handleOpenModal } = useModal({
    content: () => (
      <SubscriptionNotificationBody email={user.email} doLogout={doLogout} />
    ),
    blockScreen: true,
  });

  const imgUrl = cartState?.orderItems[0]?.imageUrl || defaultImage;
  const maName = cartState?.orderItems[0]?.maName;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Cart data
  const [checkoutValid, setCheckoutValid] = useState<boolean>(false);

  // View
  const [checkoutStep, setCheckoutStep] = useState<number>(1);
  const [loginFormVisibility, setLoginFormVisibility] = useState<boolean>(true);
  const [createAccountFormVisibility, setCreateAccountFormVisibility] =
    useState<boolean>(false);

  const redirectTo404 = useCallback(() => {
    const redirectPath = prefixPath(Constants.Pages.ERR404, language);
    push(redirectPath);
  }, []);

  const handleUserLogout = useCallback(async () => {
    dispatch(clearBillingInformation()).then(() => {
      doLogout();
      analytic.dataLayerForm({
        formName: 'checkout-form',
        formEvent: 'LogoutSuccess',
      });
      editStepHandler(CHECKOUT_ACCOUNT_STEP);
    });
    clearCartStates();
  }, []);

  const clearCartStates = useCallback(() => {
    dispatch(_clearCartStates());
  }, []);

  const editStepHandler = useCallback(
    (step: number) => {
      switch (step) {
        case CHECKOUT_ACCOUNT_STEP:
          setCheckoutStep(CHECKOUT_ACCOUNT_STEP);
          dispatch(setCartId(LocalStorage.cartId));
          cartState.updatedWithNewCustomer === STATUSES.FALSE &&
            dispatch(updateCartId({ language, redirectTo404 }));
          break;
        case CHECKOUT_PAYMENT_AND_BILLING_STEP:
          setCheckoutStep(CHECKOUT_PAYMENT_AND_BILLING_STEP);

          cartState.updateBillingInformation === STATUSES.FALSE &&
            dispatch(getCartData({ language }));
          break;
        case CHECKOUT_REVIEW_STEP:
          setCheckoutStep(CHECKOUT_REVIEW_STEP);
          break;
      }
    },
    [
      cartState.updateBillingInformation,
      cartState.updatedWithNewCustomer,
      cartState.orderItems,
      isLoggedIn,
      language,
    ]
  );
  useEffect(() => {
    if (user && !cartState.uid) {
      dispatch(getParty({ paId: user?.uid }));
    }
  }, [user]);

  useEffect(() => {
    if (cartState.isCheckSubscriptions !== STATUSES.TRUE) return;
    if (cartState.isSubscriptionExist && user !== null) {
      handleOpenModal();
    } else {
      editStepHandler(CHECKOUT_PAYMENT_AND_BILLING_STEP);
    }
  }, [cartState.isCheckSubscriptions]);

  // get Subscriptions before 2 step
  useEffect(() => {
    if (
      cartState.updatedWithNewCustomer === STATUSES.TRUE &&
      cartState.isCheckSubscriptions === STATUSES.FALSE
    ) {
      if (
        isLoggedIn &&
        !cartState.isGetPartyLoading &&
        cartState.uid !== null
      ) {
        const uIdVal = cartState?.uid;
        dispatch(getSubscriptions({ uId: uIdVal })); // redux example
      }
    }
  }, [cartState.updatedWithNewCustomer, cartState.isGetPartyLoading]);

  // attach User if logged after get Cart
  useEffect(() => {
    if (
      cartState.statusUserLinked === STATUSES.FALSE &&
      user &&
      cartState.prRelatedParties
    ) {
      dispatch(
        linkCartWithUser({
          paId: user?.uid,
          prRelatedParties: cartState.prRelatedParties,
        })
      );
    }
  }, [cartState.prRelatedParties, user]);

  useEffect(() => {
    if (!countries.length) return;

    if (
      cartState.istaxCalculated === STATUSES.TRUE &&
      cartState.statusUserLinked === STATUSES.TRUE
    ) {
      checkoutStep !== CHECKOUT_REVIEW_STEP &&
        editStepHandler(CHECKOUT_REVIEW_STEP);
    }
  }, [cartState.istaxCalculated, cartState.statusUserLinked, countries]);

  useEffect(() => {
    cartState.error &&
      setShowNotification([NotificationTypes.error, cartState.error]);
  }, [cartState.error]);

  useEffect(() => {
    cartState.sku &&
      analytic.dataLayerPageLoaded({
        page: {
          name: 'checkout',
          section: 'Checkout',
        },
        event: 'page loaded',
      });
  }, [cartState.sku]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const startCartFlow = () => {
    const sku = getSku();
    editStepHandler(CHECKOUT_ACCOUNT_STEP);
    dispatch(setSku(sku));
  };

  useEffect(() => {
    countries.length && startCartFlow();
  }, [countries]);

  const addToCartLayerProps: AddToCartLayerType = useMemo(
    () => ({
      product: cartState.orderItems?.[0],
      currency: cartState.orderItems?.[0]?.price.units,
    }),
    [cartState.orderItems?.length]
  );

  useEffect(() => {
    if (
      checkoutStep === CHECKOUT_ACCOUNT_STEP &&
      addToCartLayerProps?.product?.maId
    ) {
      analytic.dataLayerAddToCartTracker(addToCartLayerProps);
    }
  }, [addToCartLayerProps]);

  return (
    <>
      {(cartState.isShowSpinner ||
        cartState.isLoading ||
        !countries.length) && <Spinner size={'64px'} withOverlay />}
      <div className="order-summary-mobile">
        <OrderSummary
          orderItems={cartState.orderItems}
          checkoutStep={checkoutStep}
          agreementCheck={cartState.agreementCheck}
          orderPrice={cartState.orderPrice}
          isSummaryLoading={cartState.isLoading || !countries.length}
          promotions={cartState.promotions}
          checkoutValid={checkoutValid}
          addressDetails={cartState.addressDetails}
        />
      </div>

      <div className="checkout">
        <div className="checkout__container">
          <div className="checkout__steps">
            {/* CHECKOUT STEP 1 */}
            {checkoutStep === CHECKOUT_ACCOUNT_STEP ? (
              <span className="checkout__title">
                <span className="checkout__title-num">1.</span>
                {loginFormVisibility ? (
                  <span>{t('checkout.step1.title.login')}</span>
                ) : (
                  <span>{t('checkout.step1.title.register')}</span>
                )}
              </span>
            ) : (
              <span className="checkout__title--inactive">
                <span className="checkout__title-num">1.</span>
                <span>{t('checkout.step1.title.loggedin')}</span>
                <button
                  className="checkout__edit-btn"
                  onClick={handleUserLogout}
                >
                  {t('checkout.step1.editBtn')}
                </button>
              </span>
            )}

            {checkoutStep === CHECKOUT_ACCOUNT_STEP && !user && (
              <>
                {loginFormVisibility && (
                  <AccountLogin
                    props={{
                      setLoginFormVisibility,
                      setCreateAccountFormVisibility,
                    }}
                  />
                )}
                {createAccountFormVisibility && (
                  <AccountCreate
                    props={{
                      setLoginFormVisibility,
                      setCreateAccountFormVisibility,
                    }}
                  />
                )}
              </>
            )}

            {user && (
              <span className="checkout__current-user regularText">
                {t('checkout.step1.isLoggedInText1')}
                <strong className="text-bold">{user.email}</strong>{' '}
                {t('checkout.step1.isLoggedInText2')}
              </span>
            )}

            {checkoutStep === CHECKOUT_PAYMENT_AND_BILLING_STEP && (
              <div id={`securePayment`} className="checkout__user-info"></div>
            )}
            {/* CHECKOUT STEP 2 */}
            <span
              className={
                checkoutStep === CHECKOUT_PAYMENT_AND_BILLING_STEP
                  ? 'checkout__title'
                  : 'checkout__title--inactive'
              }
            >
              <span className="checkout__title-num">2.</span>
              <span>{t('checkout.step2.title')}</span>
              {checkoutStep === CHECKOUT_REVIEW_STEP && (
                <button
                  className="checkout__edit-btn"
                  onClick={() => {
                    dispatch(setUpdateBillingInformation(STATUSES.FALSE));
                    editStepHandler(CHECKOUT_PAYMENT_AND_BILLING_STEP);
                  }}
                >
                  {t('checkout.step2.editBtn')}
                </button>
              )}
            </span>
            {checkoutStep === CHECKOUT_PAYMENT_AND_BILLING_STEP &&
              cartState.statusUserLinked === STATUSES.TRUE && (
                <SecurePayment
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  handleOpenModal={handleOpenModal}
                />
              )}
            {/* CHECKOUT STEP 3 */}
            <span
              className={
                checkoutStep === CHECKOUT_REVIEW_STEP
                  ? 'checkout__title'
                  : 'checkout__title--inactive'
              }
            >
              <span className="checkout__title-num">3.</span>
              <span>{t('checkout.step3.title')}</span>
              <LockIcon
                className={
                  checkoutStep === CHECKOUT_REVIEW_STEP
                    ? 'checkout__lock-icon'
                    : 'checkout__lock-icon--inactive'
                }
              />
            </span>
            {checkoutStep === CHECKOUT_REVIEW_STEP &&
              cartState.statusUserLinked === STATUSES.TRUE && (
                <div className="order-review-step">
                  <div className="book-item__info-main-review-image">
                    <img
                      src={imgUrl as string | undefined}
                      alt="Wiley Book Cover"
                    />
                  </div>
                  <div className="order-review-mobile-view">
                    <span className="order-review-item__name">{maName}</span>

                    <span className="order-review-item__text">
                      <p>{t('checkout.review.subscription')}</p>
                    </span>
                  </div>
                  <div className="order-review-desktop">
                    <OrderReview
                      orderItems={cartState.orderItems}
                      checkoutStep={checkoutStep}
                      orderPrice={cartState.orderPrice}
                      isLoading={isLoading}
                      isSummaryLoading={
                        cartState.isLoading || !countries.length
                      }
                      promotions={cartState.promotions}
                      cartId={cartState.cartId}
                      setIsLoading={setIsLoading}
                      onSuccess={clearCartStates}
                      addressDetails={cartState.addressDetails}
                    />
                  </div>
                </div>
              )}
          </div>

          <div className="order-summary-desktop">
            <OrderSummary
              orderItems={cartState.orderItems}
              checkoutStep={checkoutStep}
              agreementCheck={cartState.agreementCheck}
              orderPrice={cartState.orderPrice}
              isSummaryLoading={cartState.isLoading || !countries.length}
              promotions={cartState.promotions}
              checkoutValid={checkoutValid}
              addressDetails={cartState.addressDetails}
            />
          </div>
        </div>
      </div>
    </>
  );
};
