import { gql } from '@apollo/client';
import { EdgesType, IGadCountry, IPaybPayment } from '@global/types/types';

interface _IPaybPayment extends IPaybPayment {
  uid: string;
  paymAmount: {
    edges: EdgesType[];
  };
}

export interface REQUEST_SUBSCRIPTION_TYPE {
  getSubscriptionContract: {
    uid: string;
    bpStatus: {
      code: string;
    };
    hiConsistsOf: {
      biiSalable: {
        maId: string;
        maName: string;
        maDescription: string;
        imageUrl: string;
      };
      biiQuantity: {
        amount: number;
      };
    }[];
    paybPayment: _IPaybPayment;
    wContactPhone?: null | string;
    paybBillingAddress?: {
      upaLine1: string;
      upaLine2: string;
      upaCity: string;
      gadStateOrProvince: string;
      gadCountry: IGadCountry;
      upaPostcode: string;
    };

    subsValidPeriod: {
      start: string;
      end: string;
    };
    paybNextBillDate: string;
  };
}

export const REQUEST_SUBSCRIPTION = gql`
  query getSubscriptionContractByUid($uid: UUID!) {
    getSubscriptionContract(uid: $uid) {
      uid
      bpStatus {
        code
      }

      # Product data
      hiConsistsOf {
        biiSalable {
          ... on ViaxConfigurableSalableProduct {
            maId
            maName
            maDescription
            imageUrl
          }
        }
        biiQuantity {
          amount
        }
      }

      paybPayment {
        uid
        ... on WPSCustomerPeriodicPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
          wPaymentType
        }
        paymAmount(
          _mapsOn: [{ code: "TOTAL" }, { code: "SUBTOTAL" }, { code: "TAX" }]
        ) {
          edges {
            mapsOn {
              code
            }
            node {
              amount
              units {
                code
              }
            }
          }
        }
      }

      wContactPhone

      paybBillingAddress {
        upaLine1
        upaLine2
        upaCity
        gadStateOrProvince
        gadCountry {
          name
          numericCode
          iso2Code
        }
        upaPostcode
      }

      # Subscription Began
      subsValidPeriod {
        start
        end
      }

      paybNextBillDate

      prRelatedParties(_mapsOn: { code: "SOLD_TO" }) {
        edges {
          node {
            ... on ViaxIndividual {
              paId
            }
          }
        }
      }
    }
  }
`;
