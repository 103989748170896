// Modules
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ButtonCheckout } from '@global/components/UI Elements/ButtonCheckout';

// Utils
import { buildTitleWithName } from '@global/utils/lang';
import { useAuth } from '@global/hooks/useAuth';
import { LOCALE_CODES } from '@global/types/types';

export const NotExist = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const { user } = useAuth();

  return (
    <div className="no-sub">
      <div className="no-sub__info-wrapper">
        <span className="no-sub__title">
          {buildTitleWithName(language, t('subDetails.noSub.title'))}
        </span>
        <span className="no-sub__message">
          {t('subDetails.noSub.message1')}{' '}
          <span className="no-sub__message--bold">{user?.email}</span>{' '}
          {t('subDetails.noSub.message2')}
        </span>
        <ButtonCheckout text={t('subDetails.noSub.cta')} forceActive />
      </div>
    </div>
  );
};
