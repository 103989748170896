export default function observeButtonMutation(
  buttonId: string,
  setWpsButtonDisableState: (isDisabled: boolean) => void,
  paymentElementId: string
): MutationCallback {
  // Define the callback function to handle mutations
  const handleMutation: MutationCallback = (mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        // Check if the 'disabled' attribute has changed
        if (mutation.attributeName === 'disabled') {
          const button = document.getElementById(buttonId);
          if (
            button &&
            document.getElementById(paymentElementId).childNodes.length > 0
          ) {
            const isDisabled = button.getAttribute('disabled') !== null;
            setWpsButtonDisableState(isDisabled);
          }
        }
      }
    }
  };

  return handleMutation;
}
