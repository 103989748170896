// Moodules
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import './style.scss';
import { LinkMailto } from '@global/components/UI Elements/LinkMailTo';
import analytic from '@global/utils/AdobeAnalytics';

export const Page404: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    analytic.dataLayerPageLoaded({
      page: {
        name: 'on-demand:chemistry',
        section: 'eChem errorpage',
      },
      event: 'page loaded',
      error: {
        code: '404',
        message: t('404.message'),
      },
    });
  }, []);

  return (
    <div className="page404">
      <span className="page404__message">{t('404.message')}</span>

      <div className="page404__help">
        <span>{t('404.details')}</span>
        <span>
          {t('404.helpText')}
          <LinkMailto
            mailto={'chemond@wiley.com'}
            label={'chemond@wiley.com'}
          />
          .
        </span>
      </div>
    </div>
  );
};
