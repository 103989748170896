import { createSlice } from '@reduxjs/toolkit';

import {
  IBillingInfo,
  IProduct,
  PaymentInfo,
  SUBSCRIPTION_STATUSES,
} from '@global/types/types';
import { OrderPrice, PeriodInfo } from '@pages/SubscriptionDetails/types';
import { SubscriptionPageType } from '@global/requests/subscriptionDetails/GET_SUBSCRIPTIONS';

import { initBillingInfo, initOrderPrice } from '@pages/checkout/consts';
import { initPeriodInfo } from '@pages/SubscriptionDetails/consts';
import { getSubscriptions } from '@global/store/slices/subscription/extraReducers/getSubscriptions';
import { getCustomerOrder } from '@global/store/slices/subscription/extraReducers/getCustomerOrder';
import { getLatestSubscription } from '@global/store/slices/subscription/extraReducers/getLatestSubscription';
import { updateBillingInformation } from '@global/store/slices/subscription/extraReducers/updateBillingInformation';
import { updatePaymentMethod } from '@global/store/slices/subscription/extraReducers/updatePaymantMethod';
import { cancelSubscriptionContract } from './extraReducers/cancelSubscriptionContract';
import { getProductByMaId } from '@global/store/slices/subscription/extraReducers/getProductByMaId';

export interface SubscriptionSliceState {
  orderId: string | null;
  isCancelled: boolean;
  orderStatus: SUBSCRIPTION_STATUSES;
  isLoading: boolean;
  updating: boolean;
  isButtonLoading: boolean;
  isClicked: boolean;
  uid?: string;
  paymentUid?: string;

  error?: string | null;
  notification: string | null;

  subscriptions: SubscriptionPageType[];

  billingInfo: IBillingInfo;
  paymentInfo: PaymentInfo | null;
  periodInfo: PeriodInfo | null;
  orderPrice: OrderPrice;
  product: { sku?: string };
  productContent?: IProduct;
}

const initialState: SubscriptionSliceState = {
  orderId: null,
  isCancelled: false,
  orderStatus: SUBSCRIPTION_STATUSES.None,
  isLoading: false,
  updating: false,
  isButtonLoading: false,
  isClicked: false,
  error: null,
  notification: null,

  subscriptions: [],

  billingInfo: initBillingInfo,
  paymentInfo: null,
  periodInfo: initPeriodInfo,
  orderPrice: initOrderPrice,
  product: { sku: undefined },
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    clearSubscriptions: (state) => {
      state.subscriptions = initialState.subscriptions;
    },
    setOrderId: (state, { payload }) => {
      state.orderId = payload;
    },
    setNotification: (state, { payload }) => {
      state.notification = payload;
    },
    setIsCancelled: (state, { payload }) => {
      state.isCancelled = payload;
    },
    setPaymentInfo: (state, { payload }) => {
      if (payload.paymentUid) state.paymentUid = payload.paymentUid;
      if (payload.paymentInfo) state.paymentInfo = payload.paymentInfo;
    },
    setBillingInfo: (state, { payload }) => {
      state.billingInfo = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setIsClicked: (state, { payload }) => {
      state.isClicked = payload;
    },
    setIsButtonloading: (state, { payload }) => {
      state.isButtonLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.subscriptions = payload;
      })
      .addCase(getSubscriptions.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })

      .addCase(getCustomerOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerOrder.fulfilled, (state, { payload }) => {
        state.orderStatus = payload;
        state.isLoading = false;
      })
      .addCase(getCustomerOrder.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })

      .addCase(getLatestSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLatestSubscription.fulfilled, (state, { payload }) => {
        const {
          uid,
          tel,
          orderStatus,
          product,
          orderPrice,
          paymentInfo,
          periodInfo,
          paymentUid,
          billingInfo,
          isCancelled,
        } = payload;

        state.isLoading = false;
        state.uid = uid;
        state.billingInfo.tel = tel;
        state.orderStatus = orderStatus;
        state.product = product;
        state.orderPrice = orderPrice;
        state.paymentInfo = paymentInfo;
        state.periodInfo = periodInfo;
        state.paymentUid = paymentUid;
        state.isCancelled = isCancelled;
        state.billingInfo = billingInfo;
      })
      .addCase(getLatestSubscription.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })

      .addCase(updateBillingInformation.pending, (state) => {
        state.error = null;
        state.updating = true;
        state.isButtonLoading = true;
        state.isClicked = true;
      })
      .addCase(updateBillingInformation.fulfilled, (state, { payload }) => {
        state.notification = payload;
        state.updating = false;
        state.isButtonLoading = false;
        state.isClicked = false;
      })
      .addCase(updateBillingInformation.rejected, (state, { payload }) => {
        state.error = payload;
        state.updating = false;
        state.isButtonLoading = false;
        state.isClicked = false;
      })

      .addCase(updatePaymentMethod.pending, (state) => {
        state.error = null;
        state.updating = true;
        state.isButtonLoading = true;
      })
      .addCase(updatePaymentMethod.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.notification = payload;
        state.isButtonLoading = false;
      })
      .addCase(updatePaymentMethod.rejected, (state, { payload }) => {
        state.error = payload;
        state.updating = false;
        state.isButtonLoading = false;
      })

      .addCase(cancelSubscriptionContract.pending, (state) => {
        state.error = null;
        state.updating = true;
      })
      .addCase(cancelSubscriptionContract.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.notification = payload;
      })
      .addCase(cancelSubscriptionContract.rejected, (state, { payload }) => {
        state.error = payload;
        state.updating = false;
      })

      .addCase(getProductByMaId.fulfilled, (state, { payload }) => {
        state.productContent = payload;
      });
  },
});

export const {
  clearSubscriptions,
  setOrderId,
  setNotification,
  setIsCancelled,
  setPaymentInfo,
  setBillingInfo,
  setIsClicked,
  setIsButtonloading,
  setError,
} = subscriptionSlice.actions;

export const subscriptionReducer = subscriptionSlice.reducer;
