import { ELQForm } from '@global/App/constants/constants';

export const makePayload = (email) => {
  const data = {
    emailAddress: email,
    elqFormName: ELQForm.formName,
    elqSiteID: ELQForm.siteID,
  };

  const formBody = [];

  for (const property in data) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }

  return formBody.join('&');
};

export const signUpSend = (payload) => {
  const headerFormUrlEncoded = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  };

  const requestOptions = {
    method: 'POST',
    headers: headerFormUrlEncoded,
    body: payload,
  };

  return fetch(ELQForm.endpoint, requestOptions)
    .then((response) => {
      return response.text();
    })
    .then((text) => {
      return text.includes('DefaultFormSubmitConfirmation.aspx');
    })
    .catch((e) => {
      console.log('error', e);
      return e;
    });
};
