//Yup rules examples https://www.npmjs.com/package/yup#api

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const loginValidationSchema = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm.validationErr',
  });
  return yup.object({
    email: yup.string().trim().required(t('emailReq')).email(t('emailFormat')),
    password: yup
      .string()
      .required(t('passwordReq'))
      .min(10, t('passwordLength')),
  });
};
