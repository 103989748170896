// Modules
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// Components
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

// CSS and assets
import '@pages/checkout/components/AccountCreate/style.scss';

// Utils
import { CountrySelectInput } from '@global/components/ActiveForm/Elements/SelectCountry';
import { ActiveInputText } from '@global/components/ActiveForm/Elements/ActiveInputText';
import { accountCreationSchema } from '@pages/checkout/components/AccountCreate/rules';
import { yupResolver } from '@hookform/resolvers/yup';
import { PasswordRequirements } from '@global/components/ActiveForm/Elements/PasswordRequirements';
import { useAuth } from '@global/hooks/useAuth';
import { RegistrationParams } from '@global/hooks/useAuth/types';
import { NotificationTypes } from '@global/components/Notification';
import analytic from '@global/utils/AdobeAnalytics';
import { LOCALE_CODES } from '@global/types/types';
import { useNotification } from '@global/hooks/useNotification';
import { useSelector } from 'react-redux';
import { cartSelector } from '@global/store/slices/cart/selectors';
import { countriesSelector } from '@global/store/slices/country/countrySelector';
import { CountryService } from '@global/utils/CountryService';

interface IProps {
  props: {
    setLoginFormVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateAccountFormVisibility: React.Dispatch<
      React.SetStateAction<boolean>
    >;
  };
}

export const AccountCreate: React.FC<IProps> = ({
  props: { setLoginFormVisibility, setCreateAccountFormVisibility },
}) => {
  const { t } = useTranslation();
  const { locale } = useParams() as { locale: LOCALE_CODES };
  const { setShowNotification, showNotification } = useNotification();
  const cartState = useSelector(cartSelector);
  const countries = useSelector(countriesSelector);

  const resolverSchema = accountCreationSchema();
  const rulesResolver = yupResolver(resolverSchema);
  const { isLoading, doRegistration } = useAuth();

  const formLayerProps = {
    formName: 'createAccountForm',
    pageName: 'checkout',
  };
  // React Hook Form
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: rulesResolver,
  });

  const sectionToggleHandler = () => {
    setLoginFormVisibility(true);
    setCreateAccountFormVisibility(false);
  };

  const registrationHandler = methods.handleSubmit(async (data) => {
    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form submit',
    });

    const country = CountryService.getCountryByIsoCode(countries, data.country);

    const singUpValues = {
      ...data,
      country: {
        isoCodeTwo: country.isoCodeTwo,
        currency: country.currency,
      },
    } as RegistrationParams;

    const resultSignUp = await doRegistration(singUpValues, locale, false);

    if (resultSignUp) {
      analytic.dataLayerCheckoutTracker({
        pageData: {
          page: {
            name: 'cart',
            section: 'checkout',
            type: `checkout`,
          },
          event: `checkout account`,
        },
        ecommerceData: {
          orderItems: cartState.orderItems,
          currency: cartState.orderItems?.[0]?.price.units,
        },
        language: locale,
        isLoggedIn: true,
      });

      setShowNotification([
        NotificationTypes.success,
        'Your account was successfully created and you have been logged in.',
      ]);
    }
  });

  useEffect(() => {
    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form view',
    });
  }, []);

  useEffect(() => {
    methods.formState.isDirty &&
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form start',
      });
  }, [methods.formState.isDirty]);

  useEffect(() => {
    showNotification &&
      showNotification[0] === NotificationTypes.error &&
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form error',
        errorMsg: showNotification[1],
      });
  }, [showNotification]);

  return (
    <div className="create-account">
      <button className="existing-login-btn" onClick={sectionToggleHandler}>
        {t('checkout.step1.register.toLogin')}
      </button>

      <span className="form-text">{t('activeForm.reqLabel')}</span>
      <FormProvider {...methods}>
        <form
          className="active-form create-account__form"
          id="loginForm"
          onSubmit={registrationHandler}
        >
          {/* FIRST NAME */}
          <ActiveInputText inputName={'firstName'} />

          {/* LAST NAME */}
          <ActiveInputText inputName={'lastName'} />

          {/* E-MAIL */}
          <ActiveInputText
            inputName={'email'}
            confirmationInputName={'confirmEmail'}
          />

          {/* E-MAIL CONFIRMATION */}
          <ActiveInputText inputName={'confirmEmail'} />

          {/* PASSWORD */}
          <ActiveInputText
            inputName={'password'}
            inputType={'password'}
            confirmationInputName={'confirmPassword'}
          />

          {/* PASSWORD CONFIRMATION */}
          <ActiveInputText
            inputName={'confirmPassword'}
            inputType={'password'}
          />

          <PasswordRequirements />

          {/* COUNTRY */}
          <CountrySelectInput disabled={true} />

          <div className="checkbox-wrapper">
            <input
              id="offers"
              type="checkbox"
              {...methods.register('offers')}
            />
            <label htmlFor="offers">
              {t('checkout.step1.register.offers')}
            </label>
          </div>

          <div className="submit-block">
            <ButtonCTA
              variant="primary"
              className="extra-class submit-btn billing-info__submit-btn"
              type="submit"
              id="continue-to-payment-btn"
              disabled={!methods.formState.isValid || isLoading}
            >
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                t('checkout.step1.register.cta')
              )}
            </ButtonCTA>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
