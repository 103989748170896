import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { RootState } from '@global/store/store';

import { CANCEL_SUBSCRIPTION_CONTRACT } from '@global/requests/subscriptionDetails';
import {
  setError,
  setIsCancelled,
  setNotification,
} from '@global/store/slices/subscription/subscriptionSlice';

export const cancelSubscriptionContract = createAsyncThunk<
  any,
  { language?: string; t?: (arg: string) => string },
  { rejectValue }
>(
  'subscription/cancelSubscriptionContract',
  async ({ t }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as RootState;
      await client.mutate({
        mutation: CANCEL_SUBSCRIPTION_CONTRACT,
        variables: {
          uid: state.subscription.subscriptions?.[0].uid,
        },
      });

      dispatch(setIsCancelled(true));
      dispatch(setNotification(t('notificationTexts.success.cancel')));
    } catch (err) {
      dispatch(setError(err.message));
      return rejectWithValue(t('notificationTexts.err.cancel'));
    }
  }
);
