import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import '@pages/checkout/components/SecurePayment/style.scss';
import { useAppDispatch } from '@src/global/store/hooks';
import { setUpdateBillingInformation } from '@src/global/store/slices/cart/cartSlice';
import { STATUSES } from '@src/pages/checkout/types';

interface IProps {
  payment: Record<string, unknown>;
  isPaymentMethodSelected: boolean;
}

const PayPal: React.FC<IProps> = ({ payment, isPaymentMethodSelected }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (payment && isPaymentMethodSelected) {
      dispatch(setUpdateBillingInformation(STATUSES.TRUE));
    }
  }, [payment, isPaymentMethodSelected]);

  return (
    <>
      <div className="paypal-section">
        <h5 className="paypa-header">
          {t('checkout.step2.paymentRedirectiontxt')}
        </h5>
        <div id="paypal-button"></div>
      </div>
    </>
  );
};

export default PayPal;
