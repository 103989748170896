import React, { useCallback, useState, useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { Modal } from './Modal';
import { BrowserRouter } from 'react-router-dom';
import { Providers } from '@global/App/Providers';

export type Content = React.ElementType<{ handleClose?: () => void }>;

interface IProps {
  content: Content;
  blockScreen?: boolean;
  customClass?: string;
}

export function useModal({
  content: Content,
  blockScreen: _blockScreen,
  customClass,
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [blockScreen, setBlockScreen] = useState(_blockScreen);

  const rootElement = useRef<HTMLElement | null>(
    document.querySelector('#modal-root')
  );
  // main callbacks
  const handleClose = useCallback(() => {
    !blockScreen && setIsOpen(false);
  }, [blockScreen]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen((prev) => {
      if (blockScreen) return prev;
      return !prev;
    });
  }, []);

  const handleBlockScreen = useCallback(() => {
    setBlockScreen(true);
  }, []);

  const handleUnblockScreen = useCallback(() => {
    setBlockScreen(false);
  }, []);

  const clearRoot = useCallback(() => {
    unmountComponentAtNode(rootElement.current);
    document?.body.classList.remove('noscroll');
  }, [rootElement]);

  const renderModal = useCallback(() => {
    const CustomModal = () => (
      <BrowserRouter>
        <Providers>
          <div className="overlay" onClick={handleClose}></div>
          <Modal
            onClose={handleClose}
            blockScreen={blockScreen}
            customClass={customClass}
            isModalVisible={isOpen}
          >
            <Content handleClose={handleClose} />
          </Modal>
        </Providers>
      </BrowserRouter>
    );

    render(<CustomModal />, rootElement.current);
  }, [Content, rootElement]);

  // use Effects
  useEffect(() => {
    isOpen ? renderModal() : clearRoot();
  }, [isOpen]);

  return {
    isOpen,
    handleClose,
    handleOpen,
    toggle,
    handleBlockScreen,
    handleUnblockScreen,
  };
}
