// Modules
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import './style.scss';

export const ButtonShow: React.FC = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'activeForm.passwordToggle',
  });
  const [showPassword, setShowPassword] = useState(false);

  const showInputPassword = (e) => {
    e.target.parentElement.querySelector('input').type = showPassword
      ? 'password'
      : 'text';
    setShowPassword(!showPassword);
  };

  return (
    <span className="show-password-btn" onClick={showInputPassword}>
      {showPassword ? t('hide') : t('show')}
    </span>
  );
};
