import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import {
  GET_PARTY_TYPE,
  GET_PARTY,
} from '@global/requests/subscriptionDetails';

export const getParty = createAsyncThunk<
  string,
  {
    paId: string;
  },
  { rejectValue: string }
>('subscription/getParty', async (paId, { rejectWithValue }) => {
  try {
    const getPartyResult = await client.query<GET_PARTY_TYPE>({
      query: GET_PARTY,
      variables: paId,
    });

    return getPartyResult?.data?.getParty?.uid;
  } catch (error) {
    return rejectWithValue('SERVER_ERROR');
  }
});
