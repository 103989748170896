import React, { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BillingInfoForm } from '@global/components/BillingInfoForm/BillingInfoForm';

import { IBillingInfo } from '@global/types/types';
import { useAppSelector } from '@src/global/store/hooks';
import { subscriptionSelector } from '@src/global/store/slices/subscription/selectors';

interface ChangeBillingInfoFormProps {
  isShow: boolean;
  setIsShow: (value: boolean) => void;
  buttonId: string;
  billingInfo: IBillingInfo;
  methods: UseFormReturn<any, any>;
  onSubmit?: (e?: BaseSyntheticEvent) => Promise<void>;
  buttonText: string;
}

export const ChangeBillingInfoForm: FC<ChangeBillingInfoFormProps> = ({
  isShow,
  setIsShow,
  buttonId,
  billingInfo,
  methods,
  onSubmit,
  buttonText,
}) => {
  const { isClicked, isButtonLoading } = useAppSelector(subscriptionSelector);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isShow && billingInfo) {
      methods.setValue('country', billingInfo.country?.iso2Code);
      methods.setValue('CCname', billingInfo.name);

      methods.setValue('address1', billingInfo.addressOne);
      methods.setValue('address2', billingInfo.addressTwo);

      methods.setValue('city', billingInfo.city);
      methods.setValue('zip', billingInfo.zip);
      methods.setValue('state', billingInfo.state);

      methods.setValue('phone', billingInfo.tel);
      methods.trigger();
    }
  }, [isShow, billingInfo]);

  useEffect(() => {
    setDisabled(!methods.formState.isValid || isClicked);
  }, [methods.formState.isValid, isClicked]);

  return (
    <div hidden={!isShow}>
      <FormProvider {...methods}>
        <BillingInfoForm
          onSubmit={onSubmit}
          buttonId={buttonId}
          buttonText={buttonText}
          isButtonLoading={isButtonLoading}
          buttonCancelText={t('subDetails.sub.billing.billingEditCta.cancel')}
          buttonCancelAction={() => setIsShow(false)}
          isLoading={false}
          disabledSubmit={disabled}
          billingInfo={billingInfo}
        />
      </FormProvider>
    </div>
  );
};
