import React from 'react';

import { useTranslation } from 'react-i18next';
import { buildTitleWithName } from '@global/utils/lang';
import { LOCALE_CODES } from '@global/types/types';

export const NoActive = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  return (
    <div className="no-sub">
      <div className="no-sub__info-wrapper">
        <span className="no-sub__title">
          {buildTitleWithName(language, t('subDetails.pendingSub.title'))}
        </span>
        <span className="no-sub__message">
          {t('subDetails.pendingSub.message')}
        </span>
      </div>
    </div>
  );
};
