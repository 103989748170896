import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { RootState } from '@global/store/store';

import { GET_ORDER_BY_ID } from '@global/requests/subscriptionDetails';
import { GET_ORDER_BY_ID_TYPE } from '@global/requests/subscriptionDetails/GET_ORDER_BY_ID';
import { SUBSCRIPTION_STATUSES } from '@global/types/types';

export const getCustomerOrder = createAsyncThunk<
  SUBSCRIPTION_STATUSES,
  undefined,
  { rejectValue: string }
>('subscription/getCustomerOrder', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;

    const result = await client.query<GET_ORDER_BY_ID_TYPE>({
      query: GET_ORDER_BY_ID,
      variables: {
        biId: state.subscription.orderId,
      },
    });

    return result.data?.getCustomerOrderByBiId?.bpStatus
      ?.code as SUBSCRIPTION_STATUSES;
  } catch (error) {
    console.log(error);
    return rejectWithValue('SERVER ERROR');
  }
});
