import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import { RootState } from '@global/store/store';

import {
  REQUEST_SUBSCRIPTION,
  REQUEST_SUBSCRIPTION_TYPE,
} from '@global/requests/subscriptionDetails';
import { setIsCancelled } from '@global/store/slices/subscription/subscriptionSlice';
import { dateFormatter } from '@global/utils/lang';
import { getOrderPrice } from '@global/utils/orderUtils';
import {
  IBillingInfo,
  LOCALE_CODES,
  PaymentInfo,
  SUBSCRIPTION_STATUSES,
} from '@global/types/types';
import { OrderPrice, PeriodInfo } from '@pages/SubscriptionDetails/types';

type IResult = {
  uid: string | null;
  tel: string;
  orderStatus: SUBSCRIPTION_STATUSES;
  product: { sku?: string };
  orderPrice: OrderPrice;
  periodInfo: PeriodInfo;
  paymentUid: string;
  paymentInfo: PaymentInfo;
  billingInfo: IBillingInfo;
  isCancelled: boolean;
};

export const getLatestSubscription = createAsyncThunk<
  IResult,
  { language: LOCALE_CODES; t?: (arg: string) => string },
  { rejectValue: string }
>(
  'subscription/getLatestSubscription',
  async ({ language, t }, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const latestUid = state.subscription.subscriptions[0]?.uid;

      const { data, errors } = await client.query<REQUEST_SUBSCRIPTION_TYPE>({
        query: REQUEST_SUBSCRIPTION,
        variables: { uid: latestUid },
        fetchPolicy: 'no-cache',
      });

      if (errors) {
        throw new Error(errors.join(';'));
      }

      const payment = data?.getSubscriptionContract?.paybPayment;
      const address = data?.getSubscriptionContract?.paybBillingAddress;
      const address2 = address?.upaLine2 === 'null' ? '' : address.upaLine2;
      const subsValidPeriod = data?.getSubscriptionContract?.subsValidPeriod;
      const paybNextBillDate = data?.getSubscriptionContract?.paybNextBillDate;

      if (
        data.getSubscriptionContract?.bpStatus?.code ===
        SUBSCRIPTION_STATUSES.Cancelling
      ) {
        dispatch(setIsCancelled(true));
      }

      const result: IResult = {
        uid: data?.getSubscriptionContract?.uid || null,
        tel: data?.getSubscriptionContract?.wContactPhone || '',
        orderStatus: data.getSubscriptionContract.bpStatus
          .code as SUBSCRIPTION_STATUSES,
        product: {
          sku: data?.getSubscriptionContract?.hiConsistsOf[0]?.biiSalable?.maId,
        },
        orderPrice: getOrderPrice(
          data.getSubscriptionContract.paybPayment?.paymAmount.edges
        ),
        periodInfo: {
          subscriptionBegan: dateFormatter(subsValidPeriod?.start, language),
          nextBillDate: dateFormatter(paybNextBillDate, language),
          subscriptionEndDate: dateFormatter(subsValidPeriod?.end, language),
        },
        paymentUid: payment?.uid,
        paymentInfo: {
          nameOnCard: payment?.ccpaNameOnCard,
          cardNumber: payment?.ccpaCardNumber,
          expirationDate: payment?.ccpaExpirationDate,
          wPaymentType: payment?.wPaymentType,
        },
        billingInfo: {
          name: payment?.ccpaNameOnCard || '',
          addressOne: address?.upaLine1 || '',
          addressTwo: address2,
          zip: address?.upaPostcode || '',
          city: address?.upaCity || '',
          state: address?.gadStateOrProvince || '',
          tel: data?.getSubscriptionContract?.wContactPhone || '',
          country: {
            name: address?.gadCountry.name || '',
            numericCode: address?.gadCountry.numericCode || '',
            iso2Code: address?.gadCountry.iso2Code || '',
          },
        },
        isCancelled:
          data.getSubscriptionContract?.bpStatus?.code ===
          SUBSCRIPTION_STATUSES.Cancelling,
      };

      return result;
    } catch (error) {
      console.error(error);
      return rejectWithValue(t('notificationTexts.err.subscription'));
    }
  }
);
