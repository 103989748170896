import { CART_ID_ATTRIBUTE } from '@global/App/constants/constants';

export class LocalStorage {
  static set cartId(value: string | undefined) {
    !value
      ? localStorage.removeItem(CART_ID_ATTRIBUTE)
      : localStorage.setItem(CART_ID_ATTRIBUTE, value);
  }

  static get cartId(): string | undefined {
    return localStorage.getItem(CART_ID_ATTRIBUTE);
  }

  static set orderId(value: string | undefined) {
    !value
      ? localStorage.removeItem('orderId')
      : localStorage.setItem('orderId', value);
  }

  static get orderId(): string | undefined {
    return localStorage.getItem('orderId');
  }
}
