import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// CSS and assets
import defaultImage from '@assets/images/product-default-img.png';

// Components
import { SubscriptionsBookItem } from '@global/components/SubscriptionsBookItem';

// GraphQL
import { PurchasedItemData } from '@pages/orderConfirmation/types';
import { useAppDispatch, useAppSelector } from '@global/store/hooks';
import { getProductByMaId } from '@global/store/slices/subscription/extraReducers/getProductByMaId';
import { productContentSelector } from '@global/store/slices/subscription/selectors';

interface Props {
  sku: string;
  isActive?: boolean;
  isCancelled?: boolean;
  totalPrice: string;
  periodInfo?: {
    subscriptionBegan: string;
    nextBillDate: string;
    subscriptionEndDate: string;
  };
}

export const SubscriptionProduct: React.FC<Props> = ({
  sku,
  isActive,
  isCancelled,
  periodInfo,
  totalPrice,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const productContents = useAppSelector(productContentSelector);

  const topLeft = useMemo(
    () =>
      isCancelled
        ? ''
        : isActive
        ? t('subDetails.sub.details.nextBill')
        : t('subDetails.sub.details.endDate'),
    [isCancelled, isActive, t]
  );

  const bottLeft = useMemo(
    () =>
      isCancelled
        ? ''
        : isActive
        ? periodInfo.nextBillDate
        : periodInfo.subscriptionEndDate,
    [isCancelled, isActive, periodInfo]
  );

  const itemData: PurchasedItemData = useMemo(
    () => ({
      imgUrl: productContents?.imageUrl || defaultImage,
      navEntity: {
        to: 'active-subscriptions',
        isInAppNav: true,
        label: productContents?.maName,
      },
      bottomContent: {
        top: {
          left: topLeft,
          right: isActive ? t('subDetails.sub.details.monthlyBill') : '',
        },
        bottom: {
          left: bottLeft,
          right: isActive ? totalPrice : '',
        },
      },
    }),
    [productContents, topLeft, bottLeft, isActive, totalPrice, t]
  );

  useEffect(() => {
    sku && dispatch(getProductByMaId(sku));
  }, [sku]);

  return <SubscriptionsBookItem itemEntity={itemData} active={isActive} />;
};
