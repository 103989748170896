import { SUBSCRIPTION_STATUSES } from '@global/types/types';

export const existSubscriptionStatus = [
  SUBSCRIPTION_STATUSES.Placed,
  SUBSCRIPTION_STATUSES.Paid,
];

export const makeSubscriptionStatusesString = (
  statuses: SUBSCRIPTION_STATUSES[]
): string => {
  return statuses.reduce((previousValue, status, index, arr) => {
    const separator = index === arr.length - 1 ? '' : ' or ';
    return previousValue + `bpStatus=${status + separator}`;
  }, '');
};
