import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '@global/Api';
import {
  NEW_CART,
  NEW_CART_TYPE,
  UPDATE_CART,
  UPDATE_CART_TYPE,
} from '@global/requests/checkout';

import {
  getOrderItems,
  getOrderPrice,
  OrderItemType,
  OrderPriceType,
} from '@global/utils/orderUtils';

import { LocalStorage } from '@global/utils/LocalStorage';
import { getPromoCode, getSku } from '@global/store/slices/cart/utils';

import {
  PRODUCT_BY_MAID,
  PRODUCT_BY_MAID_TYPE,
} from '@src/global/requests/subscriptionDetails';
import { RootState } from '@global/store/store';
import { ICorPromoCodes, LOCALE_CODES } from '@global/types/types';
import {
  GET_CUSTOMER_ORDER_BY_UID,
  GET_CUSTOMER_ORDER_BY_UID_TYPE,
} from '@global/requests/checkout/GET_CUSTOMER_ORDER_BY_UID';
import { DELETE_PRODUCTS_FROM_ORDER } from '@global/requests/checkout/DELETE_PRODUCTS_FROM_ORDER';

export const updateCartId = createAsyncThunk<
  {
    orderPrice: OrderPriceType;
    orderItems: OrderItemType[];
    corPromoCodes: ICorPromoCodes[];
    cartId: string;
  } | null,
  { language: LOCALE_CODES; redirectTo404: () => void },
  { rejectValue: string }
>(
  'cart/updateWithNewCustomer',
  async ({ language, redirectTo404 }, { rejectWithValue, getState }) => {
    try {
      const { cart, country } = getState() as RootState;

      const sku = getSku();
      if (!sku) return null;

      let requestResult;
      const promoCode = getPromoCode();
      const corPromoCodes = promoCode ? [{ prmId: promoCode }] : [];
      const { tmpPricingFallbackValue, tmpPricingCountryCode } = country;

      const { salableProductUid, paybBillingPlanUid } =
        await getProductDateByMaid(sku);

      const createCartVariables: CreateNewCartVariables = {
        corPromoCodes,
        uId: salableProductUid,
        tmpPricingCountryCode,
        tmpPricingFallbackValue,
      };

      const updatedCartVariables: UpdateCartVariables = {
        uid: cart.cartId,
        contents: {
          typeName: 'ViaxSubscriptionContractItem',
          biiSalable: {
            uid: salableProductUid,
          },
        },
        corPromoCodes,
      };

      if (paybBillingPlanUid) {
        createCartVariables.paybBillingPlanUid = paybBillingPlanUid;
        updatedCartVariables.contents.paybBillingPlan = {
          uid: paybBillingPlanUid,
        };
      }

      if (cart.cartId) {
        // get all prev products
        let order;
        await client
          .query<GET_CUSTOMER_ORDER_BY_UID_TYPE>({
            query: GET_CUSTOMER_ORDER_BY_UID,
            variables: {
              uid: cart.cartId,
            },
            fetchPolicy: 'no-cache',
          })
          .then((response) => (order = response.data.getViaxCustomerOrder))
          .catch((err) => console.error('[GET_CUSTOMER_ORDER_BY_ID]', err));

        if (!order) {
          // If the cartId is broken or something like that, a new one is creating
          requestResult = await createNewCart(createCartVariables);
        } else {
          // delete all prev products
          const requestsForDeleteOldProducts = order?.hiConsistsOf?.map(
            (item) =>
              client.mutate({
                mutation: DELETE_PRODUCTS_FROM_ORDER,
                variables: {
                  uid: item.uid,
                },
              })
          );

          await Promise.all(requestsForDeleteOldProducts).catch((err) =>
            console.error('[DELETE_PRODUCTS_FROM_ORDER]', err)
          );

          requestResult = await client.mutate<UPDATE_CART_TYPE>({
            mutation: UPDATE_CART,
            variables: updatedCartVariables,
            fetchPolicy: 'no-cache',
          });
        }
      } else {
        requestResult = await createNewCart(createCartVariables);
      }

      const data = requestResult?.data?.updateViaxCustomerOrder
        ? requestResult?.data?.updateViaxCustomerOrder
        : requestResult?.data?.createViaxCustomerOrder;

      if (!data) {
        throw new Error(requestResult.error.message);
      }

      LocalStorage.cartId = data.uid;

      const payload = {
        cartId: data.uid,
        corPromoCodes: data.corPromoCodes,
        orderPrice: getOrderPrice(data.pricPrice?.edges),
        orderItems: getOrderItems(data.hiConsistsOf, language),
      };

      return payload;
    } catch (err) {
      console.log(err);
      // redirectTo404();
      return rejectWithValue('SERVER ERROR');
    }
  }
);

interface CreateNewCartVariables {
  corPromoCodes: { prmId: string }[];
  uId: string;
  paybBillingPlanUid?: string;
  tmpPricingCountryCode: string;
  tmpPricingFallbackValue: string;
}

interface UpdateCartVariables {
  uid: string;
  contents: {
    typeName: string;
    biiSalable: {
      uid: string;
    };
    paybBillingPlan?: {
      uid: string;
    };
  };
  corPromoCodes: { prmId: string }[];
}

// Helpers
async function createNewCart(variables: CreateNewCartVariables) {
  try {
    const newCart = await client.mutate<NEW_CART_TYPE>({
      mutation: NEW_CART,
      variables,
      fetchPolicy: 'no-cache',
    });

    return newCart;
  } catch (error) {
    console.error('[CREATE_CUSTOMER_ORDER]', error);
  }
}

async function getProductDateByMaid(
  sku: string
): Promise<{ salableProductUid: string; paybBillingPlanUid?: string }> {
  try {
    const MaidResult = await client.query<PRODUCT_BY_MAID_TYPE>({
      query: PRODUCT_BY_MAID,
      variables: {
        maId: sku,
      },
    });
    const product = MaidResult.data?.getViaxConfigurableSalableProduct;

    return {
      salableProductUid: product?.uid,
      paybBillingPlanUid:
        product?.saOwnershipModels?.[0]?.somBillingPlans?.[0]?.uid,
    };
  } catch (error) {
    console.error('[GET_PRODUCT_BY_MAID]', error);
  }
}
