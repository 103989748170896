// Modules
import React from 'react';

// CSS and assets
import './style.scss';

import { PurchasedItemData } from '@pages/orderConfirmation/types';

interface IProps {
  itemEntity: PurchasedItemData;
  active?: boolean;
}

export const SubscriptionsBookItem: React.FC<IProps> = ({
  itemEntity: { imgUrl, navEntity, bottomContent, biiId },
  active = true,
}) => {
  return (
    <div className="book-item" data-biiid={biiId}>
      <div className="book-item__info-main">
        <div className="book-item__info-main-image">
          <img src={imgUrl || 'Wiley Book Cover'} alt="Wiley Book Cover" />
        </div>

        <span className="book-item__title-mobile">{navEntity.label}</span>
      </div>

      <div className="book-item__content">
        <span className="book-item__title">{navEntity.label}</span>

        <div className="book-item__info-wrapper">
          <div className="book-item__date">
            <span>{bottomContent.top.left}</span>
            <span>{bottomContent.bottom.left}</span>
          </div>
          {active && (
            <div className="book-item__price">
              <span>{bottomContent.top.right}</span>
              <span>{bottomContent.bottom.right}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
