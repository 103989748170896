// Modules
import React, { useEffect, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// CSS and assets
import '@pages/login/style.scss';

// Components
import { useAuth } from '@global/hooks/useAuth';
import { useNotification } from '@global/hooks/useNotification';

import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';
import { NotificationTypes } from '@src/global/components/Notification';
import { ForgotPasswordLink } from '@global/components/ForgotPasswordLink';

import { ActiveInputText } from '@global/components/ActiveForm/Elements/ActiveInputText';

import { useYupValidationResolver } from '@global/components/ActiveForm/utils';
import { loginValidationSchema } from '@pages/checkout/components/AccountLogin/rules';

import analytic from '@global/utils/AdobeAnalytics';
import { LOCALE_CODES } from '@global/types/types';

type FormValues = {
  email: string;
  password: string;
};

const formLayerProps = {
  formName: 'loginPageForm',
  pageName: 'login',
};

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { doLogin, isLoading, isLoggedIn } = useAuth();
  const { locale } = useParams() as { locale: LOCALE_CODES };

  const [isPushedNotification, setIsPushedNotification] = useState(false);

  const { showNotification } = useNotification();

  //React Hook Form
  const resolverSchema = loginValidationSchema();
  const rulesResolver = useYupValidationResolver(resolverSchema);
  const methods = useForm({
    mode: 'all',
    resolver: rulesResolver,
  });

  const loginHandler = useCallback(
    methods.handleSubmit(async (formData: FormValues) => {
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'LoginAttempt',
        isLoggedIn,
      });

      if (await doLogin(formData, locale)) {
        analytic.dataLayerForm({
          ...formLayerProps,
          formEvent: 'form submit',
          isLoggedIn,
        });
        analytic.dataLayerForm({
          ...formLayerProps,
          formEvent: 'LoginSuccess',
          isLoggedIn,
        });
      }
    }),
    [isLoggedIn]
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form view',
      isLoggedIn,
    });

    analytic.dataLayerPageLoaded({
      page: {
        name: 'login',
        section: 'Login',
      },
      event: 'page loaded',
      isLoggedIn,
    });
  }, []);

  useEffect(() => {
    methods.formState.isDirty &&
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form start',
        isLoggedIn,
      });
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (showNotification && !isPushedNotification) {
      showNotification[0] === NotificationTypes.error &&
        analytic.dataLayerForm({
          ...formLayerProps,
          formEvent: 'form error',
          errorMsg: showNotification[1],
          isLoggedIn,
        });

      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'LoginAttemptFailure',
        errorMsg: `${showNotification[1]}`,
        isLoggedIn,
      });
      setIsPushedNotification(true);
    }

    if (!showNotification && isPushedNotification) {
      setIsPushedNotification(false);
    }
  }, [showNotification]);

  return (
    <main className="page-login">
      <div className="page-title underlinedBlock">
        <div className="inner-wrapper">
          <h1>{t('login.title')}</h1>
        </div>
      </div>
      <div className="page-body">
        <div className="inner-wrapper">
          {/*<div className="create-account">*/}
          {/*  <Link className="underlined-link" to={Pages.REGISTER}>*/}
          {/*    {t('login.toRegistration')}*/}
          {/*  </Link>*/}
          {/*</div>*/}
          <FormProvider {...methods}>
            <form className="login-account__form" onSubmit={loginHandler}>
              <div className="form-description">{t('activeForm.reqLabel')}</div>
              {/* E-MAIL */}
              <ActiveInputText inputName={'email'} />

              {/* PASSWORD */}
              <ActiveInputText inputName={'password'} inputType={'password'} />

              <ButtonCTA
                variant="primary"
                className="login-account__submit-btn"
                type="submit"
                disabled={!methods.formState.isValid || isLoading}
              >
                {isLoading ? (
                  <div className="spinner"></div>
                ) : (
                  t('checkout.step1.login.cta')
                )}
              </ButtonCTA>
              <ForgotPasswordLink />
            </form>
          </FormProvider>
        </div>
      </div>
    </main>
  );
};
