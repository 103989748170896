import React from 'react';
import { useTranslation } from 'react-i18next';

export const CookieLink = () => {
  const { t } = useTranslation();

  return (
    <a
      style={{ fontWeight: 400 }}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        window?.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
      }}
    >
      {t('footer.account.cookie')}
    </a>
  );
};
