import React, { FC } from 'react';
import { langExistsInPath } from '@global/localization/services';
import { langExistsInPathList } from '@global/localization/i18n';
import PageLayout from '@src/layouts/PageLayout';
import { Page404 } from '@pages/404';

interface IProps {
  path: string;
  children: React.ReactNode;
}

const RouteWrapper: FC<IProps> = ({ path, children }) => {
  return langExistsInPath(langExistsInPathList) ? (
    <PageLayout path={path}>{children}</PageLayout>
  ) : (
    <PageLayout path={path}>
      <Page404 />
    </PageLayout>
  );
};

export default React.memo(RouteWrapper);
