import { gql } from '@apollo/client';

export const UPDATE_BILLING_INFORMATION = gql`
  mutation updateViaxCustomerOrder($input: ViaxCustomerOrderInput!) {
    updateViaxCustomerOrder(input: $input) {
      id
      uid
      paybPayment {
        ... on WPSCustomerPeriodicPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
          wPaymentType
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
      pricPrice(_mapsOn: [{ code: "TAX" }, { code: "TOTAL" }]) {
        edges {
          mapsOn {
            code
          }
          node {
            amount
            units {
              code
            }
          }
        }
      }
      paybBillingAddress {
        uid
        id
        gadCountry {
          name
          uid
          numericCode
          iso2Code
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
      hiConsistsOf {
        # sorting key
        biiSequentialId
        # product total price
        pricPrice(_mapsOn: { code: "SUBTOTAL" }) {
          edges {
            mapsOn {
              code
            }
            node {
              amount
              units {
                code
              }
            }
          }
        }
        # product data
        biiSalable {
          ... on ViaxConfigurableSalableProduct {
            maId
            maName
            imageUrl
            pricPrice(_mapsOn: { code: "PRICE" }) {
              edges {
                mapsOn {
                  code
                }
                node {
                  amount
                  units {
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
