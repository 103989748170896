import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  orderId: string;
};

export const OrderNumber: FC<IProps> = ({ orderId }) => {
  const { t } = useTranslation();

  return (
    <div className="payment-details__column">
      <p className="payment-details__item payment-details__item_secondary-color">
        {t('confirmation.details.orderNumber')}
      </p>
      <p className="payment-details__item">{orderId}</p>
    </div>
  );
};
