// Modules
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

// CSS and assets
import '@pages/checkout/components/AccountLogin/style.scss';

// Components
import { ButtonCTA } from '@src/global/components/UI Elements/ButtonCTA';
import { ForgotPasswordLink } from '@global/components/ForgotPasswordLink';

import { loginValidationSchema } from '@pages/checkout/components/AccountLogin/rules';
import { ActiveInputText } from '@global/components/ActiveForm/Elements/ActiveInputText';
import { useYupValidationResolver } from '@global/components/ActiveForm/utils';
import { useAuth } from '@global/hooks/useAuth';
import { NotificationTypes } from '@global/components/Notification';
import analytic from '@global/utils/AdobeAnalytics';
import { LOCALE_CODES } from '@global/types/types';
import { useNotification } from '@global/hooks/useNotification';
import { useSelector } from 'react-redux';
import { cartSelector } from '@global/store/slices/cart/selectors';

type FormValues = {
  email: string;
  password: string;
};

interface IProps {
  props: {
    setLoginFormVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateAccountFormVisibility: React.Dispatch<
      React.SetStateAction<boolean>
    >;
  };
}

const formLayerProps = {
  formName: 'loginForm',
  pageName: 'checkout',
};

export const AccountLogin: React.FC<IProps> = ({
  props: { setLoginFormVisibility, setCreateAccountFormVisibility },
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const { locale } = useParams() as { locale: LOCALE_CODES };
  const { showNotification } = useNotification();
  const [isPushedNotification, setIsPushedNotification] = useState(false);
  const cartState = useSelector(cartSelector);

  const resolverSchema = loginValidationSchema();
  const rulesResolver = useYupValidationResolver(resolverSchema);
  const { doLogin, isLoading, isLoggedIn } = useAuth();

  //React Hook Form
  const methods = useForm({
    mode: 'all',
    resolver: rulesResolver,
  });

  const sectionToggleHandler = () => {
    setLoginFormVisibility(false);
    setCreateAccountFormVisibility(true);
  };

  const loginHandler = useCallback(
    methods.handleSubmit(async (formData: FormValues) => {
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form submit',
      });
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'LoginAttempt',
      });
      const isLogged = await doLogin(formData, locale, false);
      if (isLogged) {
        analytic.dataLayerForm({
          ...formLayerProps,
          formEvent: 'LoginSuccess',
        });
        analytic.dataLayerCheckoutTracker({
          pageData: {
            page: {
              name: 'cart',
              section: 'checkout',
              type: `checkout`,
            },
            event: `checkout account`,
          },
          ecommerceData: {
            orderItems: cartState.orderItems,
            currency: cartState.orderItems?.[0]?.price.units,
          },
          language,
          isLoggedIn: true,
        });
      }
    }),
    []
  );

  useEffect(() => {
    analytic.dataLayerForm({
      ...formLayerProps,
      formEvent: 'form view',
    });
  }, []);

  useEffect(() => {
    methods.formState.isDirty &&
      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'form start',
      });
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (showNotification && !isPushedNotification) {
      showNotification[0] === NotificationTypes.error &&
        analytic.dataLayerForm({
          ...formLayerProps,
          formEvent: 'form error',
          errorMsg: showNotification[1],
          isLoggedIn,
        });

      analytic.dataLayerForm({
        ...formLayerProps,
        formEvent: 'LoginAttemptFailure',
        errorMsg: `${showNotification[1]}`,
        isLoggedIn,
      });
      setIsPushedNotification(true);
    }

    if (!showNotification && isPushedNotification) {
      setIsPushedNotification(false);
    }
  }, [showNotification]);

  return (
    <div className="login-account">
      <button className="create-account-btn" onClick={sectionToggleHandler}>
        {t('checkout.step1.login.toRegistration')}
      </button>

      <span className="form-text">{t('activeForm.reqLabel')}</span>
      <FormProvider {...methods}>
        <form className="login-account__form" onSubmit={loginHandler}>
          {/* E-MAIL */}
          <ActiveInputText inputName={'email'} />

          {/* PASSWORD */}
          <ActiveInputText inputName={'password'} inputType={'password'} />

          <ButtonCTA
            variant="primary"
            className="login-account__submit-btn"
            type="submit"
            disabled={!methods.formState.isValid || isLoading}
          >
            {isLoading ? (
              <div className="spinner"></div>
            ) : (
              t('checkout.step1.login.cta')
            )}
          </ButtonCTA>

          <ForgotPasswordLink />
        </form>
      </FormProvider>
    </div>
  );
};
