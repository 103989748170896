export interface COUNTRY_CONFIG {
  VAT?: number | null;
  phoneRegExp?: RegExp;
  phoneFormat?: string;
  vatTitle?: string;
  stateDropdown?: boolean;
  templates?: Record<string, string>;
  links?: Record<string, string>;
}

export const USConfig: COUNTRY_CONFIG = {
  phoneRegExp: /\+[0-9]{1}[ ][0-9]{3}[ ][0-9]{3}[ ][0-9]{4}$/,
  vatTitle: 'TAX',
  phoneFormat: '+# ### ### ####',
  stateDropdown: true,
};

export const DEFAULT_LOCALE_CONFIG: COUNTRY_CONFIG & {
  language: 'en';
  locale: 'en-us';
} = {
  language: 'en',
  locale: 'en-us',
  VAT: 0,
  phoneRegExp: USConfig.phoneRegExp,
  phoneFormat: USConfig.phoneFormat,
  links: {
    subscribe: 'https://www.wiley.com/en-us/shop/on-demand',
  },
  templates: {
    titleWithName: '{name}{title}',
    billingAddress: '{city} {state}{zip}',
    vatStep1: ' ',
    vatStep2: ' ',
    vatStep3: ' ',
  },
};

export const UkConfig: COUNTRY_CONFIG = {
  phoneRegExp: /\+[0-9]{2}[ ][0-9]{2}[ ][0-9]{8}$/,
  phoneFormat: '+## ## ########',
  links: {
    subscribe: 'https://www.wiley.com/en-gb/shop/on-demand',
  },
  templates: {
    vatStep1: ' ',
    vatStep2: ' ',
    vatStep3: '(7%)',
  },
};

export const GermanConfig: COUNTRY_CONFIG = {
  VAT: 7,
  phoneRegExp: /\+[0-9]{2}[ ][0-9]{2}[ ][0-9]{6}$/,
  phoneFormat: '+## ## ######',
  links: {
    subscribe: 'https://www.wiley.com/de-de/shop/on-demand',
  },
  templates: {
    titleWithName: '{title}',
    billingAddress: '{zip} {state}{city}',
    vatStep1: ' ',
    vatStep2: ' ',
    vatStep3: '(7%)',
  },
};

export const AustraliaConfig: COUNTRY_CONFIG = {
  VAT: 10,
  vatTitle: 'TAX',
  phoneRegExp: /\+[0-9]{2}[ ][0-9]{1}[ ][0-9]{4}[ ][0-9]{4}$/,
  phoneFormat: '+## # #### ####',
  links: {
    subscribe: 'https://www.wiley.com/en-au/shop/on-demand',
  },
  templates: {
    ...GermanConfig.templates,
    vatStep3: '(10%)',
  },
};
