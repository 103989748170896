import { gql } from '@apollo/client';

export const REGISTRATION = gql`
  mutation wRegistration(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $country: String!
    $currency: String!
    $language: String!
    $optIn: Boolean!
  ) {
    wRegistration(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      country: $country
      currency: $currency
      language: $language
      optIn: $optIn
    )
  }
`;
