export const Pages = {
  ROOT: '/',
  CATEGORY: '/shop',
  CART: '/cart',
  LOGIN: '/login',
  // REGISTER: '/register',
  PDP1: '/pdp-mock',
  ORDER_CONFIRMATION: '/order-confirmation',
  CHECKOUT: '/cart',
  SUBSCRIPTION_DETAILS: '/subscriptions-details',
  ERR404: '/404',
};

export const FORGOT_PASSWORD_LINK =
  process.env.REACT_APP_MODE === 'production'
    ? process.env.REACT_APP_FORGOT_PASSWORD_LINK
    : process.env.REACT_APP_LOWER_ENV_FORGOT_PASSWORD_LINK;

export const ALMPages = {
  CHEMISTRY: process.env.REACT_APP_AEM_LINK_CHEMISTRY,
  WHY: process.env.REACT_APP_AEM_LINK_WHY,
  HOW_IT_WORKS: process.env.REACT_APP_AEM_LINK_HOW_IT_WORKS,
  ALM_URL: process.env.REACT_APP_AEM_URL,
};

export const ELQForm = {
  endpoint: process.env.REACT_APP_ELQ_FORM_ENDPOINT,
  formName: process.env.REACT_APP_ELQ_FORM_NAME,
  siteID: process.env.REACT_APP_ELQ_SITE_ID,
  formStart: process.env.REACT_APP_ELQ_FORM_START,
};

export const AWS_COUNTRIES_URL = process.env.REACT_APP_AWS_COUNTRIES_URL;
export const CART_ID_ATTRIBUTE = process.env.REACT_APP_CART_ID_ATTRIBUTE;

export const Keycloak = {
  REALM: process.env.REACT_APP_KC_REALM,
  CLIENT_ID: process.env.REACT_APP_KC_CLIENT_ID,
  AUTH_URL: process.env.REACT_APP_VIAX_AUTHENTICATION_DOMAIN,
  DEFAULT_IDP: process.env.REACT_APP_KC_DEFAULT_IDP,
};

export const GRAPHQL_URI = process.env.REACT_APP_VIAX_GRAPHQL_URI;

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const WPS_JS_PROVIDER = process.env.REACT_APP_WPS_JS_PROVIDER;
export const WPS_PAYPAL_PROVIDER = process.env.REACT_APP_WPS_JS_PROVIDER;
export const PAYPAL_ENABLED = process.env.REACT_APP_WPS_LOCAL_ENV;
export const PAYPAL_TEST_MODE_OPTION =
  process.env.REACT_APP_WPS_JS_PAYPAL_TEST_MODE_OPTION === 'true';
export const WPS_JS_CAPTCHA_RETRY_ATTEMPT =
  process.env.REACT_APP_WPS_JS_CAPTCHA_RETRY_ATTEMPT;
export const WPS_JS_CAPTCHA_TEST_MODE =
  process.env.REACT_APP_WPS_JS_CAPTCHA_TEST_MODE === 'true';

export const WPS_PAYMENT_METHODS = JSON.parse(
  process.env.REACT_APP_WPS_PAYMENT_METHODS
);

export const PAYMENT_ELEMENT_ID = 'payment-element';
