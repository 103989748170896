import { PaybBillingAddress, PaybBillingAddressView } from './types';

export function mapToView(
  paybBillingAddress: PaybBillingAddress = {
    upaLine1: '',
    upaLine2: '',
    upaCity: '',
    gadStateOrProvince: '',
    gadCountry: {
      name: '',
      numericCode: '',
      iso2Code: '',
    },
    upaPostcode: '',
  }
): PaybBillingAddressView {
  return {
    state: paybBillingAddress?.gadStateOrProvince || '',
    addressOne: paybBillingAddress?.upaLine1 || '',
    addressTwo: paybBillingAddress?.upaLine2 || '',
    city: paybBillingAddress?.upaCity || '',
    zip: paybBillingAddress?.upaPostcode || '',
    uid: paybBillingAddress?.uid || '',
    country: {
      name: paybBillingAddress?.gadCountry?.name,
      numericCode: paybBillingAddress?.gadCountry?.numericCode,
      iso2Code: paybBillingAddress?.gadCountry?.iso2Code,
    },
  };
}
