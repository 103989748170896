import React, { FC, useState } from 'react';
import './style.scss';

import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

interface IProps {
  action?: (arg?: unknown) => void;
  handleClose?: (arg?: unknown) => void;
  actionBtnText?: string;
  title?: string;
  backBtnText?: string;
  isLoading?: boolean;
}

export const Wrapper: FC<IProps> = ({
  action,
  handleClose,
  children,
  actionBtnText = '',
  title = '',
  backBtnText = '',
  isLoading = false,
}) => {
  const [_isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <>
      <span className="modal__title">{title}</span>
      <div className="modal__content">{children}</div>
      {actionBtnText && (
        <ButtonCTA
          variant="primary"
          className="modal__action-btn"
          onClick={async () => {
            setIsLoading(true);
            action && (await action());
            setIsLoading(false);
          }}
          tabIndex={1}
          disabled={isLoading}
        >
          {isLoading || _isLoading ? (
            <div className="spinner"></div>
          ) : (
            actionBtnText
          )}
        </ButtonCTA>
      )}
      {backBtnText && (
        <span style={{ textAlign: 'center' }}>
          <button
            className="modal__back-btn"
            onClick={handleClose}
            tabIndex={2}
          >
            {backBtnText}
          </button>
        </span>
      )}
    </>
  );
};
