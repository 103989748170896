import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@global/store/hooks';
import { countriesSelector } from '@global/store/slices/country/countrySelector';
import { getCountries } from '@global/store/slices/country/extraReducers/getCountries';
import { getLocaleDataFromUrl } from '@global/utils/lang';
import { CountryService } from '@global/utils/CountryService';

interface IProps {
  selectedCountry?: { isoCodeTwo: string };
  disabled?: boolean;
  label?: string;
}

export const CountrySelectInput: React.FC<IProps> = ({
  selectedCountry,
  disabled,
  label,
}) => {
  const methods = useFormContext();
  const { t } = useTranslation();
  const countries = useAppSelector(countriesSelector);
  const dispatch = useAppDispatch();

  const { iso } = useMemo(() => getLocaleDataFromUrl(), []);
  const defaultCountry = useMemo(
    () =>
      selectedCountry?.isoCodeTwo
        ? selectedCountry
        : CountryService.getCountryByIsoCode(countries, iso),
    [countries, iso, selectedCountry]
  );

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  return (
    <div
      className={
        'active-validation-field' +
        ' country-select-field' +
        (methods.formState.errors.country ? ' validation-error' : '') +
        (defaultCountry?.isoCodeTwo ? ' validation-success' : '')
      }
    >
      <label htmlFor="card_billingaddress_country">
        {label ?? t('activeForm.countryLabel')}
      </label>
      <div className="select-wrapper">
        <fieldset disabled={disabled}>
          <select
            id="card_billingaddress_country"
            defaultValue={defaultCountry.isoCodeTwo}
            {...methods.register('country')}
          >
            <option value="-1" disabled hidden>
              {t('activeForm.countrySelect')}
            </option>
            {countries?.map((country) => (
              <option key={country.isoCodeTwo} value={country.isoCodeTwo}>
                {country.name}
              </option>
            ))}
          </select>
        </fieldset>

        {methods.formState.errors.country && (
          <span className="error">
            {methods.formState.errors.country.message}
          </span>
        )}
      </div>
    </div>
  );
};
