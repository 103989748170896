import { IBillingInfo } from '@global/types/types';
import { OrderPriceType } from '@global/utils/orderUtils';

export const initBillingInfo: IBillingInfo = {
  name: '',
  tel: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  country: {
    name: '',
    numericCode: '',
    iso2Code: '',
  },
};

export const initOrderPrice: OrderPriceType = {
  total: { value: 0, code: '' },
  tax: { value: 0, code: '' },
  price: { value: 0, code: '' },
};

export const initBillingAddress = {
  id: '',
  uid: '',
  typeName: '',
  gadCountry: {
    name: '',
    uid: '',
    numericCode: '',
    iso2Code: '',
  },
  gadStateOrProvince: '',
  upaLine1: '',
  upaLine2: '',
  upaPostcode: '',
  upaCity: '',
};

export const CHECKOUT_ACCOUNT_STEP = 1;
export const CHECKOUT_PAYMENT_AND_BILLING_STEP = 2;
export const CHECKOUT_REVIEW_STEP = 3;
