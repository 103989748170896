import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { Provider } from 'react-redux';
import { AuthProvider } from '@global/hooks/useAuth';
import { store } from '@global/store/store';

import { NotificationProvider } from '@global/hooks/useNotification';

// Api
import { client } from '@global/Api';
import { ApolloProvider } from '@apollo/client';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from '@global/App/constants/constants';
import { countryCurrencySymbolByLocale } from '@global/utils/lang';
import { LOCALE_CODES } from '@global/types/types';

export const Providers: FC = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);

  const { i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  useEffect(() => {
    const stripePromise = loadStripe(STRIPE_KEY, {
      locale: countryCurrencySymbolByLocale(language),
    });

    setStripePromise(stripePromise);
  }, []);

  return (
    <Provider store={store}>
      <NotificationProvider>
        <AuthProvider>
          <ApolloProvider client={client}>
            <Elements stripe={stripePromise}>{children}</Elements>
          </ApolloProvider>
        </AuthProvider>
      </NotificationProvider>
    </Provider>
  );
};
