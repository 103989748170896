import React, { useEffect, useState } from 'react';
import './style.scss';

import WpsPayment from '../WpsPayment';

import { WPS_JS_PROVIDER } from '@global/App/constants/constants';

import { paymentInfo } from '@pages/checkout/types';
import { MappedPaymentComponentsType } from '@global/types/types';

interface IProps {
  paymentMethod: string;
  setPayment: React.Dispatch<React.SetStateAction<any>>;
  selectedPaymentMethod: string;
  paymentInitData: paymentInfo;
  paymentComponentMap: MappedPaymentComponentsType;
  isPaypalTestMode: boolean;
}

const PaymentMethod: React.FC<IProps> = ({
  paymentMethod,
  setPayment,
  selectedPaymentMethod,
  paymentInitData,
  paymentComponentMap,
  isPaypalTestMode,
}) => {
  const [startWpsJsInit, setStartWpsJsInit] = useState(false);

  useEffect(() => {
    const loadWpsJsScript = () => {
      const script = document.createElement('script');
      script.src = WPS_JS_PROVIDER;
      script.async = true;
      script.onload = () => {
        if (!startWpsJsInit) {
          setStartWpsJsInit(true);
        }
      };
      document.body.appendChild(script);
    };
    // Note: loading WpsJs provider script
    loadWpsJsScript();
  }, []);

  return (
    <>
      <div className="order-summary__promotion__text-block">
        <div className="order-summary__promotion__text">
          <div className="payment-section">
            <div
              className={`wps-payment ${
                paymentMethod === selectedPaymentMethod ? 'show' : 'hidden'
              }`}
            >
              <WpsPayment
                paymentMethod={paymentMethod}
                paymentInitData={paymentInitData}
                setPayment={setPayment}
                startWpsJsInit={startWpsJsInit}
                setStartWpsJsInit={setStartWpsJsInit}
                isPaymentMethodSelected={
                  paymentMethod === selectedPaymentMethod
                }
                paymentComponentMap={paymentComponentMap}
                isPaypalTestMode={isPaypalTestMode}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
