import { gql } from '@apollo/client';
import { IOrder } from '@global/types/types';

// Requests
export interface REQUEST_CART_TYPE {
  getViaxCustomerOrder: IOrder;
}

export const REQUEST_CART = gql`
  query getCart($uid: UUID!) {
    getViaxCustomerOrder(uid: $uid) {
      id
      uid
      biCreatedAt
      # order items
      pricPrice(
        _mapsOn: [{ code: "TAX" }, { code: "TOTAL" }, { code: "SUBTOTAL" }]
      ) {
        edges {
          mapsOn {
            code
          }
          node {
            amount
            units {
              code
            }
          }
        }
      }
      prRelatedParties(_mapsOn: [{ code: "SOLD_TO" }, { code: "AUTHOR" }]) {
        edges {
          node {
            ... on ViaxIndividual {
              paId
            }
          }
        }
      }
      paybBillingAddress {
        uid
        id
        gadCountry {
          iso2Code
          name
          uid
          numericCode
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
      hiConsistsOf {
        # sorting key
        biiSequentialId
        # product total price
        pricPrice(_mapsOn: { code: "SUBTOTAL" }) {
          edges {
            mapsOn {
              code
            }
            node {
              amount
              units {
                code
              }
            }
          }
        }
        # product data
        biiSalable {
          ... on ViaxConfigurableSalableProduct {
            maId
            maName
            imageUrl
          }
        }
      }

      paybPayment {
        ... on WPSCustomerPeriodicPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
          wPaymentType
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
    }
  }
`;
