import { createSlice } from '@reduxjs/toolkit';
import { Country } from '@global/types/types';
import { getCountries } from '@global/store/slices/country/extraReducers/getCountries';
import { getLocaleDataFromUrl } from '@global/utils/lang';

const initialState = {
  countries: [] as Country[],
  tmpPricingCountryCode: '' as string,
  tmpPricingFallbackValue: '' as string,
} as const;

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, { payload }) => {
      state.countries = payload;

      const { tmpPricingFallbackValue, tmpPricingCountryCode } =
        getTmpPricingData(payload);

      state.tmpPricingCountryCode = tmpPricingCountryCode;
      state.tmpPricingFallbackValue = tmpPricingFallbackValue;
    });
  },
});

export const {} = countrySlice.actions;
export const countryReducer = countrySlice.reducer;

function getTmpPricingData(countries: Country[]) {
  const { iso } = getLocaleDataFromUrl();
  const country = countries?.find(
    (country) => country.isoCodeTwo === iso?.toUpperCase()
  );

  return {
    tmpPricingCountryCode: iso,
    tmpPricingFallbackValue: country.fallbackCountry,
  };
}
