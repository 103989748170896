import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { Wrapper } from './Wrapper';
import { GET_IS_GRACE_PERIOD } from '@global/requests/subscriptionDetails';
import { Spinner } from '@global/components/UI Elements/Spinner';

import analytic from '@global/utils/AdobeAnalytics';

export const CancelModalBody = ({
  handleCancel,
  nextBillDate,
  handleClose,
  latestUid,
  product,
}) => {
  const { t } = useTranslation();

  const [isGracePeriod, setIsGracePeriod] = useState<boolean>(false);
  const { loading } = useQuery(GET_IS_GRACE_PERIOD, {
    variables: {
      uid: latestUid,
    },
    skip: !latestUid,
    onCompleted: (data) => {
      setIsGracePeriod(data?.wIsSubscriptionInGracePeriod?.result);
    },
  });

  const action = useCallback(async () => {
    analytic.dataLayerCancelSubscriptionTracker({ product });
    await handleCancel();
    handleClose();
  }, []);

  return !loading ? (
    <Wrapper
      action={action}
      actionBtnText={t('subDetails.sub.info.cancelCta.modalCta')}
      backBtnText={t('subDetails.sub.info.cancelCta.modalCloseCta')}
      handleClose={handleClose}
    >
      <span>
        {!isGracePeriod ? (
          <>
            {t('subDetails.sub.info.cancelCta.modalText1')}
            <span className="text-bold">{nextBillDate}</span>
            {t('subDetails.sub.info.cancelCta.modalText2')}
          </>
        ) : (
          t('subDetails.sub.info.cancelCta.modalGracePeriod')
        )}
      </span>
    </Wrapper>
  ) : (
    <Spinner size="64px" />
  );
};
