import { gql } from '@apollo/client';

export interface GET_VITAL_SOURCE_TYPE {
  wGetVitalSourceAccessLink120sec: {
    result?: {
      redirectLink?: string;
    };
  };
}

export const GET_VITAL_SOURCE = gql`
  query wGetVitalSourceAccessLink120sec($subscriptionContractUid: UUID!) {
    wGetVitalSourceAccessLink120sec(
      subscriptionContractUid: $subscriptionContractUid
    )
  }
`;
