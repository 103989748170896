import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  date: string;
};

export const IssueDate: FC<IProps> = ({ date }) => {
  const { t } = useTranslation();

  return (
    <div className="payment-details__column">
      <p className="payment-details__item payment-details__item_secondary-color">
        {t('confirmation.details.issueDate')}
      </p>
      <p className="payment-details__item">{date}</p>
    </div>
  );
};
