import { gql } from '@apollo/client';

export interface GET_CUSTOMER_ORDER_BY_UID_TYPE {
  getViaxCustomerOrder: {
    uid: string;
    hiConsistsOf: {
      uid?: string;
    }[];
  };
}

export const GET_CUSTOMER_ORDER_BY_UID = gql`
  query getViaxCustomerOrder($uid: UUID!) {
    getViaxCustomerOrder(uid: $uid) {
      uid
      hiConsistsOf {
        uid
      }
    }
  }
`;
