import React, { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// CSS and assets
import '@pages/checkout/components/SecurePayment/style.scss';
import './style.scss';
import { paymentInfo } from '@src/pages/checkout/types';
import {
  WPS_PAYMENT_METHODS,
  WPS_JS_CAPTCHA_RETRY_ATTEMPT,
  WPS_JS_CAPTCHA_TEST_MODE,
} from '@src/global/App/constants/constants';
import { ReloadInitResponseContext } from '@src/pages/checkout/components/SecurePayment';
import {
  LOCALE_CODES,
  MappedPaymentComponentsType,
} from '@src/global/types/types';

interface IProps {
  paymentInitData: paymentInfo;
  startWpsJsInit: boolean;
  setStartWpsJsInit: React.Dispatch<React.SetStateAction<boolean>>;
  setPayment: React.Dispatch<React.SetStateAction<string>>;
  paymentMethod: string;
  isPaymentMethodSelected: boolean;
  paymentComponentMap: MappedPaymentComponentsType;
  isPaypalTestMode: boolean;
}

const WpsPayment: FC<IProps> = ({
  paymentInitData,
  startWpsJsInit,
  setStartWpsJsInit,
  setPayment,
  paymentMethod,
  isPaymentMethodSelected,
  paymentComponentMap,
  isPaypalTestMode,
}) => {
  const { payment, setPaymentMethodErrorMsg, ...context } = useContext(
    ReloadInitResponseContext
  );
  const { i18n } = useTranslation();
  const language = i18n.language as LOCALE_CODES;

  const PaymentComponent = paymentComponentMap[paymentMethod];
  useEffect(() => {
    const paymentInit = async () => {
      const paymentFactory = new (window as any).PaymentFactory();
      const paymentMethodData = paymentInitData[paymentMethod];
      await paymentFactory.init(
        {
          appId: paymentMethodData.appId,
          region: paymentMethodData.region,
          currency: paymentMethodData.currency,
          amount: paymentMethodData.amount,
          intentType: paymentMethodData.intentType,
          timestamp: paymentMethodData.timestamp,
          email: paymentMethodData.email,
          paymentToken: paymentMethodData.paymentToken, // PayPaL
          paymentReference: paymentMethodData.paymentReference, // Card
          isTestMode: isPaypalTestMode,
          fakeNonce: 'fake-paypal-one-time-nonce',
          paymentMethods: [WPS_PAYMENT_METHODS[paymentMethod]],
          locale: language,
          captchaRetryAttempt: WPS_JS_CAPTCHA_RETRY_ATTEMPT,
          isCaptchaTestMode: WPS_JS_CAPTCHA_TEST_MODE,

          // paymentMethodAppearance: {
          //   card: {
          //     label: {
          //       padding: '10px',
          //       margin: '10px',
          //       color: 'green',
          //       fontFamily: 'Ideal Sans, system-ui, sans-serif',
          //       fontWeight: '600',
          //     },
          //     input: {
          //       backgroundColor: 'antiquewhite',
          //       borderRadius: '30px',
          //       borderColor: 'purple',
          //       border: '2px solid',
          //     },
          //     error: { color: 'purple' },
          //     captcha: {
          //       theme: 'dark',
          //       size: 'normal',
          //     },
          //   },
          // }, // TODO: if any CSS need for card payment, enable this and modify according to that
          successCallback: (response) => {
            setPayment(response);
          },
          errorCallback: (error) => {
            setPayment(error);
            setPaymentMethodErrorMsg(error);
          },
        },
        WPS_PAYMENT_METHODS[paymentMethod]
      );
    };
    if ((context.startWpsJsInit || startWpsJsInit) && isPaymentMethodSelected) {
      paymentInit();
      context.setStartWpsJsInit?.(false);
      setStartWpsJsInit(false);
    }
  }, [startWpsJsInit, isPaymentMethodSelected]);

  return (
    <>
      <div className="iframeStyle">
        {
          <PaymentComponent
            payment={payment}
            isPaymentMethodSelected={isPaymentMethodSelected}
          />
        }
      </div>
    </>
  );
};

export default WpsPayment;
