import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from '@assets/images/Filled_Checkmark.svg';

type IProps = {
  email: string;
};

export const Header: FC<IProps> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <div className="order-confirmation__title-wrapper">
      <div className="order-confirmation__title-wrapper--title">
        <CheckmarkIcon />
        <span>{t('confirmation.message')}</span>
      </div>

      <p className="order-confirmation__title-wrapper--message">
        {t('confirmation.instructions')}
        <span>{email}</span> {t('confirmation.instructions2')}
      </p>
    </div>
  );
};
