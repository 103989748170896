// Modules
import React from 'react';

// Css and assets
import './style.scss';

interface IProps {
  isOpen: boolean;
}

export const Burger: React.FC<IProps> = ({ isOpen }) => {
  return (
    <div className={isOpen ? 'burger-menu open' : 'burger-menu'}>
      <span> </span>
    </div>
  );
};
