import React from 'react';
import { useTranslation } from 'react-i18next';
import '@global/components/LinkItem/style.scss';

import { FORGOT_PASSWORD_LINK } from '@global/App/constants/constants';

export const ForgotPasswordLink = () => {
  const { t } = useTranslation();
  const forgotPasswordLink =
    FORGOT_PASSWORD_LINK + '?redirect_url=' + window.location.href;
  return (
    <a
      className="link-item"
      target="_blank"
      href={forgotPasswordLink}
      rel="noreferrer"
    >
      {t('activeForm.forgotPassword')}
    </a>
  );
};
