// Modules
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ButtonCTA } from '@global/components/UI Elements/ButtonCTA';

// Utils
import { Pages } from '@global/App/constants/constants';
import analytic from '@global/utils/AdobeAnalytics';

export const SubscriptionNotificationBody: FC<{
  doLogout: () => void;
  email?: string;
}> = ({ doLogout, email }) => {
  const { t, i18n } = useTranslation('', {
    keyPrefix: 'checkout.step1.subscriptionExist',
  });

  const goToSubscription = useCallback(() => {
    analytic.dataLayerBtnTracker({
      btnType: 'cta',
      btnName: t('ctaGreen'),
    });
    location.replace(`/${i18n.language}${Pages.SUBSCRIPTION_DETAILS}`);
  }, []);

  const changeAccount = useCallback(() => {
    analytic.dataLayerBtnTracker({
      btnType: 'cta',
      btnName: t('ctaWhite'),
    });
    analytic.dataLayerForm({
      formName: 'SubscriptionExistModal',
      formEvent: 'LogoutSuccess',
    });
    doLogout();
    location.replace(`/${i18n.language}${Pages.LOGIN}`);
  }, []);

  return (
    <>
      <div className="modal__content">
        {t('text1')}
        <strong className={'bold'}>{email} </strong>
        {t('text2')}
      </div>

      <ButtonCTA
        variant="primary"
        className="modal__action-btn"
        onClick={goToSubscription}
        tabIndex={1}
      >
        {t('ctaGreen')}
      </ButtonCTA>

      <span style={{ textAlign: 'center' }}>
        <button
          className="modal__back-btn"
          onClick={changeAccount}
          tabIndex={2}
        >
          {t('ctaWhite')}
        </button>
      </span>
    </>
  );
};
